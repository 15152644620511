import { Module } from "vuex";
import { state } from "./state";
import {
  IProjectIntegrationStateModel,
  IRootStateModel,
} from "./../../interfaces";
import { mutations } from "./mutations";
import { getters } from "./getters";
import { actions } from "./actions";

const projectIntegration: Module<
  IProjectIntegrationStateModel,
  IRootStateModel
> = {
  state,
  mutations,
  getters,
  actions,
};

export default projectIntegration;
