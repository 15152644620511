import type {
  AddEntityAttachmentsRequestDto,
  CheckIsEstimatedEntitiesRequestDto,
  CheckIsEstimatedEntitiesResponseDto,
  CreateEntityCommentRequestDto,
  CreateEntityRequestDto,
  EntitiesListRequestDto,
  EntitiesListResponseDto,
  FindEntitiesRequestDto,
  FindEntitiesResponseDto,
  RemoveEntityCommentsRequestDto,
  SetEntityEstimationRequestDto,
  SetEntityManagerRequestDto,
  SetEstimationResponseDto,
  UpdateEntityCommentRequestDto,
  ValidateFilenameResponseDto,
} from "@/services/api/entities/types";
import { BaseApi } from "@/services/api/BaseApi";
import type {
  BaseEntityModel,
  DetailedEntityModel,
} from "@/services/models/entities/entities";
import type { ItemsListModel } from "@/services/types/utility";
import type { CommentModel } from "@/services/models/entities/comments";
import { ValidateFilenameRequestDto } from "@/services/api/entities/types";

export class EntitiesApi extends BaseApi {
  async getEntitiesList<T = BaseEntityModel>(payload: EntitiesListRequestDto) {
    return (await this.client.post(payload, `/entities/list`)) as Promise<
      EntitiesListResponseDto<T>
    >;
  }

  async getEntity<T = DetailedEntityModel>(entityId: number) {
    return (await this.client.get(`/entities/${entityId}`)) as Promise<T>;
  }

  async findEntities(payload: FindEntitiesRequestDto) {
    return (await this.client.post(
      payload,
      `/entities/find`
    )) as Promise<FindEntitiesResponseDto>;
  }

  async checkIsEstimatedEntities(payload: CheckIsEstimatedEntitiesRequestDto) {
    return (await this.client.post(
      payload,
      `/entities/check`
    )) as Promise<CheckIsEstimatedEntitiesResponseDto>;
  }

  async createEntity(payload: CreateEntityRequestDto) {
    return (await this.client.post(
      payload,
      `/entities/create`
    )) as Promise<void>;
  }

  async getEntityComments(entityId: number) {
    return (await this.client.get(`/entities/${entityId}/comments`)) as Promise<
      ItemsListModel<CommentModel>
    >;
  }

  async createEntityComment(
    entityId: number,
    payload: CreateEntityCommentRequestDto
  ) {
    return (await this.client.post(
      payload,
      `/entities/${entityId}/comments/create`
    )) as Promise<void>;
  }

  async updateEntityComment(
    entityId: number,
    commentId: number,
    payload: UpdateEntityCommentRequestDto
  ) {
    return (await this.client.post(
      payload,
      `/entities/${entityId}/comments/${commentId}`
    )) as Promise<void>;
  }

  async removeEntityComments(
    entityId: number,
    payload: RemoveEntityCommentsRequestDto
  ) {
    return (await this.client.post(
      payload,
      `/entities/${entityId}/comments/remove`
    )) as Promise<void>;
  }

  async setEntityEstimation(
    entityId: number,
    payload: SetEntityEstimationRequestDto
  ) {
    return (await this.client.post(
      payload,
      `/entities/${entityId}/estimate`
    )) as Promise<SetEstimationResponseDto>;
  }

  async setEntityManager(
    entityId: number,
    payload: SetEntityManagerRequestDto
  ) {
    return (await this.client.post(
      payload,
      `/entities/${entityId}/manager`
    )) as Promise<void>;
  }

  async validateFilename(payload: ValidateFilenameRequestDto) {
    return (await this.client.post(
      payload,
      `/entities/validate`
    )) as Promise<ValidateFilenameResponseDto>;
  }

  addEntityAttachments(id: number, payload: AddEntityAttachmentsRequestDto) {
    return this.client.post(
        payload,
        `/entities/${id}/attachments`
    ) as Promise<unknown>
  }

  enqueue4stt(id: number) {
    return this.client.get(`/entities/${id}/enqueue4stt`) as Promise<unknown>
  }

  getSttBalance() {
    return this.client.get(`/users/sttBalance`) as Promise<{ sttBalance: number }>
  }
}
