import { IReportsStateModel } from "./../../interfaces";
import * as utils from "@/services/utils";

const date = new Date();
date.setDate(date.getDate() - 7);

const dateFrom = utils.getFormattedDate(date);
const dateTo = utils.getFormattedDate(new Date());

export const state: IReportsStateModel = {
  dataIsLoading: false,
  filter: {
    from: dateFrom,
    to: dateTo,
  },
  selectedProjects: [],
  labels: [],
  isDay: false,
  reports: [],
  projects: [],
  rawList: [],
  reportsRenderList: [],
  page: "managers",
  selectedCriterion: null,
  selectedManager: null,
  dateInterval: "week",
  reportCallsList: [],
  reportCallsListSort: {
    direction: "asc",
    sort: "optionValue",
  },
};
