import { MutationTree } from "vuex";
import { Reports2MutationTypes } from "./types";
import {
  BaseEntityModel,
  ReportItem,
  ReportItemRenderData,
  ReportsListDateFilterModel,
  ReportsPageType,
} from "@/services/types";
import {
  IReports2StateModel,
  Reports2Targets,
} from "@/store/modules/reports2/state";

export type Reports2Mutations<S = IReports2StateModel> = {
  [Reports2MutationTypes.SET_REPORTS2_DATA_IS_LOADING](
    state: S,
    payload: boolean
  ): void;
  [Reports2MutationTypes.SET_REPORTS2_DETAILS_IS_LOADING](
    state: S,
    payload: boolean
  ): void;
  [Reports2MutationTypes.SET_REPORTS2_LABELS](
    state: S,
    payload: string[]
  ): void;
  [Reports2MutationTypes.SET_REPORTS2_PROJECTS](
    state: S,
    payload: BaseEntityModel[]
  ): void;
  [Reports2MutationTypes.SET_REPORTS2_SELECTED_PROJECTS](
    state: S,
    payload: number[]
  ): void;
  [Reports2MutationTypes.SET_REPORTS2_REPORTS](
    state: S,
    payload: BaseEntityModel[]
  ): void;
  [Reports2MutationTypes.SET_REPORTS2_SELECTED_REPORTS](
    state: S,
    payload: number[]
  ): void;
  [Reports2MutationTypes.SET_REPORTS2_RAW_REPORTS_DATA](
    state: S,
    payload: ReportItem[]
  ): void;
  [Reports2MutationTypes.SET_REPORTS2_RENDER_REPORTS_DATA](
    state: S,
    payload: ReportItemRenderData[]
  ): void;
  [Reports2MutationTypes.SET_REPORTS2_PAGE](
    state: S,
    payload: ReportsPageType
  ): void;
  [Reports2MutationTypes.SET_REPORTS2_DATE_FILTER](
    state: S,
    payload: ReportsListDateFilterModel
  ): void;
  [Reports2MutationTypes.SET_REPORTS2_DATE_INTERVAL](
    state: S,
    payload: number
  ): void;
  [Reports2MutationTypes.SET_REPORTS2_DETAILS_RAW_DATA](
    state: S,
    payload: ReportItem[]
  ): void;
  [Reports2MutationTypes.SET_REPORTS2_DETAILS_RENDER_DATA](
    state: S,
    payload: ReportItemRenderData[]
  ): void;
  [Reports2MutationTypes.SET_REPORTS2_TARGETS](
    state: S,
    payload: Reports2Targets
  ): void;
  [Reports2MutationTypes.SET_REPORTS2_REPORTS_SNAP_ID](
    state: S,
    payload: string
  ): void;
  [Reports2MutationTypes.SET_REPORTS2_FILTER_REPORT_ID](
    state: S,
    payload: number
  ): void;
  [Reports2MutationTypes.SET_REPORTS2_FILTER_REPORT_OPTIONS](
    state: S,
    payload: BaseEntityModel[]
  ): void;
};

export const mutations: MutationTree<IReports2StateModel> & Reports2Mutations =
  {
    [Reports2MutationTypes.SET_REPORTS2_DATA_IS_LOADING](state, payload) {
      state.isDataLoading = payload;
    },
    [Reports2MutationTypes.SET_REPORTS2_DETAILS_IS_LOADING](state, payload) {
      state.isDetailsLoading = payload;
    },
    [Reports2MutationTypes.SET_REPORTS2_LABELS](state, payload) {
      state.labels = payload;
    },
    [Reports2MutationTypes.SET_REPORTS2_PROJECTS](state, payload) {
      state.projects = payload;
    },
    [Reports2MutationTypes.SET_REPORTS2_SELECTED_PROJECTS](state, payload) {
      state.selectedProjects = payload;
    },
    [Reports2MutationTypes.SET_REPORTS2_REPORTS](state, payload) {
      state.reports = payload;
    },
    [Reports2MutationTypes.SET_REPORTS2_SELECTED_REPORTS](state, payload) {
      state.selectedReports = payload;
    },
    [Reports2MutationTypes.SET_REPORTS2_RAW_REPORTS_DATA](
      state,
      payload: ReportItem[]
    ) {
      state.reportsRawData = payload;
    },
    [Reports2MutationTypes.SET_REPORTS2_RENDER_REPORTS_DATA](state, payload) {
      state.reportsRenderData = payload;
    },
    [Reports2MutationTypes.SET_REPORTS2_PAGE](state, payload) {
      state.page = payload;
    },
    [Reports2MutationTypes.SET_REPORTS2_DATE_FILTER](state, payload) {
      state.dateFilter = payload;
    },
    [Reports2MutationTypes.SET_REPORTS2_DATE_INTERVAL](state, payload) {
      state.dateInterval = payload;
    },
    [Reports2MutationTypes.SET_REPORTS2_DETAILS_RAW_DATA](state, payload) {
      state.reportDetailsRawData = payload;
    },
    [Reports2MutationTypes.SET_REPORTS2_DETAILS_RENDER_DATA](state, payload) {
      state.reportDetailsRenderData = payload;
    },
    [Reports2MutationTypes.SET_REPORTS2_TARGETS](state, payload) {
      state.targets = payload;
    },
    [Reports2MutationTypes.SET_REPORTS2_REPORTS_SNAP_ID](state, payload) {
      state.selectedReportsSnapId = payload;
    },
    [Reports2MutationTypes.SET_REPORTS2_FILTER_REPORT_ID](state, payload) {
      state.filterReportId = payload;
    },
    [Reports2MutationTypes.SET_REPORTS2_FILTER_REPORT_OPTIONS](state, payload) {
      state.filterReportOptions = payload;
    },
  };
