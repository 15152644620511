import {
  CompanyEmployeeModel,
  CriterionTypeCode,
  DateIntervalsType,
  EmployeeRole,
} from "./types";
import { i18n } from "@/i18n/index";
import { CallDirection, CallEstimationType } from "./types/call";
import { version as AppVersion } from "@/../package.json";
import moment from "moment/moment";

export type Order = "asc" | "desc";

export const getFormattedTime = (date: Date) => {
  const hh = date.getUTCHours();
  const hhRender = hh < 10 ? `0${hh}` : hh;
  const min = date.getUTCMinutes();
  const minRender = min < 10 ? `0${min}` : min;

  return `${hhRender}:${minRender}`;
};

export const renderDate = (timestamp: number) =>
  new Date(timestamp).toLocaleTimeString("ru", {
    day: "numeric",
    month: "2-digit",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  });

// export const renderDateFromString = (time: string) => new Date(time).toLocaleTimeString('ru', {
//     day: 'numeric',
//     month: '2-digit',
//     year: 'numeric',
//     hour: '2-digit',
//     minute: '2-digit'
// })

export const renderShortDateFromString = (time: string) => {
  const date = new Date(time);
  const YYYY = date.getUTCFullYear();
  const mm =
    date.getUTCMonth() + 1 < 10
      ? `0${date.getUTCMonth() + 1}`
      : date.getUTCMonth() + 1;
  const dd =
    date.getUTCDate() < 10 ? `0${date.getUTCDate()}` : date.getUTCDate();

  return `${dd}.${mm}.${YYYY}`;
};

export const renderDateFromString = (time: string) => {
  const date = new Date(time);

  return `${renderShortDateFromString(time)} ${getFormattedTime(date)}`;
};

export function formatDateTimeString(dateTimeString: string, preserveTimezone = false): string {
  if (preserveTimezone) {
    return moment.parseZone(dateTimeString).format('DD.MM.YYYY HH:mm');
  } else {
    return moment(dateTimeString).format('DD.MM.YYYY HH:mm');
  }
}

export const renderDuration = (timestamp: number) => {
  const length = timestamp / 1000;

  const minutes = Math.floor(length / 60),
    secondsInt = length - minutes * 60,
    secondsStr = secondsInt.toString(),
    seconds = secondsStr.substr(0, 2);

  return minutes
    ? minutes +
        `${i18n.t("utils.time.min")}, ` +
        seconds +
        ` ${i18n.t("utils.time.sec")}`
    : seconds + ` ${i18n.t("utils.time.sec")}`;
};

export const renderDurationWithDots = (timestamp: number) => {
  const length = timestamp / 1000;

  const minutes = Math.floor(length / 60),
    secondsInt = length - minutes * 60,
    secondsStr = secondsInt.toString(),
    seconds = parseInt(secondsStr.substr(0, 2));

  const num = (val: number) => {
    return val < 10 ? "0" + val : val;
  };

  return minutes ? num(minutes) + ":" + num(seconds) : "00:" + num(seconds);
};

export function formatMmSsString(timeString: string): string {
  let [minutes, seconds] = timeString.split(':');
  if (!minutes) {
    minutes = '00';
  } else if (minutes.length === 1) {
    minutes = '0' + minutes;
  }

  if (!seconds) {
    seconds = '00';
  } else if (seconds.length === 1) {
    seconds = '0' + seconds;
  }

  return `${minutes}:${seconds}`;
}

// limited to 99:59, fix input with formatMmSsString
export function getSecondsFromMmSsString(timeString: string): number {
  const formattedTimeString = formatMmSsString(timeString);
  const [minutes, seconds] = formattedTimeString.split(':').map(Number);
  return Math.min(Math.max(minutes * 60 + seconds, 0), 99*60+59)
}

export function secondsToMmSs(totalSeconds: number): string {
  const minutes = Math.floor(totalSeconds / 60);
  const seconds = totalSeconds % 60;

  return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
}

export const renderTags = (tags: string[]) => {
  return tags.join(", ");
};

export const translateDaysPrefix = (days: number): string => {
  if (days == 1) {
    return `${days} день`;
  } else if (days >= 2 && days <= 4) {
    return `${days} дня`;
  } else {
    return `${days} дней`;
  }
};

export const getCallEstimation = (status: CallEstimationType): string => {
  let label = i18n.t("calls.estimation.label") as string;
  if (status == "withEstimation") {
    label = i18n.t("calls.estimation.withEstimation") as string;
  } else if (status == "withoutEstimation") {
    label = i18n.t("calls.estimation.withoutEstimation") as string;
  }
  return label;
};

export const getUserRole = (role: EmployeeRole): string => {
  let label = "";
  if (role == "CONTROL") {
    label = "Администратор";
  } else if (role == "OWNER") {
    label = "Владелец";
  } else if (role == "AUDITOR") {
    label = "Аудитор";
  } else if (role == "MANAGER") {
    label = "Владелец";
  } else if (role == "READ_ONLY") {
    label = "Только чтение";
  }
  return label;
};

export const getCriterionTypeCode = (code: CriterionTypeCode): string => {
  let label = i18n.t("criterions.forms.criterionType.label") as string;

  if (code == "CUSTOM") {
    label = i18n.t("criterions.forms.criterionType.custom") as string;
  } else if (code == "PROHIBITION") {
    label = i18n.t("criterions.forms.criterionType.prohibition") as string;
  } else if (code == "REQUIREMENT") {
    label = i18n.t("criterions.forms.criterionType.requirement") as string;
  }
  return label;
};

export const convertCommaToDot = (str: string, idx: number): string => {
  return str.substr(0, idx) + "." + str.substr(idx + 1);
};

export const roundToTwo = (value: number): number => {
  return isNaN(value) ? value : Math.round(value * 100) / 100;
};

export const convertCommaToDotAndRoundToTwo = (weight: string): number => {
  const commaIdx = weight.toString().indexOf(",");
  if (commaIdx >= 0) {
    return roundToTwo(
      parseFloat(convertCommaToDot(weight.toString(), commaIdx))
    );
  } else {
    return roundToTwo(parseFloat(weight));
  }
};

export const getDirection = (direction: CallDirection): string => {
  if (direction == "incoming") {
    return i18n.t("utils.call.incoming") as string;
  } else {
    return i18n.t("utils.call.outgoing") as string;
  }
};

export const getArraySum = (array: number[]): number =>
  array.reduce((acc, num) => {
    const i = isNaN(num) ? 0 : num;
    return acc + i;
  }, 0);

// Filter out null & nan
export const getArrayAverage = (numbers: number[]): number => {
  let length = 0;
  let sum = 0;

  for (const n of numbers) {
    if (n !== null && !isNaN(n)) {
      length++;
      sum += +n;
    }
  }

  return length ? sum / length : null;
};

export const getFormattedDate = (date: Date) => {
  const YYYY = date.getFullYear();
  const mm =
    date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1;
  const dd = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();

  return `${YYYY}-${mm}-${dd}`;
};

export const getDateInterval = (code: DateIntervalsType): string => {
  let label = "";

  if (code == "day") {
    label = i18n.t("reports.dateIntervals.day") as string;
  } else if (code == "week") {
    label = i18n.t("reports.dateIntervals.week") as string;
  } else if (code == "month") {
    label = i18n.t("reports.dateIntervals.month") as string;
  } else {
    label = i18n.t("reports.dateIntervals.custom") as string;
  }
  return label;
};

export function getAppVersion() {
  return AppVersion;
}

/**
 * Returns a random integer between min (inclusive) and max (inclusive).
 * The value is no lower than min (or the next integer greater than min
 * if min isn't an integer) and no greater than max (or the next integer
 * lower than max if max isn't an integer).
 * Using Math.round() will give you a non-uniform distribution!
 */
export function getRandomInt(min: number, max: number) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

export function getUserFio(item: CompanyEmployeeModel) {
  return [item.lastname, item.firstname, item.surname].join(" ");
}

export function file2Base64(file: File): Promise<string | ArrayBuffer> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      //     let encoded = reader.result.toString();
      //     if (encoded.length % 4 > 0) {
      //       encoded += "=".repeat(4 - (encoded.length % 4));
      //     }
      //     resolve(encoded);

      const encoded = reader.result.toString();
      resolve(encoded);
    };
    reader.onerror = (error) => reject(error);
    reader.readAsDataURL(file);
  });
}

export function base64RemoveDataType(source: string | ArrayBuffer): string {
  return source.toString().replace(/^data:(.*,)?/, "")
}

export function getExtensionFromFileName(name: string): string | undefined {
  const re = /(?:\.([^.]+))?$/;
  return re.exec(name)[0];
}

export function limitString(str: string, limit: number, ellipsis = `...`) {
  if (!str || !str.length || str.length <= limit) return str;
  return `${str.slice(0, limit)}${ellipsis}`;
}
