import { MutationTree } from "vuex";
import { MutationTypes } from "./types";
import { IPlayerStateModel } from "./../../../interfaces";
import { PlayBackSpeed } from "@/services/types";

export type PlayerMutations<S = IPlayerStateModel> = {
  [MutationTypes.SET_PLAYER_CURRENT_TIME](state: S, payload: number): void;
  [MutationTypes.SET_PLAYER_DURATION](state: S, payload: number): void;
  [MutationTypes.SET_PLAYER_PLAY](state: S, payload: boolean): void;
  [MutationTypes.SET_PLAYER_URL](state: S, payload: string): void;
  [MutationTypes.SET_PLAYER_VOLUME](state: S, payload: number): void;
  [MutationTypes.SET_PLAYER_PLAY_BACK_SPEED](
    state: S,
    payload: PlayBackSpeed
  ): void;
  [MutationTypes.SET_PLAYER_NEW_CURRENT_TIME](state: S, payload: number): void;
  [MutationTypes.SET_PLAYER_IS_PLAYED](state: S, payload: boolean): void;
  [MutationTypes.SET_PLAYER_CLEAR_PLAYER](
    state: S,
    payload: IPlayerStateModel
  ): void;
};

export const mutations: MutationTree<IPlayerStateModel> & PlayerMutations = {
  [MutationTypes.SET_PLAYER_CURRENT_TIME](state, payload: number) {
    state.currentTime = payload;
  },
  [MutationTypes.SET_PLAYER_DURATION](state, payload: number) {
    state.duration = payload;
  },
  [MutationTypes.SET_PLAYER_PLAY](state, payload: boolean) {
    state.play = payload;
  },
  [MutationTypes.SET_PLAYER_URL](state, payload: string) {
    state.url = payload;
  },
  [MutationTypes.SET_PLAYER_VOLUME](state, payload: number) {
    state.volume = payload;
  },
  [MutationTypes.SET_PLAYER_PLAY_BACK_SPEED](state, payload: PlayBackSpeed) {
    state.playBackSpeed = payload;
  },
  [MutationTypes.SET_PLAYER_NEW_CURRENT_TIME](state, payload: number) {
    state.newCurrentTime = payload;
  },
  [MutationTypes.SET_PLAYER_IS_PLAYED](state, payload: boolean) {
    state.isPlayed = payload;
  },
  [MutationTypes.SET_PLAYER_CLEAR_PLAYER](state, payload: IPlayerStateModel) {
    state.currentTime = payload.currentTime;
    state.duration = payload.duration;
    state.isPlayed = payload.isPlayed;
    state.newCurrentTime = payload.newCurrentTime;
    state.play = payload.play;
    state.playBackSpeed = payload.playBackSpeed;
    state.url = payload.url;
    state.volume = payload.volume;
  },
};
