export enum MutationTypes {
  SET_INSTANCES_LIST = "setInstancesList",
  SET_DIALOG_QUEUE = "setDialogQueue",
  SET_DIALOG_CLOSE = "setDialogCLose",
  SET_DATA_IS_LOADING = "setDataIsLoading",
  SET_DIALOG_IS_LOADING = "setDialogIsLoading",
  SET_CURRENT_INSTANCE = "setCurrentInstance",
  SET_CURRENT_QR = "setCurrentQr",
  SET_TARIFFS = "setTariffs",
  SET_EWA_USER = "setEwaUser",
  SET_BALANCE = "setBalance",
}
