import { Module } from "vuex";
import { IReports2StateModel, state } from "./state";
import { IRootStateModel } from "./../../interfaces";
import { mutations } from "./mutations";
import { getters } from "./getters";
import { actions } from "./actions";

const reports2: Module<IReports2StateModel, IRootStateModel> = {
  state,
  mutations,
  getters,
  actions,
};

export default reports2;
