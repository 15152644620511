export enum MutationTypes {
  SET_CHECKLISTS_LIST = "setCheckListsList",
  SET_CHECKLISTS_LIST_PAGE = "setCheckListsListPage",
  SET_CHECKLISTS_TOTAL = "setCheckListsTotal",
  SET_CHECKLISTS_LIST_PER_PAGE = "setCheckListsListPerPage",
  SET_CHECKLISTS_DATA_IS_LOADING = "setCheckListsDataIsLoading",
  SET_CHECKLISTS_SORT_VALUE = "setCheckListsSortValue",
  SET_CHECKLISTS_FILTER_VALUE = "setCheckListsFilterValue",
  SET_CHECKLISTS_SELECTED_CHECKLIST = "setCheckListsSelectedCheckList",
  SET_CHECKLISTS_CATEGORIES_LIST_BY_SELECTED_CHECKLIST = "setCheckListsCategoriesListBySelectedCheckList",
  SET_CHECKLISTS_LIST_SIMPLE = "setCheckListsListSimple",
}

export function SET_CHECKLISTS_DATA_IS_LOADING(
  SET_CHECKLISTS_DATA_IS_LOADING: any,
  arg1: boolean
) {
  throw new Error("Function not implemented.");
}
