import { IEmployeesStateModel } from "./../../interfaces";

export const state: IEmployeesStateModel = {
  list: [],
  dataIsLoading: false,
  selectedEmployee: null,
  error: null,
  filter: {
    status: null,
    search: "",
  },
};
