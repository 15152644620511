import { ICheckListsStateModel } from "./../../interfaces";

export const state: ICheckListsStateModel = {
  list: [],
  page: 1,
  perPage: 20,
  total: 0,
  dataIsLoading: false,
  selectedCheckList: null,
  filter: {
    search: "",
  },
  sort: {
    direction: "asc",
    sort: "name",
  },
  categoriesListBySelectedCheckList: [],
  listSimple: [],
};
