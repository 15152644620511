export enum MutationTypes {
  SET_PROJECT_INTEGRATION_TYPE = "setProjectIntegrationType",
  SET_PROJECT_INTEGRATION_DATA_IS_LOADING = "setProjectIntegrationDataIsLoading",
  SET_PROJECT_INTEGRATION_FUNNELS_LIST = "setProjectIntegrationFunnelsList",
  SET_PROJECT_INTEGRATION_INTEGRATIONS_LIST = "setProjectIntegrationIntegrationsList",
  SET_PROJECT_INTEGRATION_SELECTED_PROJECT_INTEGRATION = "setProjectIntegrationSelectedProjectIntegration",
  SET_PROJECT_INTEGRATION_INTEGRATION = "setProjectIntegrationIntegration",
  SET_PROJECT_INTEGRATION_CALLS_LIST_IS_LOADING = "setProjectIntegrationCallsListIsLoading",
  SET_PROJECT_INTEGRATION_INTEGRATION_TYPE = "SET_PROJECT_INTEGRATION_INTEGRATION_TYPE",
  SET_BITRIX_INTEGRATION = "setBitrixIntegration",
}
