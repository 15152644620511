import { ActionContext, ActionTree } from "vuex";
import { ICallsStateModel, IRootStateModel } from "../../../interfaces";
import { CallsMutations } from "./../mutations";
import { ActionTypes } from "./types";
import { MutationTypes } from "./../mutations/types";
import {
  BaseEntityModelsListResponse,
  CallsListFilterModel,
  CallsListSortModel,
  GetProjectsPhonesResponseModel,
} from "./../../../../services/types";
import { BaseClient } from "./../../../../services/client/BaseClient";
import { getClientInstance } from "./../../../..//services/client/clientProvider";
import apiClient from "@/services/api/apiClient";
import { CallEntityModel } from "@/services/types/entities/entities";
import { getStore } from "@/store/getStore";

declare const window: any;

type AugmentedActionCallsContext = {
  commit<K extends keyof CallsMutations>(
    key: K,
    payload: Parameters<CallsMutations[K]>[1]
  ): ReturnType<CallsMutations[K]>;
} & Omit<ActionContext<ICallsStateModel, IRootStateModel>, "commit">;

export interface CallsActions {
  [ActionTypes.SET_CALLS_LIST](
    context: AugmentedActionCallsContext
  ): Promise<void>;

  [ActionTypes.SET_CALLS_LIST_PAGE](
    context: AugmentedActionCallsContext,
    page: number
  ): void;

  [ActionTypes.SET_CALLS_LIST_PER_PAGE](
    context: AugmentedActionCallsContext,
    perPage: number
  ): void;

  [ActionTypes.SET_CALLS_LIST_SORT](
    context: AugmentedActionCallsContext,
    sort: CallsListSortModel
  ): void;

  [ActionTypes.SET_CALLS_LIST_FILTER](
    context: AugmentedActionCallsContext,
    sort: CallsListFilterModel
  ): void;

  [ActionTypes.SET_CALLS_SELECTED_GRID_COLUMNS](
    context: AugmentedActionCallsContext,
    list: string[]
  ): void;

  [ActionTypes.SET_CALLS_PROJECTS_LIST](
    context: AugmentedActionCallsContext
  ): Promise<void>;

  [ActionTypes.SET_CALLS_MANAGERS_LIST](
    context: AugmentedActionCallsContext
  ): Promise<void>;

  [ActionTypes.SET_CALLS_LINKED_PHONES_LIST](
    context: AugmentedActionCallsContext
  ): Promise<void>;

  [ActionTypes.SET_CALLS_LIST_LINKED_PHONE_FILTER](
    context: AugmentedActionCallsContext,
    phone: string
  ): void;
}

export const actions: ActionTree<ICallsStateModel, IRootStateModel> &
  CallsActions = {
  async [ActionTypes.SET_CALLS_LIST]({ commit, getters }) {
    commit(MutationTypes.SET_CALLS_DATA_IS_LOADING, true);
    try {
      const getters = getStore().getters;

      const response =
        await apiClient.entitiesApi.getEntitiesList<CallEntityModel>({
          page: getters.getCallsListPage,
          perPage: getters.getCallsListPerPage,
          filter: {
            types: ["LEAD", "CALL"],
            linkedEntities: getters.getCallsFilterValue.linkedCalls,
            initialEntities: getters.getCallsFilterValue.initialCalls,
            ...getters.getCallsFilterValue,
          },
          sort: getters.getCallsSortValue,
        });

      commit(MutationTypes.SET_CALLS_LIST, response.items as any[]);
      commit(MutationTypes.SET_CALLS_TOTAL, response.total);
      commit(MutationTypes.SET_CALLS_TOTAL_DURATION, response.totalDuration);
    } catch (error) {
      window.error("Ошибка получения списка звонков");
      console.error("getCallsList", error);
    } finally {
      commit(MutationTypes.SET_CALLS_DATA_IS_LOADING, false);
    }
    commit(MutationTypes.SET_CALLS_DATA_IS_LOADING, false);
  },
  [ActionTypes.SET_CALLS_LIST_PAGE]({ commit }, page: number) {
    commit(MutationTypes.SET_CALLS_LIST_PAGE, page);
  },
  [ActionTypes.SET_CALLS_LIST_PER_PAGE]({ commit }, perPage: number) {
    commit(MutationTypes.SET_CALLS_LIST_PER_PAGE, perPage);
  },
  [ActionTypes.SET_CALLS_LIST_SORT]({ commit }, sort: CallsListSortModel) {
    commit(MutationTypes.SET_CALLS_SORT_VALUE, sort);
  },
  [ActionTypes.SET_CALLS_LIST_FILTER](
    { commit },
    filter: CallsListFilterModel
  ) {
    commit(MutationTypes.SET_CALLS_FILTER_VALUE, filter);
    commit(MutationTypes.SET_CALLS_LIST_PAGE, 1);
  },
  [ActionTypes.SET_CALLS_SELECTED_GRID_COLUMNS]({ commit }, list: string[]) {
    commit(MutationTypes.SET_CALLS_SELECTED_GRID_COLUMNS, list);
  },
  async [ActionTypes.SET_CALLS_MANAGERS_LIST]({ commit }) {
    try {
      const filter = getStore().getters.getCallsFilterValue;
      const response: BaseEntityModelsListResponse =
        await apiClient.projectsApi.getProjectsManagers({
          filter: {
            isEstimated: filter.isEstimated,
            started: filter.started,
            duration: filter.duration,
          },
        }) as any;

      if (response) {
        commit(MutationTypes.SET_CALLS_MANAGERS_LIST, response.items);
      }
    } catch (error) {
      window.error("Ошибка получения списка менеджеров");
      console.error("getManagersSimpleList", error);
    }
  },
  async [ActionTypes.SET_CALLS_PROJECTS_LIST]({ commit }) {
    const client: BaseClient = getClientInstance();

    try {
      const response = await apiClient.projectsApi.getProjectsPlainList();

      const filtredProjects = response.items.filter(
        (p) => p.type === "CALL" || p.type === "LEAD"
      );
      const flatList = [];
      filtredProjects.forEach((p) => {
        flatList.push(p);
        if (p.subprojects) {
          flatList.push(...p.subprojects);
        }
      });

      commit(MutationTypes.SET_CALLS_PROJECTS_LIST, flatList);
    } catch (error) {
      window.error("Ошибка получения списка проектов");
      console.error("getProjectsSimpleList", error);
    }
  },
  async [ActionTypes.SET_CALLS_LINKED_PHONES_LIST]({ commit }) {
    const client: BaseClient = getClientInstance();

    try {
      const response: GetProjectsPhonesResponseModel =
        await client.getProjectsPhonesList();

      commit(MutationTypes.SET_CALLS_LINKED_PHONES_LIST, response.items);
    } catch (error) {
      console.error("getProjectsPhonesList", error);
    }
  },
  [ActionTypes.SET_CALLS_LIST_LINKED_PHONE_FILTER](
    { getters, dispatch },
    phone: string
  ) {
    const filter = getters.getCallsFilterValue as CallsListFilterModel;
    filter.linkedCalls = [phone];
    dispatch(ActionTypes.SET_CALLS_LIST_FILTER, filter);
  },
};
