import { ICategoriesStateModel } from "./../../interfaces";

export const state: ICategoriesStateModel = {
  list: [],
  fullList: [],
  withCriterionsFullList: [],
  page: 1,
  perPage: 20,
  total: 0,
  dataIsLoading: false,
  selectedCategory: null,
  filter: {
    status: null,
    search: "",
  },
  sort: {
    direction: "asc",
    sort: "name",
  },
};
