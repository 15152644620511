import {
  ChartReportItemModel,
  ReportDateEstimationItem,
  ReportItem,
  ReportItemRenderData,
} from "@/services/types/reports";
import { BaseEntityModel } from "@/services/types/base";
import {
  DEFAULT_CHART_ITEM_VALUES,
  DEFAULT_COLOR,
  EXTENDED_COLOR_PALETTE,
  EXTENDED_MAX_SELECTED_ITEMS_LIST_LENGTH,
} from "@/store/modules/reports/helpers";
import { getArrayAverage, getArraySum, roundToTwo } from "@/services/utils";
import { groupBy } from "lodash";
import moment from "moment/moment";

export const CHART_NO_DATA = {
  labels: ["Нет данных"],
  datasets: [
    {
      label: "Нет данных",
      data: [0],
    },
  ],
};

function getColorFromPalette(index: number) {
  return index < EXTENDED_MAX_SELECTED_ITEMS_LIST_LENGTH
    ? EXTENDED_COLOR_PALETTE[index]
    : DEFAULT_COLOR;
}

function getDateFormatFromRoundType(roundType: string) {
  switch (roundType) {
    case "month":
      return "MM/YY";
    case "year":
      return "YYYY";
    case "day":
    case "week":
    default:
      return "DD/MM/YY";
  }
}

function getReportAvgRatingsCalculatedBySelectedProjects(
  items: ReportDateEstimationItem[],
  selectedProjectIds: number[]
) {
  return items.map((i) => {
    const values = i.estimation
      .filter(
        (e) => e.value !== null && selectedProjectIds.includes(e.projectId)
      )
      .map((e) => Number(e.value));

    const rating = getArrayAverage(values);
    // debugger;

    return {
      date: i.date,
      rating,
    };
  });
}

export function createReportsRenderList(
  reportsRawData: ReportItem[],
  reports: BaseEntityModel[],
  selectedProjectsIds: number[]
): ReportItemRenderData[] {
  const reportsRenderList: ReportItemRenderData[] = [];

  reportsRawData.forEach((report, index) => {
    const color = getColorFromPalette(index);

    // 9605

    const data = getReportAvgRatingsCalculatedBySelectedProjects(
      report.estimations,
      selectedProjectsIds
    );

    const totalAvgRating = getArrayAverage(data.map((i) => i.rating));

    if (totalAvgRating !== null && !isNaN(totalAvgRating)) {
      reportsRenderList.push({
        reportId: report.id,
        name: reports.find((r) => r.id === report.id)?.name || `${report.id}`,
        color: color,
        totalAvgRating: roundToTwo(totalAvgRating),
        data,
      });
    }
  });

  return reportsRenderList;
}

export function getLineChartData(
  reportsRenderData: ReportItemRenderData[],
  selectedReportsIds: number[],
  roundType: "day" | "week" | "month" | "year"
) {
  const selectedReports = reportsRenderData.filter((r) =>
    selectedReportsIds.includes(r.reportId)
  );

  const datasets = selectedReports.map((r) => {
    const groupedByDate = groupBy(r.data, (i) =>
      moment(i.date).startOf(roundType)
    );
    const data = Object.entries(groupedByDate).map(([key, value]) => {
      const avg = getArrayAverage(
        value.map((i) => i.rating).filter((n) => n !== null)
      );
      return {
        x: moment(key).format(getDateFormatFromRoundType(roundType)),
        y: avg !== null ? roundToTwo(avg) : null,
      };
    });

    return {
      data: data,
      label: r.name,
      backgroundColor: r.color,
      pointBackgroundColor: r.color,
      borderColor: r.color,
      spanGaps: true,
      ...DEFAULT_CHART_ITEM_VALUES,
    };
  });

  return {
    datasets,
  };
}

export function getBarChartData(
  reportsRenderData: ReportItemRenderData[],
  selectedReportsIds: number[]
): {
  labels: string[];
  datasets: ChartReportItemModel[];
} {
  const selectedReports = reportsRenderData.filter((r) =>
    selectedReportsIds.includes(r.reportId)
  );

  return {
    labels: selectedReports.map((i) => i.name),
    datasets: selectedReports.map((item, index) => {
      const data = Array(selectedReports.length).fill(0);
      data.splice(index, 1, item.totalAvgRating);
      return {
        label: item.name,
        data,
        backgroundColor: item.color,
        pointBackgroundColor: item.color,
        borderColor: item.color,
        spanGaps: true,
        ...DEFAULT_CHART_ITEM_VALUES,
      };
    }),
  };
}

export function getRatingColor(rating: number) {
  if (rating >= 90) {
    return "#28A745";
  }
  if (rating >= 60) {
    return "#FFD963";
  }
  return "#FF0000";
}
