import { MutationTree } from "vuex";
import { MutationTypes } from "./types";
import { GlobalStateModel } from "../../../interfaces";

export type GlobalMutations<S = GlobalStateModel> = {
  [MutationTypes.SET_SHOW_CONTENT](state: S, payload: boolean): void;
  [MutationTypes.SET_CONFIRM_MODAL](state: S, payload): void;
  [MutationTypes.SET_SAVED_FILTERS](state: S, payload): void;
};

export const mutations: MutationTree<GlobalStateModel> & GlobalMutations = {
  [MutationTypes.SET_SHOW_CONTENT](state, payload: boolean) {
    state.showContent = payload;
  },
  [MutationTypes.SET_CONFIRM_MODAL](state, payload) {
    state.confirmModal = payload;
  },
  [MutationTypes.SET_SAVED_FILTERS](state, payload) {
    state.filters = payload;
  },
};
