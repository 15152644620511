import { GetterTree } from "vuex";
import { ICheckListsStateModel, IRootStateModel } from "./../../../interfaces";
import {
  CheckListModel,
  CheckListsListSortModel,
  CheckListsListFilterModel,
  CategoriesInCheckListModel,
  BaseEntityModel,
} from "@/services/types";

export type CheckListGetters = {
  getCheckListsList(state: ICheckListsStateModel): CheckListModel[];
  getCheckListsListPage(state: ICheckListsStateModel): number;
  getCheckListsListPerPage(state: ICheckListsStateModel): number;
  getCheckListsTotal(state: ICheckListsStateModel): number;
  isCheckListsDataLoading(state: ICheckListsStateModel): boolean;
  getCheckListsSortValue(state: ICheckListsStateModel): CheckListsListSortModel;
  getCheckListsFilterValue(
    state: ICheckListsStateModel
  ): CheckListsListFilterModel;
  getCheckListsSelectedCheckList(state: ICheckListsStateModel): CheckListModel;
  getCheckListsCategoriesListBySelectedCheckList(
    state: ICheckListsStateModel
  ): CategoriesInCheckListModel[];
  getCheckListsListSimple(state: ICheckListsStateModel): BaseEntityModel[];
  getCheckListsListSimpleOnlyCheckLists(
    state: ICheckListsStateModel
  ): BaseEntityModel[];
};

export const getters: GetterTree<ICheckListsStateModel, IRootStateModel> &
  CheckListGetters = {
  getCheckListsList: (state: ICheckListsStateModel) => {
    return state.list;
  },
  getCheckListsListPage: (state: ICheckListsStateModel) => {
    return state.page;
  },
  getCheckListsListPerPage: (state: ICheckListsStateModel) => {
    return state.perPage;
  },
  getCheckListsTotal: (state: ICheckListsStateModel) => {
    return state.total;
  },
  isCheckListsDataLoading: (state: ICheckListsStateModel) => {
    return state.dataIsLoading;
  },
  getCheckListsFilterValue: (state: ICheckListsStateModel) => {
    return state.filter;
  },
  getCheckListsSortValue: (state: ICheckListsStateModel) => {
    return state.sort;
  },
  getCheckListsSelectedCheckList: (state: ICheckListsStateModel) => {
    return state.selectedCheckList;
  },
  getCheckListsCategoriesListBySelectedCheckList: (
    state: ICheckListsStateModel
  ) => {
    return state.categoriesListBySelectedCheckList;
  },
  getCheckListsListSimple: (state: ICheckListsStateModel) => {
    return state.listSimple;
  },
  getCheckListsListSimpleOnlyCheckLists: (state: ICheckListsStateModel) => {
    return state.listSimple.map((item) => ({ id: item.id, name: item.name }));
  },
};
