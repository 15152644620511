import { MutationTree } from "vuex";
import { MutationTypes } from "./types";
import { IProjectIntegrationStateModel } from "./../../../interfaces";
import {
  BaseEntityModel,
  ProjectIntegration,
  ProjectIntegrationModel,
  ProjectsFunnelsListModel,
} from "@/services/types";

export type ProjectIntegrationMutations<S = IProjectIntegrationStateModel> = {
  [MutationTypes.SET_PROJECT_INTEGRATION_TYPE](state: S, payload: string): void;
  [MutationTypes.SET_PROJECT_INTEGRATION_DATA_IS_LOADING](
    state: S,
    payload: boolean
  ): void;
  [MutationTypes.SET_PROJECT_INTEGRATION_INTEGRATION](
    state: S,
    payload: ProjectIntegrationModel | null
  ): void;
  [MutationTypes.SET_PROJECT_INTEGRATION_FUNNELS_LIST](
    state: S,
    payload: ProjectsFunnelsListModel | null
  ): void;
  [MutationTypes.SET_PROJECT_INTEGRATION_INTEGRATIONS_LIST](
    state: S,
    payload: ProjectIntegration[]
  ): void;
  [MutationTypes.SET_PROJECT_INTEGRATION_SELECTED_PROJECT_INTEGRATION](
    state: S,
    payload: ProjectIntegration
  ): void;
  [MutationTypes.SET_PROJECT_INTEGRATION_CALLS_LIST_IS_LOADING](
    state: S,
    payload: boolean
  ): void;
  [MutationTypes.SET_BITRIX_INTEGRATION](state: S, payload): void;
};

export const mutations: MutationTree<IProjectIntegrationStateModel> &
  ProjectIntegrationMutations = {
  [MutationTypes.SET_PROJECT_INTEGRATION_TYPE](state, payload: string) {
    state.projectIntegrationType = payload;
  },
  [MutationTypes.SET_PROJECT_INTEGRATION_DATA_IS_LOADING](
    state,
    payload: boolean
  ) {
    state.dataIsLoading = payload;
  },
  [MutationTypes.SET_PROJECT_INTEGRATION_INTEGRATION](
    state,
    payload: BaseEntityModel
  ) {
    state.projectIntegration = payload;
  },
  [MutationTypes.SET_PROJECT_INTEGRATION_FUNNELS_LIST](
    state,
    payload: ProjectsFunnelsListModel | null
  ) {
    state.funnelsList = payload;
  },
  [MutationTypes.SET_PROJECT_INTEGRATION_INTEGRATIONS_LIST](
    state,
    payload: ProjectIntegration[]
  ) {
    state.selectedProjectIntegrationsList = payload;
  },
  [MutationTypes.SET_PROJECT_INTEGRATION_SELECTED_PROJECT_INTEGRATION](
    state,
    payload: ProjectIntegration
  ) {
    state.selectedProjectIntegration = payload;
  },
  [MutationTypes.SET_PROJECT_INTEGRATION_CALLS_LIST_IS_LOADING](
    state,
    payload: boolean
  ) {
    state.callsListIsLoading = payload;
  },
  [MutationTypes.SET_BITRIX_INTEGRATION](state, payload) {
    state.bitrixIntegration = payload;
  },
};
