import { ActionContext, ActionTree } from "vuex";
import { IRootStateModel } from "@/store/interfaces";
import { CardsMutations } from "@/store/modules/cards/mutations";
import { CardsStateModel } from "@/store/modules/cards/state";
import { CardsActionTypes } from "@/store/modules/cards/actions/types";
import { BaseClient } from "@/services/client/BaseClient";
import { getClientInstance } from "@/services/client/clientProvider";
import { CardsMutationTypes } from "@/store/modules/cards/mutations/types";
import { ProjectWithChecklistsModel } from "@/services/types/project";
import { CardModel, CardsListFilterModel } from "@/services/types/cards";
import { ProjectType } from "@/services/types/ProjectType";
import { setCardsChecklists } from "@/services/cards/cardsChecklistsStore";
import apiClient from "@/services/api/apiClient";
import { getStore } from "@/store/getStore";

type AugmentedActionCardsContext = {
  commit<K extends keyof CardsMutations>(
    key: K,
    payload: Parameters<CardsMutations[K]>[1]
  ): ReturnType<CardsMutations[K]>;
} & Omit<ActionContext<CardsStateModel, IRootStateModel>, "commit">;

export type CardsActions = {
  [CardsActionTypes.SET_CARDS_LIST_PROJECTS_LIST](
    context: AugmentedActionCardsContext
  ): Promise<void>;
  [CardsActionTypes.SET_CARDS_LIST_FILTER](
    context: AugmentedActionCardsContext,
    payload: CardsListFilterModel
  ): void;
  [CardsActionTypes.SET_CARDS_LIST](
    context: AugmentedActionCardsContext
  ): Promise<void>;
  [CardsActionTypes.SET_CARDS_LIST_MANAGERS_LIST](
    context: AugmentedActionCardsContext,
    payload: number
  ): Promise<void>;
};

export const actions: ActionTree<CardsStateModel, IRootStateModel> &
  CardsActions = {
  async [CardsActionTypes.SET_CARDS_LIST_MANAGERS_LIST](
    { commit },
    payload
  ): Promise<void> {
    const client: BaseClient = getClientInstance();
    try {
      const resp = await client.getProjectCardManagers(payload);
      commit(CardsMutationTypes.SET_CARDS_LIST_MANAGERS_LIST, resp.items);
    } catch (e) {
      console.error(CardsActionTypes.SET_CARDS_LIST_MANAGERS_LIST, e);
    }
  },
  async [CardsActionTypes.SET_CARDS_LIST_PROJECTS_LIST]({
    commit,
  }): Promise<void> {
    const client: BaseClient = getClientInstance();

    try {
      const resp = await client.getProjectsFullList();

      commit(
        CardsMutationTypes.SET_CARDS_LIST_PROJECTS_LIST,
        resp.items.filter(
          (p) => p["type"] === ProjectType.CARD
        ) as ProjectWithChecklistsModel[]
      );
    } catch (e) {
      console.error(CardsActionTypes.SET_CARDS_LIST_PROJECTS_LIST, e);
    }
  },
  [CardsActionTypes.SET_CARDS_LIST_FILTER]({ commit }, payload) {
    commit(CardsMutationTypes.SET_CARDS_LIST_FILTER, payload);
  },
  async [CardsActionTypes.SET_CARDS_LIST]({ commit, state }) {
    const client: BaseClient = getClientInstance();
    try {
      commit(CardsMutationTypes.SET_CARDS_IS_LOADING, true);

      const cards = await apiClient.reportsApi.getCardsReport({
        filter: getStore().getters.getCardsFilter,
      });
      commit(CardsMutationTypes.SET_CARDS_LIST, cards);

      // set checklists
      if (state.projects.length) {
        setCardsChecklists(state.projects.map((p) => p.checklists).flat());
      } else {
        setCardsChecklists(
          (
            await client.getCheckListsList({
              perPage: 999,
              page: 1,
              filter: {
                search: "",
              },
              sort: {
                sort: "name",
                direction: "asc",
              },
            })
          ).items
        );
      }
    } catch (e) {
      console.error(CardsActionTypes.SET_CARDS_LIST, e);
    } finally {
      commit(CardsMutationTypes.SET_CARDS_IS_LOADING, false);
    }
  },
};
