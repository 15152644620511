import { ActionContext, ActionTree } from "vuex";
import { ICommentsStateModel, IRootStateModel } from "../../../interfaces";
import { CommentsMutations } from "./../mutations";
import { ActionTypes } from "./types";
import { MutationTypes } from "./../mutations/types";
import {
  CallModel,
  CreateCommentRequestModel,
  EditCommentRequestModel,
} from "./../../../../services/types";
import { StoreActionTypes } from "@/store/types";
import apiClient from "@/services/api/apiClient";
import { CommentModel } from "@/services/types/entities/comments";
import {
  CreateEntityCommentRequestDto,
  UpdateEntityCommentRequestDto,
} from "@/services/api/entities/types";

type AugmentedActionCommentsContext = {
  commit<K extends keyof CommentsMutations>(
    key: K,
    payload: Parameters<CommentsMutations[K]>[1]
  ): ReturnType<CommentsMutations[K]>;
} & Omit<ActionContext<ICommentsStateModel, IRootStateModel>, "commit">;

export interface CommentsActions {
  [ActionTypes.SET_COMMENTS_LIST](
    context: AugmentedActionCommentsContext
  ): Promise<void>;

  [ActionTypes.SET_COMMENTS_DELETE_LIST](
    context: AugmentedActionCommentsContext,
    ids: number[]
  ): Promise<void>;

  [ActionTypes.SET_COMMENTS_EDIT_SELECTED_COMMENT](
    context: AugmentedActionCommentsContext,
    model: UpdateEntityCommentRequestDto
  ): Promise<void>;

  [ActionTypes.SET_COMMENTS_CREATE_COMMENT](
    context: AugmentedActionCommentsContext,
    model: CreateEntityCommentRequestDto
  ): Promise<void>;

  [ActionTypes.SET_COMMENTS_SELECTED_COMMENT](
    context: AugmentedActionCommentsContext,
    id: number
  ): void;

  [ActionTypes.SET_COMMENTS_SELECTED_COMMENT_CLEAR](
    context: AugmentedActionCommentsContext
  ): void;

  [ActionTypes.SET_COMMENTS_HAS_NOT_SAVED_COMMENT](
    context: AugmentedActionCommentsContext,
    model: boolean
  ): void;
}

export const actions: ActionTree<ICommentsStateModel, IRootStateModel> &
  CommentsActions = {
  async [ActionTypes.SET_COMMENTS_LIST]({ commit, getters }) {
    // const client: BaseClient = getClientInstance();

    commit(MutationTypes.SET_COMMENTS_DATA_IS_LOADING, true);

    const callId: number = (getters.getSelectedCall as CallModel).id;

    try {
      const response = await apiClient.entitiesApi.getEntityComments(callId);

      commit(MutationTypes.SET_COMMENTS_LIST, response.items);
    } catch (error) {
      console.error("getCommentsList", error);
    } finally {
      commit(MutationTypes.SET_COMMENTS_DATA_IS_LOADING, false);
    }
  },
  async [ActionTypes.SET_COMMENTS_DELETE_LIST](
    { commit, getters, dispatch },
    ids: number[]
  ) {
    commit(MutationTypes.SET_COMMENTS_DATA_IS_LOADING, true);

    const callId: number = (getters.getSelectedCall as CallModel).id;

    try {
      await apiClient.entitiesApi.removeEntityComments(callId, { ids });

      const selectedComment: CommentModel = getters.getCommentsSelectedComment;

      if (selectedComment && ids.indexOf(selectedComment.id) >= 0) {
        commit(MutationTypes.SET_COMMENTS_SELECTED_COMMENT, null);
      }

      await dispatch(ActionTypes.SET_COMMENTS_LIST);
    } catch (error) {
      console.error("deleteCommentsList", error);
    } finally {
      commit(MutationTypes.SET_COMMENTS_DATA_IS_LOADING, false);
    }
  },
  async [ActionTypes.SET_COMMENTS_EDIT_SELECTED_COMMENT](
    { commit, getters, dispatch },
    model: UpdateEntityCommentRequestDto
  ) {
    commit(MutationTypes.SET_COMMENTS_DATA_IS_LOADING, true);

    const selectedComment = getters.getCommentsSelectedComment;
    try {
      await apiClient.entitiesApi.updateEntityComment(
        selectedComment.entityId,
        selectedComment.id,
        model
      );

      dispatch(ActionTypes.SET_COMMENTS_SELECTED_COMMENT_CLEAR);

      await dispatch(ActionTypes.SET_COMMENTS_LIST);
    } catch (error) {
      console.error("editComment", error);
    } finally {
      commit(MutationTypes.SET_COMMENTS_DATA_IS_LOADING, false);
    }
  },
  async [ActionTypes.SET_COMMENTS_CREATE_COMMENT](
    { commit, getters, dispatch },
    model
  ) {
    commit(MutationTypes.SET_COMMENTS_DATA_IS_LOADING, true);

    const callId: number = (getters.getSelectedCall as CallModel).id;
    try {
      await apiClient.entitiesApi.createEntityComment(callId, model);
      await dispatch(ActionTypes.SET_COMMENTS_LIST);
    } catch (error) {
      console.error("createComment", error);
    } finally {
      commit(MutationTypes.SET_COMMENTS_DATA_IS_LOADING, false);
    }
  },
  [ActionTypes.SET_COMMENTS_SELECTED_COMMENT](
    { commit, getters, dispatch },
    id: number
  ) {
    const list: CommentModel[] = getters.getCommentsList;

    const selectedComment = list.find((item) => item.id == id);

    if (selectedComment.data.position.timeOnTrack) {
      dispatch(
        StoreActionTypes.SET_PLAYER_NEW_CURRENT_TIME,
        selectedComment.data.position.timeOnTrack
      );
    }

    commit(MutationTypes.SET_COMMENTS_SELECTED_COMMENT, selectedComment);
  },
  [ActionTypes.SET_COMMENTS_SELECTED_COMMENT_CLEAR]({ commit }) {
    commit(MutationTypes.SET_COMMENTS_SELECTED_COMMENT, null);
    commit(MutationTypes.SET_COMMENTS_HAS_NOT_SAVED_COMMENT, false);
  },
  [ActionTypes.SET_COMMENTS_HAS_NOT_SAVED_COMMENT]({ commit }, model: boolean) {
    commit(MutationTypes.SET_COMMENTS_HAS_NOT_SAVED_COMMENT, model);
  },
};
