import { MutationTree } from "vuex";
import { MutationTypes } from "./types";
import { ICheckListsStateModel } from "./../../../interfaces";
import {
  CheckListModel,
  CheckListsListSortModel,
  CheckListsListFilterModel,
  CategoriesInCheckListModel,
  CheckListWithCategoriesSimpleModel,
} from "@/services/types";

export type CheckListMutations<S = ICheckListsStateModel> = {
  [MutationTypes.SET_CHECKLISTS_LIST](
    state: S,
    payload: CheckListModel[]
  ): void;
  [MutationTypes.SET_CHECKLISTS_LIST_PAGE](state: S, payload: number): void;
  [MutationTypes.SET_CHECKLISTS_LIST_PER_PAGE](state: S, payload: number): void;
  [MutationTypes.SET_CHECKLISTS_TOTAL](state: S, payload: number): void;
  [MutationTypes.SET_CHECKLISTS_DATA_IS_LOADING](
    state: S,
    payload: boolean
  ): void;
  [MutationTypes.SET_CHECKLISTS_SORT_VALUE](
    state: S,
    payload: CheckListsListSortModel
  ): void;
  [MutationTypes.SET_CHECKLISTS_FILTER_VALUE](
    state: S,
    payload: CheckListsListFilterModel
  ): void;
  [MutationTypes.SET_CHECKLISTS_SELECTED_CHECKLIST](
    state: S,
    payload: CheckListModel
  ): void;
  [MutationTypes.SET_CHECKLISTS_CATEGORIES_LIST_BY_SELECTED_CHECKLIST](
    state: S,
    payload: CategoriesInCheckListModel[]
  ): void;
  [MutationTypes.SET_CHECKLISTS_LIST_SIMPLE](
    state: S,
    payload: CheckListWithCategoriesSimpleModel[]
  ): void;
};

export const mutations: MutationTree<ICheckListsStateModel> &
  CheckListMutations = {
  [MutationTypes.SET_CHECKLISTS_LIST](state, payload: CheckListModel[]) {
    state.list = payload;
  },
  [MutationTypes.SET_CHECKLISTS_LIST_PAGE](state, payload: number) {
    state.page = payload;
  },
  [MutationTypes.SET_CHECKLISTS_LIST_PER_PAGE](state, payload: number) {
    state.perPage = payload;
  },
  [MutationTypes.SET_CHECKLISTS_TOTAL](state, payload: number) {
    state.total = payload;
  },
  [MutationTypes.SET_CHECKLISTS_DATA_IS_LOADING](state, payload: boolean) {
    state.dataIsLoading = payload;
  },
  [MutationTypes.SET_CHECKLISTS_SORT_VALUE](
    state,
    payload: CheckListsListSortModel
  ) {
    state.sort = payload;
  },
  [MutationTypes.SET_CHECKLISTS_FILTER_VALUE](
    state,
    payload: CheckListsListFilterModel
  ) {
    state.filter = payload;
  },
  [MutationTypes.SET_CHECKLISTS_SELECTED_CHECKLIST](
    state,
    payload: CheckListModel
  ) {
    state.selectedCheckList = payload;
  },
  [MutationTypes.SET_CHECKLISTS_CATEGORIES_LIST_BY_SELECTED_CHECKLIST](
    state,
    payload: CategoriesInCheckListModel[]
  ) {
    state.categoriesListBySelectedCheckList = payload;
  },
  [MutationTypes.SET_CHECKLISTS_LIST_SIMPLE](
    state,
    payload: CheckListWithCategoriesSimpleModel[]
  ) {
    state.listSimple = payload;
  },
};
