import { ProjectWithChecklistsModel } from "@/services/types/project";
import { CardModel, CardsListFilterModel } from "@/services/types/cards";
import { NameIdModel } from "@/services/types/entities/base";

export interface CardsStateModel {
  projects: ProjectWithChecklistsModel[];
  managers: NameIdModel[];
  filter: CardsListFilterModel;
  cards: CardModel[];
  isLoading: boolean;
}

export const state: CardsStateModel = {
  cards: [],
  projects: [],
  managers: [],
  filter: {},
  isLoading: false,
};
