import { GetterTree } from "vuex";
import { IReportsStateModel, IRootStateModel } from "./../../../interfaces";
import {
  BaseEntityModel,
  BaseReportRenderModel,
  ChartReportItemModel,
  DateIntervalsType,
  ReportCallModel,
  ReportCallsListSortModel,
  ReportItem,
  ReportsListDateFilterModel,
  ReportsPageType,
} from "@/services/types";
import * as helpers from "../helpers";
import * as utils from "@/services/utils";

export type ReportsGetters = {
  getReportsRenderList(state: IReportsStateModel): BaseReportRenderModel[];
  isReportsDataLoading(state: IReportsStateModel): boolean;
  getReportsFilterValue(state: IReportsStateModel): ReportsListDateFilterModel;
  getReportsSelectedRenderItemsList(
    state: IReportsStateModel
  ): BaseReportRenderModel[];
  getReportsLineChartsList(state: IReportsStateModel): ChartReportItemModel[];
  getReportsLineChartsLabels(state: IReportsStateModel): string[];
  getReportsBarChartsList(state: IReportsStateModel): ChartReportItemModel[];
  getReportsBarChartsLabels(state: IReportsStateModel): string[];
  getReportsProjectsList(state: IReportsStateModel): BaseEntityModel[];
  getReportsSelectedProjectsList(state: IReportsStateModel): BaseEntityModel[];
  getReportsRawList(state: IReportsStateModel): ReportItem[];
  getReportsReportsList(state: IReportsStateModel): BaseEntityModel[];
  getReportsPage(state: IReportsStateModel): ReportsPageType;
  getReportsSelectedManager(state: IReportsStateModel): BaseEntityModel;
  getReportsSelectedCriterion(state: IReportsStateModel): BaseEntityModel;
  getReportsDateInterval(state: IReportsStateModel): DateIntervalsType;
  getReportsCallsList(state: IReportsStateModel): ReportCallModel[];
  getReportsCallsListSortValue(
    state: IReportsStateModel
  ): ReportCallsListSortModel;
};

export const getters: GetterTree<IReportsStateModel, IRootStateModel> &
  ReportsGetters = {
  getReportsRenderList: (state: IReportsStateModel) => {
    return state.reportsRenderList;
  },
  isReportsDataLoading: (state: IReportsStateModel) => {
    return state.dataIsLoading;
  },
  getReportsFilterValue: (state: IReportsStateModel) => {
    return state.filter;
  },
  getReportsSelectedRenderItemsList: (state: IReportsStateModel) => {
    return state.reportsRenderList.filter((item) => item.selected);
  },
  getReportsLineChartsList: (state: IReportsStateModel) => {
    return state.reportsRenderList
      .filter((item) => item.selected)
      .map((item) => helpers.getLineChartItem(item));
  },
  getReportsLineChartsLabels: (state: IReportsStateModel) => {
    return state.labels.map((label) =>
      state.isDay
        ? utils.getFormattedTime(new Date(label))
        : utils.getFormattedDate(new Date(label))
    );
  },
  getReportsBarChartsList: (state: IReportsStateModel) => {
    return state.reportsRenderList
      .filter((item) => item.selected)
      .map((item, idx, arr) => helpers.getBarChartItem(item, idx, arr.length));
  },
  getReportsBarChartsLabels: (state: IReportsStateModel) => {
    return state.reportsRenderList
      .filter((item) => item.selected)
      .map((item) => helpers.getBarChartLabel(item));
  },

  getReportsProjectsList: (state: IReportsStateModel) => {
    return state.projects;
  },
  getReportsSelectedProjectsList: (state: IReportsStateModel) => {
    return state.selectedProjects;
  },
  getReportsRawList: (state: IReportsStateModel) => {
    return state.rawList;
  },
  getReportsReportsList: (state: IReportsStateModel) => {
    return state.reports;
  },
  getReportsPage: (state: IReportsStateModel) => {
    return state.page;
  },
  getReportsSelectedManager: (state: IReportsStateModel) => {
    return state.selectedManager;
  },
  getReportsSelectedCriterion: (state: IReportsStateModel) => {
    return state.selectedCriterion;
  },
  getReportsDateInterval: (state: IReportsStateModel) => {
    return state.dateInterval;
  },
  getReportsCallsList: (state: IReportsStateModel) => {
    return state.reportCallsList;
  },
  getReportsCallsListSortValue: (state: IReportsStateModel) => {
    return state.reportCallsListSort;
  },
};
