export enum ActionTypes {
  SET_CALLS_LIST = "setCallsList",
  SET_CALLS_LIST_PAGE = "setCallsListPage",
  SET_CALLS_LIST_PER_PAGE = "setCallsListPerPage",
  SET_CALLS_LIST_SORT = "setCallsListSort",
  SET_CALLS_LIST_FILTER = "setCallsListFilter",
  SET_CALLS_SELECTED_GRID_COLUMNS = "setCallsSelectedGridColumns",
  SET_CALLS_MANAGERS_LIST = "setCallsManagersList",
  SET_CALLS_PROJECTS_LIST = "setCallsProjectsList",
  SET_CALLS_LINKED_PHONES_LIST = "setCallsLinkedPhonesList",
  SET_CALLS_LIST_LINKED_PHONE_FILTER = "setCallsListLinkedPhoneFilter",
}
