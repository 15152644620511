import { Module } from "vuex";
import { state } from "./state";
import { EwaStateModel, IRootStateModel } from "./../../interfaces";
import { mutations } from "./mutations";
import { getters } from "./getters";
import { actions } from "./actions";

const ewa: Module<EwaStateModel, IRootStateModel> = {
  state,
  mutations,
  getters,
  actions,
};

export default ewa;
