import { MutationTree } from "vuex";
import { MutationTypes } from "./types";
import { ISelectedCallStateModel } from "./../../../interfaces";
import {
  CallModel,
  CallCriterionsGroupedByCategoryModel,
  EstimatedCheckListModel,
  CallCriterionEstimateModel,
  BaseEntityModel,
} from "@/services/types";

export type SelectedCallMutations<S = ISelectedCallStateModel> = {
  [MutationTypes.SET_SELECTED_CALL_DATA_IS_LOADING](
    state: S,
    payload: boolean
  ): void;
  [MutationTypes.SET_SELECTED_CALL](state: S, payload: CallModel): void;
  [MutationTypes.SET_SELECTED_CALL_CATEGORIES](
    state: S,
    payload: CallCriterionsGroupedByCategoryModel[]
  ): void;
  [MutationTypes.SET_SELECTED_CALL_ESTIMATED_CHECK_LIST](
    state: S,
    payload: EstimatedCheckListModel
  ): void;
  [MutationTypes.SET_SELECTED_CALL_CHECK_LIST_ID](
    state: S,
    payload: number
  ): void;
  [MutationTypes.SET_SELECTED_CALL_RATED](state: S, payload: boolean): void;
  [MutationTypes.SET_SELECTED_CALL_SELECTED_AUDITOR_ID](
    state: S,
    payload: number
  ): void;
  [MutationTypes.SET_SELECTED_CALL_CURRENT_ESTIMATION](
    state: S,
    payload: any
  ): void;
  [MutationTypes.SET_SELECTED_CALL_AVAILABLE_MANAGERS_LIST](
    state: S,
    payload: BaseEntityModel[]
  ): void;
  [MutationTypes.SET_SELECTED_CALL_CURRENT_ESTIMATION_CLEAR](
    state: S,
    payload: CallCriterionEstimateModel[]
  ): void;
  [MutationTypes.SET_SELECTED_CALL_RATED_CLEAR](
    state: S,
    payload: boolean
  ): void;
  [MutationTypes.SET_SELECTED_CALL_CLEAR](
    state: S,
    payload: ISelectedCallStateModel
  ): void;
  [MutationTypes.SET_TOP_COLLAPSE_OPENED](state: S, payload: boolean): void;
};

export const mutations: MutationTree<ISelectedCallStateModel> &
  SelectedCallMutations = {
  [MutationTypes.SET_SELECTED_CALL_DATA_IS_LOADING](state, payload: boolean) {
    state.dataIsLoading = payload;
  },
  [MutationTypes.SET_TOP_COLLAPSE_OPENED](state, payload: boolean) {
    state.topCollapseOpened = payload;
  },
  [MutationTypes.SET_SELECTED_CALL](state, payload: CallModel) {
    state.selectedCall = payload;
  },
  [MutationTypes.SET_SELECTED_CALL_CATEGORIES](
    state,
    payload: CallCriterionsGroupedByCategoryModel[]
  ) {
    state.categories = payload;
    state.allCriterionsChecked = false;
  },
  [MutationTypes.SET_SELECTED_CALL_ESTIMATED_CHECK_LIST](
    state,
    payload: EstimatedCheckListModel
  ) {
    state.estimatedCheckList = payload;
  },
  [MutationTypes.SET_SELECTED_CALL_SELECTED_AUDITOR_ID](
    state,
    payload: number
  ) {
    state.selectedAuditorId = payload;
  },
  [MutationTypes.SET_SELECTED_CALL_CHECK_LIST_ID](state, payload: number) {
    state.checklistId = payload;
  },
  [MutationTypes.SET_SELECTED_CALL_RATED](state, payload: boolean) {
    state.callRated = payload;
  },
  [MutationTypes.SET_SELECTED_CALL_AVAILABLE_MANAGERS_LIST](
    state,
    payload: BaseEntityModel[]
  ) {
    state.availableManagersList = payload;
  },
  [MutationTypes.SET_SELECTED_CALL_CURRENT_ESTIMATION](state, payload: any) {
    const selectedCallEstimation = state.currentEstimation as any;
    const estimationIdx = selectedCallEstimation.findIndex(
      (item) => item.id == payload.model.id
    );
    if (estimationIdx >= 0) {
      selectedCallEstimation[estimationIdx].optionId = payload.model.optionId;
    } else {
      selectedCallEstimation.push(payload.model);
    }

    state.currentEstimation = [...selectedCallEstimation];

    const length = JSON.parse(JSON.stringify(payload.categories))
      .map((item) => item.criterions)
      .flat().length;

    state.allCriterionsChecked = length == state.currentEstimation.length;
  },
  [MutationTypes.SET_SELECTED_CALL_CURRENT_ESTIMATION_CLEAR](
    state,
    payload: CallCriterionEstimateModel[]
  ) {
    state.currentEstimation = payload;
    state.allCriterionsChecked = false;
  },
  [MutationTypes.SET_SELECTED_CALL_RATED_CLEAR](state, payload: boolean) {
    state.allCriterionsChecked = payload;
  },
  [MutationTypes.SET_SELECTED_CALL_CLEAR](
    state,
    payload: ISelectedCallStateModel
  ) {
    state.callRated = payload.callRated;
    state.categories = payload.categories;
    state.checklistId = payload.checklistId;
    state.currentEstimation = payload.currentEstimation;
    state.dataIsLoading = payload.dataIsLoading;
    state.estimatedCheckList = payload.estimatedCheckList;
    state.selectedCall = payload.selectedCall;
    state.allCriterionsChecked = payload.allCriterionsChecked;
    state.availableManagersList = payload.availableManagersList;
  },
};
