import { ActionTree, ActionContext } from "vuex";
import { IProjectsStateModel, IRootStateModel } from "../../../interfaces";
import { ProjectsMutations } from "./../mutations";
import { ActionTypes } from "./types";
import { MutationTypes } from "./../mutations/types";
import {
  ProjectsListResponseModel,
  ProjectsListSortModel,
  ProjectsListFilterModel,
  DeleteProjectsListRequestModel,
  ProjectsDetailsResponseModel,
  GetProjectCheckListResponseModel,
  UpdateProjectCheckListsRequestModel,
  ProjectModel,
  SubscriptionItemDataModel,
  ProjectLimitsModel,
} from "./../../../../services/types";
import { BaseClient } from "./../../../../services/client/BaseClient";
import { getClientInstance } from "./../../../..//services/client/clientProvider";
import { StoreActionTypes } from "@/store/types";

type AugmentedActionProjectsContext = {
  commit<K extends keyof ProjectsMutations>(
    key: K,
    payload: Parameters<ProjectsMutations[K]>[1]
  ): ReturnType<ProjectsMutations[K]>;
} & Omit<ActionContext<IProjectsStateModel, IRootStateModel>, "commit">;

export interface ProjectsActions {
  [ActionTypes.SET_PROJECTS_LIST](
    context: AugmentedActionProjectsContext
  ): Promise<void>;
  [ActionTypes.SET_PROJECTS_LIST_CARDS](
    context: AugmentedActionProjectsContext
  ): Promise<void>;
  [ActionTypes.SET_PROJECTS_LIST_CALLS](
    context: AugmentedActionProjectsContext
  ): Promise<void>;
  [ActionTypes.SET_PROJECTS_FULL_LIST](
    context: AugmentedActionProjectsContext
  ): Promise<void>;
  [ActionTypes.SET_PROJECTS_LIST_CLEAR](
    context: AugmentedActionProjectsContext
  ): void;
  [ActionTypes.SET_PROJECTS_LIST_PAGE](
    context: AugmentedActionProjectsContext,
    page: number
  ): void;
  [ActionTypes.SET_PROJECTS_LIST_PER_PAGE](
    context: AugmentedActionProjectsContext,
    perPage: number
  ): void;
  [ActionTypes.SET_PROJECTS_LIST_SORT](
    context: AugmentedActionProjectsContext,
    sort: ProjectsListSortModel
  ): void;
  [ActionTypes.SET_PROJECTS_LIST_FILTER](
    context: AugmentedActionProjectsContext,
    sort: ProjectsListFilterModel
  ): void;
  [ActionTypes.SET_PROJECTS_PROJECT_DELETE](
    context: AugmentedActionProjectsContext,
    model: DeleteProjectsListRequestModel
  ): Promise<void>;
  [ActionTypes.SET_PROJECTS_SELECTED_PROJECT](
    context: AugmentedActionProjectsContext,
    id: number | null
  ): Promise<void>;
  [ActionTypes.SET_PROJECTS_SELECTED_PROJECT_CLEAR](
    context: AugmentedActionProjectsContext | null
  ): void;
  [ActionTypes.SET_PROJECTS_PROJECT_CHANGE_NAME](
    context: AugmentedActionProjectsContext,
    name: string
  ): Promise<void>;
  [ActionTypes.SET_PROJECTS_SELECTED_PROJECT_CHECKLIST_GET](
    context: AugmentedActionProjectsContext
  ): Promise<void>;
  [ActionTypes.SET_PROJECTS_SELECTED_PROJECT_UPDATE_CHECKLIST](
    context: AugmentedActionProjectsContext,
    model: UpdateProjectCheckListsRequestModel
  ): Promise<void>;
  [ActionTypes.SET_PROJECTS_LIMITS](
    context: AugmentedActionProjectsContext
  ): Promise<void>;
}

export const actions: ActionTree<IProjectsStateModel, IRootStateModel> &
  ProjectsActions = {
  async [ActionTypes.SET_PROJECTS_LIST]({ commit, getters }) {
    const client: BaseClient = getClientInstance();

    commit(MutationTypes.SET_PROJECTS_DATA_IS_LOADING, true);

    try {
      const response: ProjectsListResponseModel = await client.getProjectsList({
        page: getters.getProjectsListPage as number,
        perPage: getters.getProjectsListPerPage as number,
        filter: getters.getProjectsFilterValue as ProjectsListFilterModel,
        sort: getters.getProjectsSortValue as ProjectsListSortModel,
      });

      commit(MutationTypes.SET_PROJECTS_LIST, response.items);
      commit(MutationTypes.SET_PROJECTS_TOTAL, response.total);
    } catch (error) {
      console.error("getProjectsList", error);
    } finally {
      commit(MutationTypes.SET_PROJECTS_DATA_IS_LOADING, false);
    }
  },
  async [ActionTypes.SET_PROJECTS_LIST_CALLS]({ commit, getters }) {
    const client: BaseClient = getClientInstance();

    commit(MutationTypes.SET_PROJECTS_DATA_IS_LOADING, true);

    try {
      const response: ProjectsListResponseModel = await client.getProjectsList({
        page: getters.getProjectsListPage as number,
        perPage: getters.getProjectsListPerPage as number,
        filter: {
          ...(getters.getProjectsFilterValue as ProjectsListFilterModel),
          type: "CALL",
        },
        sort: getters.getProjectsSortValue as ProjectsListSortModel,
      });

      commit(MutationTypes.SET_PROJECTS_LIST, response.items);
      commit(MutationTypes.SET_PROJECTS_TOTAL, response.total);
    } catch (error) {
      console.error("getProjectsList", error);
    } finally {
      commit(MutationTypes.SET_PROJECTS_DATA_IS_LOADING, false);
    }
  },
  async [ActionTypes.SET_PROJECTS_LIST_CARDS]({ commit, getters }) {
    const client: BaseClient = getClientInstance();

    commit(MutationTypes.SET_PROJECTS_DATA_IS_LOADING, true);

    try {
      const response: ProjectsListResponseModel = await client.getProjectsList({
        page: getters.getProjectsListPage as number,
        perPage: getters.getProjectsListPerPage as number,
        filter: {
          ...(getters.getProjectsFilterValue as ProjectsListFilterModel),
          type: "CARD",
        },
        sort: getters.getProjectsSortValue as ProjectsListSortModel,
      });

      commit(MutationTypes.SET_PROJECTS_LIST, response.items);
      commit(MutationTypes.SET_PROJECTS_TOTAL, response.total);
    } catch (error) {
      console.error("getProjectsList", error);
    } finally {
      commit(MutationTypes.SET_PROJECTS_DATA_IS_LOADING, false);
    }
  },

  async [ActionTypes.SET_PROJECTS_FULL_LIST]({ commit }) {
    const client: BaseClient = getClientInstance();

    try {
      const response: ProjectsListResponseModel =
        await client.getProjectsFullList();

      commit(MutationTypes.SET_PROJECTS_FULL_LIST, response.items);
    } catch (err) {
      console.error("getProjectsFullList", err);
    }
  },

  [ActionTypes.SET_PROJECTS_LIST_CLEAR]({ commit }) {
    commit(MutationTypes.SET_PROJECTS_LIST, []);
    commit(MutationTypes.SET_PROJECTS_TOTAL, 0);
    commit(MutationTypes.SET_PROJECTS_FILTER_VALUE, {
      search: "",
    });
    commit(MutationTypes.SET_PROJECTS_LIST_PAGE, 1);
  },
  [ActionTypes.SET_PROJECTS_LIST_PAGE]({ commit }, page: number) {
    commit(MutationTypes.SET_PROJECTS_LIST_PAGE, page);
  },
  [ActionTypes.SET_PROJECTS_LIST_PER_PAGE]({ commit }, perPage: number) {
    commit(MutationTypes.SET_PROJECTS_LIST_PER_PAGE, perPage);
  },
  [ActionTypes.SET_PROJECTS_LIST_SORT](
    { commit },
    sort: ProjectsListSortModel
  ) {
    commit(MutationTypes.SET_PROJECTS_SORT_VALUE, sort);
  },
  [ActionTypes.SET_PROJECTS_LIST_FILTER](
    { commit },
    filter: ProjectsListFilterModel
  ) {
    commit(MutationTypes.SET_PROJECTS_FILTER_VALUE, filter);
    commit(MutationTypes.SET_PROJECTS_LIST_PAGE, 1);
  },
  async [ActionTypes.SET_PROJECTS_PROJECT_DELETE](
    { commit },
    model: DeleteProjectsListRequestModel
  ) {
    const client: BaseClient = getClientInstance();
    commit(MutationTypes.SET_PROJECTS_DATA_IS_LOADING, true);

    try {
      await client.deleteProjectsList(model);
    } catch (error) {
      console.error("deleteProjectsList", error);
    } finally {
      commit(MutationTypes.SET_PROJECTS_DATA_IS_LOADING, false);
    }
  },
  async [ActionTypes.SET_PROJECTS_SELECTED_PROJECT]({ commit }, id: number) {
    const client: BaseClient = getClientInstance();
    commit(MutationTypes.SET_PROJECTS_DATA_IS_LOADING, true);

    try {
      const response: ProjectsDetailsResponseModel =
        await client.getProjectDetails(id);
      commit(MutationTypes.SET_PROJECTS_SELECTED_PROJECT, response);
    } catch (error) {
      console.error("getProjectDetails", error);
    } finally {
      commit(MutationTypes.SET_PROJECTS_DATA_IS_LOADING, false);
    }
  },
  [ActionTypes.SET_PROJECTS_SELECTED_PROJECT_CLEAR]({ commit, dispatch }) {
    commit(MutationTypes.SET_PROJECTS_SELECTED_PROJECT, null);
    commit(MutationTypes.SET_PROJECTS_SELECTED_PROJECT_CHECKLIST, []);
    dispatch(
      StoreActionTypes.SET_PROJECT_INTEGRATION_INTEGRATIONS_LIST_CLEAR,
      []
    );
  },
  async [ActionTypes.SET_PROJECTS_PROJECT_CHANGE_NAME](
    { commit, dispatch, getters },
    name: string
  ) {
    const client: BaseClient = getClientInstance();
    commit(MutationTypes.SET_PROJECTS_DATA_IS_LOADING, true);
    const id = getters.getProjectsSelectedProject.id;

    try {
      await client.changeProjectName(id, { name });
    } catch (error) {
      console.error("changeCategoryName", error);
    } finally {
      dispatch(ActionTypes.SET_PROJECTS_SELECTED_PROJECT, id);
    }
  },
  async [ActionTypes.SET_PROJECTS_SELECTED_PROJECT_CHECKLIST_GET]({
    commit,
    getters,
  }) {
    const client: BaseClient = getClientInstance();
    commit(MutationTypes.SET_PROJECTS_DATA_IS_LOADING, true);
    const id = getters.getProjectsSelectedProject.id;

    try {
      const response: GetProjectCheckListResponseModel =
        await client.getProjectChecklists(id);
      commit(
        MutationTypes.SET_PROJECTS_SELECTED_PROJECT_CHECKLIST,
        response.items
      );
    } catch (error) {
      console.error("getProjectChecklists", error);
    } finally {
      commit(MutationTypes.SET_PROJECTS_DATA_IS_LOADING, false);
    }
  },
  async [ActionTypes.SET_PROJECTS_SELECTED_PROJECT_UPDATE_CHECKLIST](
    { commit, getters },
    model: UpdateProjectCheckListsRequestModel
  ) {
    const client: BaseClient = getClientInstance();

    commit(MutationTypes.SET_PROJECTS_DATA_IS_LOADING, true);

    try {
      const projectId = (getters.getProjectsSelectedProject as ProjectModel).id;
      await client.updateProjectCheckLists(
        projectId,
        JSON.parse(JSON.stringify(model))
      );
    } catch (error) {
      console.error("updateProjectCheckLists", error);
    } finally {
      commit(MutationTypes.SET_PROJECTS_DATA_IS_LOADING, false);
    }
  },
  async [ActionTypes.SET_PROJECTS_LIMITS]({ commit }) {
    const client: BaseClient = getClientInstance();
    commit(MutationTypes.SET_PROJECTS_DATA_IS_LOADING, true);

    try {
      const response: any = await client.getProjectsLimits();
      commit(MutationTypes.SET_PROJECTS_LIMITS, response);
    } catch (error) {
      console.error("getProjectsLimits", error);
    } finally {
      commit(MutationTypes.SET_PROJECTS_DATA_IS_LOADING, false);
    }
  },
};
