import { IProjectsStateModel } from "./../../interfaces";

export const state: IProjectsStateModel = {
  list: [],
  fullList: [],
  page: 1,
  perPage: 20,
  total: 0,
  dataIsLoading: false,
  selectedProject: null,
  filter: {
    search: "",
  },
  sort: {
    direction: "asc",
    sort: "name",
  },
  selectedProjectCheckLists: [],
  limits: null,
};
