import { SelectModel, StatusType } from "@/services/types/base";
import { i18n } from "@/i18n";

export const getStatus = (status: StatusType): string => {
  let label = i18n.t("forms.status.label") as string;
  if (status == "ACTIVE") {
    label = i18n.t("forms.status.active") as string;
  } else if (status == "INACTIVE") {
    label = i18n.t("forms.status.deactive") as string;
  }
  return label;
};

export function getStatusSelectOptions(): SelectModel[] {
  return [
    {
      value: "ACTIVE",
      text: getStatus("ACTIVE"),
    },
    {
      value: "INACTIVE",
      text: getStatus("INACTIVE"),
    },
  ];
}
