import { GetterTree } from "vuex";
import { GlobalStateModel, IRootStateModel } from "../../../interfaces";

export type GlobalGetters = {
  getShowContent(state: GlobalStateModel): boolean | null;
  getGlobalNotification(state: GlobalStateModel): string | null;
  getSavedFilters(state: GlobalStateModel);
};

export const getters: GetterTree<GlobalStateModel, IRootStateModel> &
  GlobalGetters = {
  getShowContent: (state: GlobalStateModel) => {
    return state.showContent;
  },
  getConfirmMoal: (state: GlobalStateModel) => {
    return state.confirmModal;
  },
  getGlobalNotification: (state: GlobalStateModel) => {
    return state.globalNotification;
  },
  getSavedFilters: (state: GlobalStateModel) => {
    return state.filters;
  },
};
