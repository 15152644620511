import { GetterTree } from "vuex";
import { IRootStateModel } from "@/store/interfaces";
import {
  BaseEntityModel,
  ReportItem,
  ReportItemRenderData,
  ReportsListDateFilterModel,
  ReportsPageType,
} from "@/services/types";
import {
  IReports2StateModel,
  Reports2Targets,
} from "@/store/modules/reports2/state";

export type Reports2Getters = {
  getIsReports2DataLoading(state: IReports2StateModel): boolean;
  getIsReports2DetailsLoading(state: IReports2StateModel): boolean;
  getReports2Projects(state: IReports2StateModel): BaseEntityModel[];
  getReports2SelectedProjects(state: IReports2StateModel): number[];
  getReports2SelectedProjectsObjects(
    state: IReports2StateModel
  ): BaseEntityModel[];
  getReports2Reports(state: IReports2StateModel): BaseEntityModel[];
  getReports2SelectedReports(state: IReports2StateModel): number[];
  getReports2RawData(state: IReports2StateModel): ReportItem[];
  getReports2RenderData(state: IReports2StateModel): ReportItemRenderData[];
  getReports2Page(state: IReports2StateModel): ReportsPageType;
  getReports2DateFilter(state: IReports2StateModel): ReportsListDateFilterModel;
  getReports2DateInterval(state: IReports2StateModel): number;
  getReports2Targets(state: IReports2StateModel): Reports2Targets;
  getReports2SelectedReportsSnapId(state: IReports2StateModel): string;
  getReports2FilterReportId(state: IReports2StateModel): number | null;
  getReports2FilterReportOptions(state: IReports2StateModel): BaseEntityModel[];
};

export const getters: GetterTree<IReports2StateModel, IRootStateModel> &
  Reports2Getters = {
  getIsReports2DataLoading(state): boolean {
    return state.isDataLoading;
  },
  getIsReports2DetailsLoading(state): boolean {
    return state.isDetailsLoading;
  },
  getReports2Projects(state) {
    return state.projects;
  },
  getReports2SelectedProjects(state) {
    return state.selectedProjects;
  },
  getReports2Reports(state) {
    return state.reports;
  },
  getReports2SelectedReports(state) {
    return state.selectedReports;
  },
  getReports2RawData(state) {
    return state.reportsRawData;
  },
  getReports2RenderData(state) {
    return state.reportsRenderData;
  },
  getReports2Page: (state: IReports2StateModel) => {
    return state.page;
  },
  getReports2DateFilter: (state: IReports2StateModel) => {
    return state.dateFilter;
  },
  getReports2DateInterval: (state: IReports2StateModel) => {
    return state.dateInterval;
  },
  getReports2SelectedProjectsObjects: (state: IReports2StateModel) => {
    return state.projects.filter((p) => state.selectedProjects.includes(p.id));
  },
  getReports2Targets: (state) => {
    return state.targets;
  },
  getReports2SelectedReportsSnapId: (state) => {
    return state.selectedReportsSnapId;
  },
  getReports2FilterReportId: (state) => {
    return state.filterReportId;
  },
  getReports2FilterReportOptions: (state) => {
    return state.filterReportOptions;
  },
};
