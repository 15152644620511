import { isUserHasPermission } from "@/services/roles/index";
import { UserPermissions } from "@/services/roles/permissionsEnum";

export function isUserCanAccessChildren(): boolean {
  return isUserHasPermission(UserPermissions.ACCESS_CHILDREN);
}

export function isUserCanManageChecklists(): boolean {
  return isUserHasPermission(UserPermissions.MANAGE_COMPANY_CHECKLISTS);
}

export function isUserCanManageProjects(): boolean {
  return isUserHasPermission(UserPermissions.MANAGE_COMPANY_PROJECTS);
}
