/* eslint-disable require-atomic-updates */
import { ActionTree, ActionContext } from "vuex";
import { ICategoriesStateModel, IRootStateModel } from "../../../interfaces";
import { CategoriesMutations } from "./../mutations";
import { ActionTypes } from "./types";
import { MutationTypes } from "./../mutations/types";
import {
  GetCategoryResponseModel,
  CategoriesListResponseModel,
  CategoryModel,
  CategoriesListSortModel,
  CategoriesListFilterModel,
  DeleteCategoriesListRequestModel,
  ChangeCategoryStatusModel,
  GetCategoryWithCriterionsResponseModel,
  CriterionModel,
  CategoriesFullListResponseModel,
  GetCheckListWithCriterionsResponseModel,
  GetCriterionResponseModel,
} from "./../../../../services/types";
import { BaseClient } from "./../../../../services/client/BaseClient";
import { getClientInstance } from "./../../../..//services/client/clientProvider";
import { StoreActionTypes } from "./../../../types";
declare const window: any;

type AugmentedActionCategoriesContext = {
  commit<K extends keyof CategoriesMutations>(
    key: K,
    payload: Parameters<CategoriesMutations[K]>[1]
  ): ReturnType<CategoriesMutations[K]>;
} & Omit<ActionContext<ICategoriesStateModel, IRootStateModel>, "commit">;

export interface CategoriesActions {
  [ActionTypes.SET_CATEGORIES_LIST](
    context: AugmentedActionCategoriesContext
  ): Promise<void>;
  [ActionTypes.SET_CATEGORIES_FULL_LIST](
    context: AugmentedActionCategoriesContext
  ): Promise<void>;
  [ActionTypes.SET_CATEGORIES_WITH_CRITERIONS_FULL_LIST](
    context: AugmentedActionCategoriesContext,
    id: number
  ): Promise<void>;
  [ActionTypes.SET_CATEGORIES_LIST_PAGE](
    context: AugmentedActionCategoriesContext,
    page: number
  ): void;
  [ActionTypes.SET_CATEGORIES_LIST_PER_PAGE](
    context: AugmentedActionCategoriesContext,
    perPage: number
  ): void;
  [ActionTypes.SET_CATEGORIES_LIST_SORT](
    context: AugmentedActionCategoriesContext,
    sort: CategoriesListSortModel
  ): void;
  [ActionTypes.SET_CATEGORIES_LIST_FILTER](
    context: AugmentedActionCategoriesContext,
    sort: CategoriesListFilterModel
  ): void;
  [ActionTypes.SET_CATEGORIES_CATEGORY_CHANGE_STATUS](
    context: AugmentedActionCategoriesContext,
    model: ChangeCategoryStatusModel
  ): Promise<void>;
  [ActionTypes.SET_CATEGORIES_CATEGORY_DELETE](
    context: AugmentedActionCategoriesContext,
    model: DeleteCategoriesListRequestModel
  ): Promise<void>;
  [ActionTypes.SET_CATEGORIES_CATEGORY_CHANGE](
    context: AugmentedActionCategoriesContext,
    model: any
  ): Promise<void>;
  [ActionTypes.SET_CATEGORIES_CATEGORY_CREATE](
    context: AugmentedActionCategoriesContext,
    name: string
  ): Promise<void>;
  [ActionTypes.SET_CATEGORIES_SELECTED_CATEGORY](
    context: AugmentedActionCategoriesContext,
    id: number | null
  ): Promise<void>;
  [ActionTypes.SET_CATEGORIES_SELECTED_CATEGORY_CLEAR](
    context: AugmentedActionCategoriesContext | null
  ): void;
  [ActionTypes.SET_CATEGORIES_SELECTED_CATEGORY_WITH_CRITERIONS_LIST](
    context: AugmentedActionCategoriesContext,
    id: number | null
  ): Promise<void>;
}

export const actions: ActionTree<ICategoriesStateModel, IRootStateModel> &
  CategoriesActions = {
  async [ActionTypes.SET_CATEGORIES_LIST]({ commit, getters }) {
    const client: BaseClient = getClientInstance();

    commit(MutationTypes.SET_CATEGORIES_DATA_IS_LOADING, true);

    try {
      const response: CategoriesListResponseModel =
        await client.getCategoriesList({
          page: getters.getCategoriesListPage as number,
          perPage: getters.getCategoriesListPerPage as number,
          filter: getters.getCategoriesFilterValue,
          sort: getters.getCategoriesSortValue,
        });

      commit(MutationTypes.SET_CATEGORIES_LIST, response.items);
      commit(MutationTypes.SET_CATEGORIES_TOTAL, response.total);
    } catch (error) {
      console.error("getCategoriesList", error);
    } finally {
      commit(MutationTypes.SET_CATEGORIES_DATA_IS_LOADING, false);
    }
  },
  async [ActionTypes.SET_CATEGORIES_FULL_LIST]({ commit }) {
    const client: BaseClient = getClientInstance();

    commit(MutationTypes.SET_CATEGORIES_DATA_IS_LOADING, true);

    try {
      const response: CategoriesFullListResponseModel =
        await client.getCategoriesFullList();

      commit(MutationTypes.SET_CATEGORIES_FULL_LIST, response.items);
    } catch (error) {
      console.error("getCategoriesFullList", error);
    } finally {
      commit(MutationTypes.SET_CATEGORIES_DATA_IS_LOADING, false);
    }
  },
  async [ActionTypes.SET_CATEGORIES_WITH_CRITERIONS_FULL_LIST]({ commit }) {
    const client: BaseClient = getClientInstance();

    commit(MutationTypes.SET_CATEGORIES_DATA_IS_LOADING, true);

    try {
      const categoriesResponse: any = await client.getCategoriesFullList();

      commit(
        MutationTypes.SET_CATEGORIES_WITH_CRITERIONS_FULL,
        categoriesResponse.items
      );
    } catch (error) {
      console.error("getCategory", error);
    } finally {
      commit(MutationTypes.SET_CATEGORIES_DATA_IS_LOADING, false);
    }
  },
  [ActionTypes.SET_CATEGORIES_LIST_PAGE]({ commit }, page: number) {
    commit(MutationTypes.SET_CATEGORIES_LIST_PAGE, page);
  },
  [ActionTypes.SET_CATEGORIES_LIST_PER_PAGE]({ commit }, perPage: number) {
    commit(MutationTypes.SET_CATEGORIES_LIST_PER_PAGE, perPage);
  },
  [ActionTypes.SET_CATEGORIES_LIST_SORT](
    { commit },
    sort: CategoriesListSortModel
  ) {
    commit(MutationTypes.SET_CATEGORIES_SORT_VALUE, sort);
  },
  [ActionTypes.SET_CATEGORIES_LIST_FILTER](
    { commit },
    filter: CategoriesListFilterModel
  ) {
    commit(MutationTypes.SET_CATEGORIES_FILTER_VALUE, filter);
    commit(MutationTypes.SET_CATEGORIES_LIST_PAGE, 1);
  },
  async [ActionTypes.SET_CATEGORIES_CATEGORY_CHANGE_STATUS](
    { commit, dispatch, getters },
    model: ChangeCategoryStatusModel
  ) {
    const client: BaseClient = getClientInstance();

    commit(MutationTypes.SET_CATEGORIES_DATA_IS_LOADING, true);

    try {
      await client.changeCategoryStatus(model.id, {
        status: model.status == "ACTIVE" ? "INACTIVE" : "ACTIVE",
      });
    } catch (error) {
      window.error("Ошибка изменения статуса категории");
      console.error("changeCategoryStatus", error);
    } finally {
      dispatch(ActionTypes.SET_CATEGORIES_LIST);
      if (
        getters.getCategoriesSelectedCategory &&
        getters.getCategoriesSelectedCategory.id &&
        getters.getCategoriesSelectedCategory.id == model.id
      ) {
        dispatch(ActionTypes.SET_CATEGORIES_SELECTED_CATEGORY, model.id);
      }
    }
  },
  async [ActionTypes.SET_CATEGORIES_CATEGORY_DELETE](
    { commit, dispatch },
    model: DeleteCategoriesListRequestModel
  ) {
    const client: BaseClient = getClientInstance();

    commit(MutationTypes.SET_CATEGORIES_DATA_IS_LOADING, true);

    try {
      await client.deleteCategoriesList(model);
    } catch (error) {
      window.error("Ошибка удаления категории");
      console.error("deleteCategoriesList", error);
    } finally {
      dispatch(ActionTypes.SET_CATEGORIES_LIST);
    }
  },
  async [ActionTypes.SET_CATEGORIES_CATEGORY_CREATE]({ commit }, name: string) {
    const client: BaseClient = getClientInstance();

    commit(MutationTypes.SET_CATEGORIES_DATA_IS_LOADING, true);

    try {
      const category: CategoryModel = await client.createCategory({ name });

      commit(MutationTypes.SET_CATEGORIES_SELECTED_CATEGORY, category);
    } catch (error) {
      window.error("Ошибка создания категории");
      console.error("createCategory", error);
    } finally {
      commit(MutationTypes.SET_CATEGORIES_DATA_IS_LOADING, false);
    }
  },
  async [ActionTypes.SET_CATEGORIES_CATEGORY_CHANGE](
    { commit, dispatch, getters },
    model: any
  ) {
    const client: BaseClient = getClientInstance();

    commit(MutationTypes.SET_CATEGORIES_DATA_IS_LOADING, true);

    const id = model.id;

    try {
      await client.changeCategory(id, model);
    } catch (error) {
      console.error("changeCategoryName", error);
    } finally {
      dispatch(ActionTypes.SET_CATEGORIES_SELECTED_CATEGORY, id);
      dispatch(ActionTypes.SET_CATEGORIES_LIST);
    }
  },
  async [ActionTypes.SET_CATEGORIES_SELECTED_CATEGORY]({ commit }, id: number) {
    const client: BaseClient = getClientInstance();

    commit(MutationTypes.SET_CATEGORIES_DATA_IS_LOADING, true);

    try {
      const response: GetCategoryResponseModel = await client.getCategory(id);

      commit(MutationTypes.SET_CATEGORIES_SELECTED_CATEGORY, response);
    } catch (error) {
      console.error("getCategory", error);
    } finally {
      commit(MutationTypes.SET_CATEGORIES_DATA_IS_LOADING, false);
    }
  },
  [ActionTypes.SET_CATEGORIES_SELECTED_CATEGORY_CLEAR]({ commit, dispatch }) {
    commit(MutationTypes.SET_CATEGORIES_SELECTED_CATEGORY, null);
    dispatch(StoreActionTypes.SET_CRITERIONS_LIST_FROM_ARRAY, []);
  },
  async [ActionTypes.SET_CATEGORIES_SELECTED_CATEGORY_WITH_CRITERIONS_LIST](
    { commit, dispatch },
    id: number
  ) {
    const client: BaseClient = getClientInstance();

    commit(MutationTypes.SET_CATEGORIES_DATA_IS_LOADING, true);

    try {
      const response: any = await client.getCategory(id);

      const criterionList: CriterionModel[] = response.criterions;
      const category: CategoryModel = {
        id: response.id,
        name: response.name,
        status: response.status,
        createdAt: response.createdAt,
        updatedAt: response.updatedAt,
      };
      commit(MutationTypes.SET_CATEGORIES_SELECTED_CATEGORY, category);
      dispatch(StoreActionTypes.SET_CRITERIONS_LIST_FROM_ARRAY, criterionList);
    } catch (error) {
      console.error("getCategory", error);
    } finally {
      commit(MutationTypes.SET_CATEGORIES_DATA_IS_LOADING, false);
    }
  },
};
