export enum Reports2MutationTypes {
  SET_REPORTS2_DATA_IS_LOADING = "SET_REPORTS2_DATA_IS_LOADING",
  SET_REPORTS2_DETAILS_IS_LOADING = "SET_REPORTS2_DETAILS_IS_LOADING",
  SET_REPORTS2_LABELS = "SET_REPORTS2_LABELS",
  SET_REPORTS2_PROJECTS = "SET_REPORTS2_PROJECTS",
  SET_REPORTS2_SELECTED_PROJECTS = "SET_REPORTS2_SELECTED_PROJECTS",
  SET_REPORTS2_REPORTS = "SET_REPORTS2_REPORTS",
  SET_REPORTS2_SELECTED_REPORTS = "SET_REPORTS2_SELECTED_REPORTS",
  SET_REPORTS2_RAW_REPORTS_DATA = "SET_REPORTS2_RAW_REPORTS_DATA",
  SET_REPORTS2_RENDER_REPORTS_DATA = "SET_REPORTS2_RENDER_REPORTS_DATA",
  SET_REPORTS2_PAGE = "SET_REPORTS2_PAGE",
  SET_REPORTS2_DATE_FILTER = "SET_REPORTS2_DATE_FILTER",
  SET_REPORTS2_DATE_INTERVAL = "SET_REPORTS2_DATE_INTERVAL",
  SET_REPORTS2_DETAILS_RAW_DATA = "SET_REPORTS2_DETAILS_RAW_DATA",
  SET_REPORTS2_DETAILS_RENDER_DATA = "SET_REPORTS2_DETAILS_RENDER_DATA",
  SET_REPORTS2_TARGETS = "SET_REPORTS2_TARGETS",
  SET_REPORTS2_REPORTS_SNAP_ID = "SET_REPORTS2_REPORTS_SNAP_ID",
  SET_REPORTS2_FILTER_REPORT_ID = "SET_REPORTS2_FILTER_REPORT_ID",
  SET_REPORTS2_FILTER_REPORT_OPTIONS = "SET_REPORTS2_FILTER_REPORT_OPTIONS",
}
