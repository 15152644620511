import { EwaStateModel } from "./../../interfaces";

export const state: EwaStateModel = {
  list: [],
  currentInstance: null,
  currentQr: null,
  dialogQueue: [],
  dataIsLoading: false,
  dialogIsLoading: false,
  tariffs: [],
  user: null,
  balance: 0,
};
