export enum MutationTypes {
  SET_AUTHENTIFICATION_DATA_LOADING = "setAuthentificationDataLoading",
  HAS_AUTHENTIFICATION_ERROR = "hasAuthentificationError",
  SET_AUTHENTIFICATION_PROFILE = "setAuthentificationProfile",
  SET_AUTHENTIFICATION_HELPER_TEXT = "setAuthentificationHelperText",
  SET_AUTHENTIFICATION_AUTHENTICATED = "setAuthentificationAutenticated",
  SET_AUTHENTIFICATION_USER_LOCALE = "setAuthentificationUserLocale",
  SET_AUTHENTIFICATION_PASSWORD_HASH = "setAuthentificationPasswordHash",
  SET_AUTHENTIFICATION_TUTORIAL_COMPLETED_STATUS = "setAuthentificationTutorialCompletedStatus",
  SET_AUTHENTIFICATION_SHOW_TUTORIAL_VIDEO = "setAuthentificationShowTutorialVideo",
}
