import { ActionTree, ActionContext } from "vuex";
import { IPlayerStateModel, IRootStateModel } from "../../../interfaces";
import { PlayerMutations } from "./../mutations";
import { ActionTypes } from "./types";
import { MutationTypes } from "./../mutations/types";
import { PlayBackSpeed } from "./../../../../services/types";
import { defaultState } from "../state";

type AugmentedActionPlayerContext = {
  commit<K extends keyof PlayerMutations>(
    key: K,
    payload: Parameters<PlayerMutations[K]>[1]
  ): ReturnType<PlayerMutations[K]>;
} & Omit<ActionContext<IPlayerStateModel, IRootStateModel>, "commit">;

export interface PlayerActions {
  [ActionTypes.SET_PLAYER_URL](
    context: AugmentedActionPlayerContext,
    url: string
  ): void;
  [ActionTypes.SET_PLAYER_CURRENT_TIME](
    context: AugmentedActionPlayerContext,
    currentTime: number
  ): void;
  [ActionTypes.SET_PLAYER_DURATION](
    context: AugmentedActionPlayerContext,
    duration: number
  ): void;
  [ActionTypes.SET_PLAYER_PLAY](
    context: AugmentedActionPlayerContext,
    play: boolean
  ): void;
  [ActionTypes.SET_PLAYER_PLAY_BACK_SPEED](
    context: AugmentedActionPlayerContext,
    playBackSpeed: PlayBackSpeed
  ): void;
  [ActionTypes.SET_PLAYER_VOLUME](
    context: AugmentedActionPlayerContext,
    volume: number
  ): void;
  [ActionTypes.SET_PLAYER_NEW_CURRENT_TIME](
    context: AugmentedActionPlayerContext,
    newCurrentTime: number
  ): void;
  [ActionTypes.SET_PLAYER_CLEAR_PLAYER](
    context: AugmentedActionPlayerContext
  ): void;
}

export const actions: ActionTree<IPlayerStateModel, IRootStateModel> &
  PlayerActions = {
  [ActionTypes.SET_PLAYER_URL]({ commit }, url: string) {
    commit(MutationTypes.SET_PLAYER_URL, url);
  },
  [ActionTypes.SET_PLAYER_CURRENT_TIME]({ commit }, currentTime: number) {
    commit(MutationTypes.SET_PLAYER_CURRENT_TIME, currentTime);
  },
  [ActionTypes.SET_PLAYER_DURATION]({ commit }, duration: number) {
    commit(MutationTypes.SET_PLAYER_DURATION, duration);
  },
  [ActionTypes.SET_PLAYER_PLAY]({ commit, getters }, play: boolean) {
    const isPlayed: boolean = getters.getPlayerIsPlayed;
    if (play && !isPlayed) {
      commit(MutationTypes.SET_PLAYER_IS_PLAYED, true);
    }
    commit(MutationTypes.SET_PLAYER_PLAY, play);
  },
  [ActionTypes.SET_PLAYER_PLAY_BACK_SPEED](
    { commit },
    playBackSpeed: PlayBackSpeed
  ) {
    commit(MutationTypes.SET_PLAYER_PLAY_BACK_SPEED, playBackSpeed);
  },
  [ActionTypes.SET_PLAYER_VOLUME]({ commit }, volume: number) {
    commit(MutationTypes.SET_PLAYER_VOLUME, volume);
  },
  [ActionTypes.SET_PLAYER_NEW_CURRENT_TIME](
    { commit },
    newCurrentTime: number
  ) {
    commit(MutationTypes.SET_PLAYER_NEW_CURRENT_TIME, newCurrentTime);
  },
  [ActionTypes.SET_PLAYER_CLEAR_PLAYER]({ commit }) {
    commit(MutationTypes.SET_PLAYER_CLEAR_PLAYER, defaultState);
  },
};
