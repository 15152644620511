import { ActionTree, ActionContext } from "vuex";
import { IReportsStateModel, IRootStateModel } from "../../../interfaces";
import { ReportsMutations } from "./../mutations";
import { ActionTypes } from "./types";
import { MutationTypes } from "./../mutations/types";
import {
  BaseReportRenderModel,
  ReportsListDateFilterModel,
  GetReportsResponse,
  BaseEntityModel,
  ReportItem,
  ReportsPageType,
  DateIntervalsType,
  ReportCallsListFilterBaseModel,
  GetReportCallsListResponseModel,
  GetReportCallsListRequestModel,
  ReportCallsListSortModel,
} from "./../../../../services/types";
import { BaseClient } from "./../../../../services/client/BaseClient";
import { getClientInstance } from "./../../../..//services/client/clientProvider";
import * as helpers from "../helpers";
import { StoreActionTypes } from "@/store/types";
import * as utils from "@/services/utils";

type AugmentedActionReportsContext = {
  commit<K extends keyof ReportsMutations>(
    key: K,
    payload: Parameters<ReportsMutations[K]>[1]
  ): ReturnType<ReportsMutations[K]>;
} & Omit<ActionContext<IReportsStateModel, IRootStateModel>, "commit">;

export interface ReportsActions {
  [ActionTypes.SET_REPORTS_LIST](
    context: AugmentedActionReportsContext
  ): Promise<void>;
  [ActionTypes.SET_REPORTS_LIST_FILTER](
    context: AugmentedActionReportsContext,
    filter: ReportsListDateFilterModel
  ): Promise<void>;
  [ActionTypes.SET_REPORTS_HANDLE_LIST_ITEM_CLICK](
    context: AugmentedActionReportsContext,
    id: number
  ): void;
  [ActionTypes.SET_REPORTS_SELECTED_PROJECTS_LIST](
    context: AugmentedActionReportsContext,
    list: BaseEntityModel[]
  ): void;
  [ActionTypes.SET_REPORTS_RENDER_LIST](
    context: AugmentedActionReportsContext
  ): void;
  [ActionTypes.SET_REPORTS_PAGE](
    context: AugmentedActionReportsContext,
    page: ReportsPageType
  ): Promise<void>;
  [ActionTypes.SET_REPORTS_LIST_CLEAR](
    context: AugmentedActionReportsContext
  ): void;
  [ActionTypes.SET_REPORTS_SELECTED_MANAGER](
    context: AugmentedActionReportsContext,
    managerId: number
  ): Promise<void>;
  [ActionTypes.SET_REPORTS_SELECTED_CRITERION](
    context: AugmentedActionReportsContext,
    criterionId: number
  ): Promise<void>;
  [ActionTypes.SET_REPORTS_LIST_FILTER_SELECTED_MANAGER](
    context: AugmentedActionReportsContext,
    filter: ReportsListDateFilterModel
  ): Promise<void>;
  [ActionTypes.SET_REPORTS_LIST_FILTER_SELECTED_CRITERION](
    context: AugmentedActionReportsContext,
    filter: ReportsListDateFilterModel
  ): Promise<void>;
  [ActionTypes.SET_REPORTS_DATE_INTERVAL](
    context: AugmentedActionReportsContext,
    interval: DateIntervalsType
  ): void;
  [ActionTypes.SET_REPORTS_CALLS_LIST](
    context: AugmentedActionReportsContext,
    model: ReportCallsListFilterBaseModel
  ): Promise<void>;
  [ActionTypes.SET_REPORTS_CALLS_LIST_SORT](
    context: AugmentedActionReportsContext,
    model: ReportCallsListSortModel
  ): void;
}

export const actions: ActionTree<IReportsStateModel, IRootStateModel> &
  ReportsActions = {
  async [ActionTypes.SET_REPORTS_LIST]({ commit, getters, dispatch }) {
    const client: BaseClient = getClientInstance();

    commit(MutationTypes.SET_REPORTS_DATA_IS_LOADING, true);
    const page: ReportsPageType = getters.getReportsPage;

    try {
      const request: ReportsListDateFilterModel = getters.getReportsFilterValue;

      let response: GetReportsResponse;

      if (page == "managers") {
        response = await client.getManagersReports(request);
      } else {
        response = await client.getCriterionsReports(request);
      }

      commit(MutationTypes.SET_REPORTS_REPORTS_LIST, response.rating);
      commit(MutationTypes.SET_REPORTS_PROJECTS_LIST, response.projects);
      if (getters.getReportsSelectedProjectsList.length == 0) {
        commit(
          MutationTypes.SET_REPORTS_SELECTED_PROJECTS_LIST,
          response.projects
        );
      }
      commit(MutationTypes.SET_REPORTS_IS_DAY, response.isDay);
      commit(MutationTypes.SET_REPORTS_LABELS, response.labels);
      commit(MutationTypes.SET_REPORTS_RAW_LIST, response.items);

      dispatch(StoreActionTypes.SET_REPORTS_RENDER_LIST);
    } catch (error) {
      console.error("getReports", error);
    } finally {
      commit(MutationTypes.SET_REPORTS_DATA_IS_LOADING, false);
    }
  },
  async [ActionTypes.SET_REPORTS_LIST_FILTER](
    { commit, dispatch },
    filter: ReportsListDateFilterModel
  ) {
    commit(MutationTypes.SET_REPORTS_FILTER_VALUE, filter);

    await dispatch(StoreActionTypes.SET_REPORTS_LIST);
  },
  [ActionTypes.SET_REPORTS_HANDLE_LIST_ITEM_CLICK]({ commit, getters }, id) {
    const currentList: BaseReportRenderModel[] = [
      ...getters.getReportsRenderList,
    ];
    const currentSelectedList: BaseReportRenderModel[] =
      getters.getReportsSelectedRenderItemsList;

    commit(
      MutationTypes.SET_REPORTS_RENDER_LIST,
      helpers.changeReportsRenderList(currentList, currentSelectedList, id)
    );
  },
  [ActionTypes.SET_REPORTS_SELECTED_PROJECTS_LIST](
    { commit },
    list: BaseEntityModel[]
  ) {
    commit(MutationTypes.SET_REPORTS_SELECTED_PROJECTS_LIST, list);
  },
  [ActionTypes.SET_REPORTS_RENDER_LIST]({ commit, getters }) {
    const rawList: ReportItem[] = getters.getReportsRawList;
    const reportsList: BaseEntityModel[] = getters.getReportsReportsList;
    const selectedProjectsList: BaseEntityModel[] =
      getters.getReportsSelectedProjectsList;

    commit(
      MutationTypes.SET_REPORTS_RENDER_LIST,
      helpers.getReportsRenderList(rawList, reportsList, selectedProjectsList)
    );
  },
  async [ActionTypes.SET_REPORTS_PAGE](
    { commit, dispatch },
    page: ReportsPageType
  ) {
    commit(MutationTypes.SET_REPORTS_PAGE, page);
    await dispatch(StoreActionTypes.SET_REPORTS_LIST);
  },
  [ActionTypes.SET_REPORTS_LIST_CLEAR]({ commit }) {
    commit(MutationTypes.SET_REPORTS_REPORTS_LIST, []);
    commit(MutationTypes.SET_REPORTS_PROJECTS_LIST, []);

    commit(MutationTypes.SET_REPORTS_IS_DAY, false);
    commit(MutationTypes.SET_REPORTS_LABELS, []);
    commit(MutationTypes.SET_REPORTS_RAW_LIST, []);
    commit(MutationTypes.SET_REPORTS_RENDER_LIST, []);

    commit(MutationTypes.SET_REPORTS_SELECTED_MANAGER, null);
    commit(MutationTypes.SET_REPORTS_SELECTED_CRITERION, null);
    commit(MutationTypes.SET_REPORTS_CALLS_LIST, []);
    commit(MutationTypes.SET_REPORTS_CALLS_LIST_SORT_VALUE, {
      direction: "asc",
      sort: "optionValue",
    });
  },
  async [ActionTypes.SET_REPORTS_SELECTED_MANAGER](
    { commit, getters, dispatch },
    managerId: number
  ) {
    if (!managerId) {
      return console.error("SET_REPORTS_SELECTED_MANAGER: managerId is null");
    }

    const client: BaseClient = getClientInstance();

    commit(MutationTypes.SET_REPORTS_DATA_IS_LOADING, true);

    try {
      const manager: BaseEntityModel = await client.getManagerBase(managerId);

      commit(MutationTypes.SET_REPORTS_SELECTED_MANAGER, manager);

      const request: ReportsListDateFilterModel = getters.getReportsFilterValue;

      const response: GetReportsResponse = await client.getManagerReports(
        manager.id,
        request
      );

      commit(MutationTypes.SET_REPORTS_REPORTS_LIST, response.rating);
      commit(MutationTypes.SET_REPORTS_PROJECTS_LIST, response.projects);
      if (getters.getReportsSelectedProjectsList.length == 0) {
        commit(
          MutationTypes.SET_REPORTS_SELECTED_PROJECTS_LIST,
          response.projects
        );
      }
      commit(MutationTypes.SET_REPORTS_IS_DAY, response.isDay);
      commit(MutationTypes.SET_REPORTS_LABELS, response.labels);
      commit(MutationTypes.SET_REPORTS_RAW_LIST, response.items);

      dispatch(StoreActionTypes.SET_REPORTS_RENDER_LIST);
    } catch (error) {
      console.error("getManagerReports", error);
    } finally {
      commit(MutationTypes.SET_REPORTS_DATA_IS_LOADING, false);
    }
  },
  async [ActionTypes.SET_REPORTS_SELECTED_CRITERION](
    { commit, getters, dispatch },
    criterionId: number
  ) {
    const client: BaseClient = getClientInstance();

    commit(MutationTypes.SET_REPORTS_DATA_IS_LOADING, true);

    try {
      const criterion: BaseEntityModel = await client.getCriterionBase(
        criterionId
      );

      commit(MutationTypes.SET_REPORTS_SELECTED_CRITERION, criterion);

      const request: ReportsListDateFilterModel = getters.getReportsFilterValue;

      const response: GetReportsResponse = await client.getCriterionReports(
        criterion.id,
        request
      );

      commit(MutationTypes.SET_REPORTS_REPORTS_LIST, response.rating);
      commit(MutationTypes.SET_REPORTS_PROJECTS_LIST, response.projects);
      if (getters.getReportsSelectedProjectsList.length == 0) {
        commit(
          MutationTypes.SET_REPORTS_SELECTED_PROJECTS_LIST,
          response.projects
        );
      }
      commit(MutationTypes.SET_REPORTS_IS_DAY, response.isDay);
      commit(MutationTypes.SET_REPORTS_LABELS, response.labels);
      commit(MutationTypes.SET_REPORTS_RAW_LIST, response.items);

      dispatch(StoreActionTypes.SET_REPORTS_RENDER_LIST);
    } catch (error) {
      console.error("getCriterionReports", error);
    } finally {
      commit(MutationTypes.SET_REPORTS_DATA_IS_LOADING, false);
    }
  },
  async [ActionTypes.SET_REPORTS_LIST_FILTER_SELECTED_MANAGER](
    { commit, dispatch, getters },
    filter: ReportsListDateFilterModel
  ) {
    commit(MutationTypes.SET_REPORTS_FILTER_VALUE, filter);
    const managerId = getters.getReportsSelectedManager
      ? getters.getReportsSelectedManager.id
      : null;
    await dispatch(StoreActionTypes.SET_REPORTS_SELECTED_MANAGER, managerId);
  },
  async [ActionTypes.SET_REPORTS_LIST_FILTER_SELECTED_CRITERION](
    { commit, dispatch, getters },
    filter: ReportsListDateFilterModel
  ) {
    commit(MutationTypes.SET_REPORTS_FILTER_VALUE, filter);
    const criterionId = getters.getReportsSelectedCriterion.id;
    await dispatch(
      StoreActionTypes.SET_REPORTS_SELECTED_CRITERION,
      criterionId
    );
  },
  [ActionTypes.SET_REPORTS_DATE_INTERVAL](
    { commit },
    interval: DateIntervalsType
  ) {
    commit(MutationTypes.SET_REPORTS_DATE_INTERVAL, interval);
  },
  async [ActionTypes.SET_REPORTS_CALLS_LIST](
    { commit, getters },
    model: ReportCallsListFilterBaseModel
  ) {
    const client: BaseClient = getClientInstance();

    commit(MutationTypes.SET_REPORTS_DATA_IS_LOADING, true);

    try {
      const request: GetReportCallsListRequestModel = {
        filter: {
          criterionId: model.criterionId,
          managerId: model.managerId,
          projects: (
            getters.getReportsSelectedProjectsList as BaseEntityModel[]
          ).map((item) => item.id),
          from: (getters.getReportsFilterValue as ReportsListDateFilterModel)
            .from,
          to: (getters.getReportsFilterValue as ReportsListDateFilterModel).to,
        },
        sort: getters.getReportsCallsListSortValue,
      };

      const response: GetReportCallsListResponseModel =
        await client.getReportCallsList(request);

      commit(MutationTypes.SET_REPORTS_CALLS_LIST, response.items);
    } catch (error) {
      console.error("getReportCallsList", error);
    } finally {
      commit(MutationTypes.SET_REPORTS_DATA_IS_LOADING, false);
    }
  },
  [ActionTypes.SET_REPORTS_CALLS_LIST_SORT](
    { commit },
    model: ReportCallsListSortModel
  ) {
    commit(MutationTypes.SET_REPORTS_CALLS_LIST_SORT_VALUE, model);
  },
};
