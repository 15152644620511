import { IAuthentificationStateModel } from "./../../interfaces";

export const state: IAuthentificationStateModel = {
  dataIsLoading: false,
  profile: null,
  error: false,
  helperText: "",
  authenticated: false,
  passwordHash: "",
  showTutorialVideo: false,
};
