import { GetterTree } from "vuex";
import {
  ISelectedCallStateModel,
  IRootStateModel,
} from "./../../../interfaces";
import {
  CallModel,
  CallDirection,
  CallEstimationModel,
  CallCheckListModel,
  CallCriterionsGroupedByCategoryModel,
  EstimatedCheckListModel,
  CallCriterionEstimateModel,
  BaseEntityModel,
} from "@/services/types";

export type SelectedCallGetters = {
  isSelectedCallDataLoading(state: ISelectedCallStateModel): boolean;
  getSelectedCall(state: ISelectedCallStateModel): CallModel;
  getSelectedCallDirection(state: ISelectedCallStateModel): CallDirection;
  getSelectedCallEstimations(
    state: ISelectedCallStateModel
  ): CallEstimationModel[];
  getSelectedCallChecklists(
    state: ISelectedCallStateModel
  ): CallCheckListModel[];
  getSelectedCallCategories(
    state: ISelectedCallStateModel
  ): CallCriterionsGroupedByCategoryModel[];
  getSelectedCallEstimatedCheckList(
    state: ISelectedCallStateModel
  ): EstimatedCheckListModel;
  getSelectedCallCheckListId(state: ISelectedCallStateModel): number;
  getSelectedCallRated(state: ISelectedCallStateModel): boolean;
  getSelectedCallSelectedAuditorId(state: ISelectedCallStateModel): number;
  getSelectedCallCurrentEstimations(
    state: ISelectedCallStateModel
  ): CallCriterionEstimateModel[];
  getSelectedCallAllCriterionsChecked(state: ISelectedCallStateModel): boolean;
  getSelectedCallAvailableManagersList(
    state: ISelectedCallStateModel
  ): BaseEntityModel[];
};

export const getters: GetterTree<ISelectedCallStateModel, IRootStateModel> &
  SelectedCallGetters = {
  isSelectedCallDataLoading: (state: ISelectedCallStateModel) => {
    return state.dataIsLoading;
  },
  getSelectedCall: (state: ISelectedCallStateModel) => {
    return state.selectedCall;
  },
  getSelectedCallDirection: (state: ISelectedCallStateModel) => {
    return state.selectedCall ? state.selectedCall.direction : null;
  },
  getSelectedCallEstimations: (state: ISelectedCallStateModel) => {
    return state.selectedCall ? state.selectedCall.estimations : [];
  },
  getSelectedCallChecklists: (state: ISelectedCallStateModel) => {
    return state.selectedCall ? state.selectedCall.checklists : [];
  },
  getSelectedCallCategories: (state: ISelectedCallStateModel) => {
    return state.categories;
  },
  getSelectedCallEstimatedCheckList: (state: ISelectedCallStateModel) => {
    return state.estimatedCheckList;
  },
  getSelectedCallCheckListId: (state: ISelectedCallStateModel) => {
    return state.checklistId;
  },
  getSelectedCallRated: (state: ISelectedCallStateModel) => {
    return state.callRated;
  },
  getSelectedCallSelectedAuditorId: (state: ISelectedCallStateModel) => {
    return state.selectedAuditorId;
  },
  getSelectedCallCurrentEstimations: (state: ISelectedCallStateModel) => {
    return state.currentEstimation;
  },
  getSelectedCallAllCriterionsChecked: (state: ISelectedCallStateModel) => {
    return state.allCriterionsChecked;
  },
  getSelectedCallAvailableManagersList: (state: ISelectedCallStateModel) => {
    return state.availableManagersList;
  },
  getTopCollapseOpened: (state: ISelectedCallStateModel) => {
    return state.topCollapseOpened;
  },
};
