import { CardsStateModel } from "@/store/modules/cards/state";
import { MutationTree } from "vuex";
import { CardsMutationTypes } from "@/store/modules/cards/mutations/types";
import { ProjectWithChecklistsModel } from "@/services/types/project";
import { CardModel, CardsListFilterModel } from "@/services/types/cards";
import { NameIdModel } from "@/services/types/entities/base";

export type CardsMutations<S = CardsStateModel> = {
  [CardsMutationTypes.SET_CARDS_LIST_PROJECTS_LIST](
    state: S,
    payload: ProjectWithChecklistsModel[]
  ): void;
  [CardsMutationTypes.SET_CARDS_LIST_FILTER](
    state: S,
    payload: CardsListFilterModel
  );
  [CardsMutationTypes.SET_CARDS_LIST](state: S, payload: CardModel[]);
  [CardsMutationTypes.SET_CARDS_LIST_MANAGERS_LIST](
    state: S,
    payload: NameIdModel[]
  );
  [CardsMutationTypes.SET_CARDS_IS_LOADING](state: S, payload: boolean);
};

export const mutations: MutationTree<CardsStateModel> & CardsMutations = {
  [CardsMutationTypes.SET_CARDS_LIST_MANAGERS_LIST](
    state: CardsStateModel,
    payload: NameIdModel[]
  ) {
    state.managers = payload;
  },
  [CardsMutationTypes.SET_CARDS_LIST_PROJECTS_LIST](state, payload) {
    state.projects = payload;
  },
  [CardsMutationTypes.SET_CARDS_LIST_FILTER](state, payload) {
    state.filter = payload;
  },
  [CardsMutationTypes.SET_CARDS_LIST](state, payload) {
    state.cards = payload;
  },
  [CardsMutationTypes.SET_CARDS_IS_LOADING](state, payload) {
    state.isLoading = payload;
  },
};
