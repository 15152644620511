import { BaseEntityModel } from "@/services/types/base";

let cardsChecklists: BaseEntityModel[] = [];

export function setCardsChecklists(checklists: BaseEntityModel[]) {
  cardsChecklists = checklists;
}

export function getCardsChecklists() {
  return cardsChecklists;
}
