import { BaseApi } from "@/services/api/BaseApi";
import type { GetEstimationResponseDto } from "@/services/api/estimations/types";

export class EstimationsApi extends BaseApi {
  async getEstimation(estimationId: number) {
    return (await this.client.get(
      `/estimations/${estimationId}`
    )) as Promise<GetEstimationResponseDto>;
  }
}
