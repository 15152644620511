export enum MutationTypes {
  SET_PROJECTS_LIST = "setProjectsList",
  SET_PROJECTS_FULL_LIST = "setProjectsFullList",
  SET_PROJECTS_LIST_PAGE = "setProjectsListPage",
  SET_PROJECTS_TOTAL = "setProjectsTotal",
  SET_PROJECTS_LIST_PER_PAGE = "setProjectsListPerPage",
  SET_PROJECTS_DATA_IS_LOADING = "setProjectsDataIsLoading",
  SET_PROJECTS_SORT_VALUE = "setProjectsSortValue",
  SET_PROJECTS_FILTER_VALUE = "setProjectsFilterValue",
  SET_PROJECTS_SELECTED_PROJECT = "setProjectsSelectedProject",
  SET_PROJECTS_SELECTED_PROJECT_CHECKLIST = "setProjectsSelectedProjectCheckList",
  SET_PROJECTS_LIMITS = "setProjectsLimits",
}
