import { ActionTree, ActionContext } from "vuex";
import {
  IProjectIntegrationStateModel,
  IRootStateModel,
} from "../../../interfaces";
import { ProjectIntegrationMutations } from "./../mutations";
import { ActionTypes } from "./types";
import { MutationTypes } from "./../mutations/types";
import {
  CreateNewProjectRequestModel,
  CreateNewProjectResponseModel,
  BaseEntityModel,
  GetProjectsFunnelsListModelRequestModel,
  ProjectIntegrationModel,
  GetProjectsFunnelsListModelResponseModel,
  SetProjectsFunnelsListModelRequestModel,
  GetProjectIntegrationsListResponseModel,
  DeleteProjectIntegrationsRequestModel,
  GetProjectIntegrationDetailsResponseModel,
  ProjectIntegration,
  CallsListFilterModel,
  ProjectModel,
} from "./../../../../services/types";
import { BaseClient } from "./../../../../services/client/BaseClient";
import { getClientInstance } from "./../../../..//services/client/clientProvider";
import { StoreActionTypes } from "@/store/types";
import router from "@/router";
declare const window: any;

type AugmentedActionProjectIntegrationContext = {
  commit<K extends keyof ProjectIntegrationMutations>(
    key: K,
    payload: Parameters<ProjectIntegrationMutations[K]>[1]
  ): ReturnType<ProjectIntegrationMutations[K]>;
} & Omit<
  ActionContext<IProjectIntegrationStateModel, IRootStateModel>,
  "commit"
>;

export interface ProjectIntegrationActions {
  [ActionTypes.SET_PROJECT_INTEGRATION_PROJECT_CREATE](
    context: AugmentedActionProjectIntegrationContext,
    model: CreateNewProjectRequestModel
  ): Promise<void>;
  [ActionTypes.SET_PROJECT_INTEGRATION_INTEGRATION_ID](
    context: AugmentedActionProjectIntegrationContext,
    integrationId: number
  ): void;
  [ActionTypes.SET_PROJECT_INTEGRATION_INTEGRATION_DETAILS_CLEAR](
    context: AugmentedActionProjectIntegrationContext
  ): void;
  [ActionTypes.SET_PROJECT_INTEGRATION_INTEGRATIONS_GET](
    context: AugmentedActionProjectIntegrationContext
  ): Promise<void>;
  [ActionTypes.SET_PROJECT_INTEGRATION_INTEGRATION_CHANGE_STATUS](
    context: AugmentedActionProjectIntegrationContext
  ): Promise<void>;
  [ActionTypes.SET_PROJECT_INTEGRATION_INTEGRATIONS_DELETE](
    context: AugmentedActionProjectIntegrationContext,
    model: DeleteProjectIntegrationsRequestModel
  ): Promise<void>;
  [ActionTypes.SET_PROJECT_INTEGRATION_INTEGRATION_DETAILS_GET](
    context: AugmentedActionProjectIntegrationContext,
    id: number | null
  ): Promise<void>;
  [ActionTypes.SET_PROJECT_INTEGRATION_FUNNELS_LIST_SET](
    context: AugmentedActionProjectIntegrationContext,
    model: SetProjectsFunnelsListModelRequestModel
  ): Promise<void>;
  [ActionTypes.SET_PROJECT_INTEGRATION_FUNNELS_LIST_GET](
    context: AugmentedActionProjectIntegrationContext
  ): Promise<void>;
  [ActionTypes.SET_PROJECT_INTEGRATION_INTEGRATION_CLEAR](
    context: AugmentedActionProjectIntegrationContext | null
  ): void;
  [ActionTypes.SET_PROJECT_INTEGRATION_INTEGRATIONS_LIST_CLEAR](
    context: AugmentedActionProjectIntegrationContext
  ): void;
  [ActionTypes.SET_PROJECT_INTEGRATION_BITRIX_ADD](
    context: AugmentedActionProjectIntegrationContext,
    webhookURL: string
  ): void;
}

export const actions: ActionTree<
  IProjectIntegrationStateModel,
  IRootStateModel
> &
  ProjectIntegrationActions = {
  async [ActionTypes.SET_PROJECT_INTEGRATION_PROJECT_CREATE](
    { commit },
    model: CreateNewProjectRequestModel
  ) {
    const client: BaseClient = getClientInstance();

    commit(MutationTypes.SET_PROJECT_INTEGRATION_DATA_IS_LOADING, true);

    try {
      const response: CreateNewProjectResponseModel =
        await client.createProject(model);

      commit(MutationTypes.SET_PROJECT_INTEGRATION_INTEGRATION, {
        id: response.id,
        name: response.name,
      });
    } catch (error) {
      console.error("createProject", error);
    } finally {
      commit(MutationTypes.SET_PROJECT_INTEGRATION_DATA_IS_LOADING, false);
    }
  },
  [ActionTypes.SET_PROJECT_INTEGRATION_INTEGRATIONS_LIST_CLEAR]({ commit }) {
    commit(MutationTypes.SET_PROJECT_INTEGRATION_INTEGRATIONS_LIST, []);
  },
  [ActionTypes.SET_PROJECT_INTEGRATION_INTEGRATION_CLEAR]({ commit }) {
    commit(MutationTypes.SET_PROJECT_INTEGRATION_INTEGRATION, null);
  },
  [ActionTypes.SET_PROJECT_INTEGRATION_INTEGRATION_ID](
    { commit, getters },
    integrationId: number
  ) {
    const project: BaseEntityModel = getters.getProjectsSelectedProject;

    if (project) {
      commit(MutationTypes.SET_PROJECT_INTEGRATION_INTEGRATION, {
        id: project.id,
        name: project.name,
        integrationId,
      });
    }
  },
  async [ActionTypes.SET_PROJECT_INTEGRATION_FUNNELS_LIST_GET]({
    commit,
    getters,
    dispatch,
  }) {
    if (
      !(getters.getProjectIntegrationIntegration as ProjectIntegrationModel) &&
      !getters.getBitrixIntegration
    ) {
      return console.error(
        "SET_PROJECT_INTEGRATION_FUNNELS_LIST_GET: no integration info"
      );
    }

    const client: BaseClient = getClientInstance();

    commit(MutationTypes.SET_PROJECT_INTEGRATION_DATA_IS_LOADING, true);

    try {
      let projectWithIntegration: GetProjectsFunnelsListModelRequestModel;

      if (
        !(getters.getProjectIntegrationIntegration as ProjectIntegrationModel) ||
        !(getters.getProjectIntegrationIntegration as ProjectIntegrationModel)
          .integrationId
      ) {
        projectWithIntegration = {
          integrationId: +getters.getBitrixIntegration.integrationId,
          projectId: +getters.getBitrixIntegration.projectId,
        };
      } else {
        projectWithIntegration = {
          integrationId: +(
            getters.getProjectIntegrationIntegration as ProjectIntegrationModel
          ).integrationId,
          projectId: +(
            getters.getProjectIntegrationIntegration as ProjectIntegrationModel
          ).id,
        };
      }
      const response: GetProjectsFunnelsListModelResponseModel =
        await client.getProjectsFunnelsList(projectWithIntegration);

      if (!response) {
        window.error("Ошибка интеграции, попробуйте снова");
        router.push(
          `/projects/${getters.getBitrixIntegration.projectId}/add-integration`
        );
        return;
      }

      commit(MutationTypes.SET_PROJECT_INTEGRATION_FUNNELS_LIST, response);

      dispatch(ActionTypes.SET_PROJECT_INTEGRATION_INTEGRATION_ID, response.id);

      commit(
        MutationTypes.SET_PROJECT_INTEGRATION_SELECTED_PROJECT_INTEGRATION,
        {
          id: response.id,
          status: response.status,
          type: response.type,
          isConfigured: response.isConfigured,
          createdAt: null,
          updatedAt: null,
          configuration: null,
        }
      );
    } catch (error) {
      console.error("getProjectsFunnelsList", error);
    } finally {
      commit(MutationTypes.SET_PROJECT_INTEGRATION_DATA_IS_LOADING, false);
    }
  },
  async [ActionTypes.SET_PROJECT_INTEGRATION_BITRIX_ADD](
    { getters, commit, dispatch },
    webhookURL
  ) {
    const client: BaseClient = getClientInstance();
    const projectId = getters.getProjectIntegrationIntegration
      ? (getters.getProjectIntegrationIntegration as ProjectIntegrationModel).id
      : getters.getProjectsSelectedProject.id;
    if (!projectId) {
      return console.error("no project id provided");
    }

    const result = await client.bitrixIntegrationAdd(projectId, webhookURL);
    if (!result) {
      window.error("Ошибка интеграции, попробуйте снова");
      return;
    }

    /* const result = {
            projectId: 51,
            integrationId: 299,
            scope: ["crm","disk","telephony","user"]
        } */

    const trueUrl = process.env.VUE_APP_BASE_URL.slice(0, -4);
    commit(MutationTypes.SET_PROJECT_INTEGRATION_TYPE, "bitrix");
    commit(MutationTypes.SET_BITRIX_INTEGRATION, result);

    router.push("/projects/new/step-three");

    //window.location.href = `${trueUrl}/integration/bitrix24/redirect?state=${projectId}&webhookURL=${webhookURL}`;
  },
  async [ActionTypes.SET_PROJECT_INTEGRATION_FUNNELS_LIST_SET](
    { commit, dispatch, getters },
    model: SetProjectsFunnelsListModelRequestModel
  ) {
    const client: BaseClient = getClientInstance();

    commit(MutationTypes.SET_PROJECT_INTEGRATION_DATA_IS_LOADING, true);
    commit(MutationTypes.SET_PROJECT_INTEGRATION_CALLS_LIST_IS_LOADING, true);

    try {
      let integrationId;
      let projectId;

      if (
        !(getters.getProjectIntegrationIntegration as ProjectIntegrationModel) ||
        !(getters.getProjectIntegrationIntegration as ProjectIntegrationModel)
          .integrationId
      ) {
        (integrationId = getters.getBitrixIntegration.integrationId),
          (projectId = getters.getBitrixIntegration.projectId);
      } else {
        integrationId = (
          getters.getProjectIntegrationIntegration as ProjectIntegrationModel
        ).integrationId;
        projectId = (
          getters.getProjectIntegrationIntegration as ProjectIntegrationModel
        ).id;
      }

      const result = await client.setProjectsFunnelsList(
        projectId,
        integrationId,
        model
      );

      const callListFilterModel: CallsListFilterModel =
        getters.getCallsFilterValue;
      callListFilterModel.projects = [projectId];

      dispatch(StoreActionTypes.SET_CALLS_LIST_FILTER, callListFilterModel);
    } catch (error) {
      console.error("setProjectsFunnelsList", error);
    } finally {
      commit(MutationTypes.SET_PROJECT_INTEGRATION_DATA_IS_LOADING, false);
      commit(
        MutationTypes.SET_PROJECT_INTEGRATION_CALLS_LIST_IS_LOADING,
        false
      );
    }
  },
  async [ActionTypes.SET_PROJECT_INTEGRATION_INTEGRATIONS_GET]({
    commit,
    getters,
  }) {
    const client: BaseClient = getClientInstance();

    commit(MutationTypes.SET_PROJECT_INTEGRATION_DATA_IS_LOADING, true);

    const id = getters.getProjectsSelectedProject.id;

    try {
      const response: GetProjectIntegrationsListResponseModel =
        await client.getProjectIntegrations(id);

      commit(
        MutationTypes.SET_PROJECT_INTEGRATION_INTEGRATIONS_LIST,
        response.items
      );
    } catch (error) {
      console.error("getProjectIntegrations", error);
    } finally {
      commit(MutationTypes.SET_PROJECT_INTEGRATION_DATA_IS_LOADING, false);
    }
  },
  async [ActionTypes.SET_PROJECT_INTEGRATION_INTEGRATIONS_DELETE](
    { commit, getters },
    model: DeleteProjectIntegrationsRequestModel
  ) {
    const client: BaseClient = getClientInstance();

    commit(MutationTypes.SET_PROJECT_INTEGRATION_DATA_IS_LOADING, true);

    try {
      const project: ProjectModel = getters.getProjectsSelectedProject;

      await client.deleteProjectIntegrations(project.id, model);
    } catch (error) {
      console.error("deleteProjectIntegrations", error);
    } finally {
      commit(MutationTypes.SET_PROJECT_INTEGRATION_DATA_IS_LOADING, false);
    }
  },
  async [ActionTypes.SET_PROJECT_INTEGRATION_INTEGRATION_CHANGE_STATUS]({
    commit,
    getters,
  }) {
    const client: BaseClient = getClientInstance();

    commit(MutationTypes.SET_PROJECT_INTEGRATION_DATA_IS_LOADING, true);

    const integration: ProjectIntegration =
      getters.getProjectIntegrationSelectedProjectIntegration;

    const project: ProjectModel = getters.getProjectsSelectedProject;

    try {
      await client.changeProjectIntegrationStatus(project.id, integration.id, {
        status: integration.status == "ACTIVE" ? "INACTIVE" : "ACTIVE",
      });
    } catch (error) {
      console.error("changeProjectIntegrationStatus", error);
    } finally {
      commit(MutationTypes.SET_PROJECT_INTEGRATION_DATA_IS_LOADING, false);
    }
  },
  async [ActionTypes.SET_PROJECT_INTEGRATION_INTEGRATION_DETAILS_GET](
    { commit, dispatch },
    id: number
  ) {
    const client: BaseClient = getClientInstance();

    commit(MutationTypes.SET_PROJECT_INTEGRATION_DATA_IS_LOADING, true);

    try {
      const response: GetProjectIntegrationDetailsResponseModel =
        await client.getProjectIntegrationDetails(id);

      dispatch(ActionTypes.SET_PROJECT_INTEGRATION_INTEGRATION_ID, id);
      commit(
        MutationTypes.SET_PROJECT_INTEGRATION_SELECTED_PROJECT_INTEGRATION,
        response
      );
    } catch (error) {
      console.error("getProjectIntegrationDetails", error);
    } finally {
      commit(MutationTypes.SET_PROJECT_INTEGRATION_DATA_IS_LOADING, false);
    }
  },
  [ActionTypes.SET_PROJECT_INTEGRATION_INTEGRATION_DETAILS_CLEAR]({ commit }) {
    commit(
      MutationTypes.SET_PROJECT_INTEGRATION_SELECTED_PROJECT_INTEGRATION,
      null
    );
  },
};
