import { BaseClient } from "./BaseClient";
import { WebClient } from "./WebClient";

export const getClientInstance = (token?: string): BaseClient => {
  return WebClient.getInstance(token);
};

export const getClient = (token?: string): BaseClient => {
  return new WebClient(token);
};

export const createClientInstance = (token: string) => {
  WebClient.createInstance(token);
};
