import { MutationTree } from "vuex";
import { MutationTypes } from "./types";
import { EwaStateModel } from "./../../../interfaces";
import {
  CompanyEmployeeModel,
  CompanyEmployeesListFilterModel,
} from "@/services/types";
import {
  EwaInstance,
  EwaInstanceQr,
  EwaTariff,
  EwaUser,
} from "@/services/types/ewa";

export type EwaMutations<S = EwaStateModel> = {
  [MutationTypes.SET_INSTANCES_LIST](state: S, payload: EwaInstance[]): void;
  [MutationTypes.SET_DIALOG_QUEUE](state: S, payload: any): void;
  [MutationTypes.SET_DIALOG_CLOSE](state: S, payload: any): void;
  [MutationTypes.SET_DATA_IS_LOADING](state: S, payload: boolean): void;
  [MutationTypes.SET_DIALOG_IS_LOADING](state: S, payload: boolean): void;
  [MutationTypes.SET_CURRENT_INSTANCE](state: S, payload: EwaInstance): void;
  [MutationTypes.SET_CURRENT_QR](state: S, payload: EwaInstanceQr): void;
  [MutationTypes.SET_TARIFFS](state: S, payload: EwaTariff[]): void;
  [MutationTypes.SET_EWA_USER](state: S, payload: EwaUser): void;
  [MutationTypes.SET_BALANCE](state: S, payload: number): void;
};

export const mutations: MutationTree<EwaStateModel> & EwaMutations = {
  [MutationTypes.SET_INSTANCES_LIST](state, payload: EwaInstance[]) {
    state.list = payload;
  },
  [MutationTypes.SET_DIALOG_QUEUE](state, payload: any) {
    if (payload.force) {
      (state.dialogQueue as any).unshift(payload.dialog);
      return;
    }

    (state.dialogQueue as any).push(payload.dialog);
  },

  [MutationTypes.SET_DIALOG_CLOSE](state, { clear }) {
    if (clear) {
      state.dialogQueue = [];
      return;
    }

    state.dialogQueue.splice(0, 1);
  },
  [MutationTypes.SET_DATA_IS_LOADING](state, payload) {
    state.dataIsLoading = payload;
  },
  [MutationTypes.SET_DIALOG_IS_LOADING](state, payload) {
    state.dialogIsLoading = payload;
  },
  [MutationTypes.SET_CURRENT_INSTANCE](state, payload) {
    state.currentInstance = payload;
  },
  [MutationTypes.SET_CURRENT_QR](state, payload) {
    state.currentQr = payload;
  },
  [MutationTypes.SET_TARIFFS](state, payload) {
    state.tariffs = payload;
  },
  [MutationTypes.SET_EWA_USER](state, payload) {
    state.user = payload;
  },
  [MutationTypes.SET_BALANCE](state, payload) {
    state.balance = payload;
  },
};
