import { ICriterionsStateModel } from "./../../interfaces";

export const state: ICriterionsStateModel = {
  page: 1,
  perPage: 20,
  total: 0,
  list: [],
  dataIsLoading: false,
  selectedCriterion: null,
  filter: {
    status: null,
    search: "",
    typeCode: null,
  },
  sort: {
    direction: "asc",
    sort: "name",
  },
};
