export enum MutationTypes {
  SET_CRITERIONS_LIST = "setCriterionsList",
  SET_CRITERIONS_LIST_TOTAL = "setCriterionsListTotal",
  SET_CRITERIONS_LIST_PAGE = "setCriterionsListPage",
  SET_CRITERIONS_LIST_PER_PAGE = "setCriterionsListPerPage",
  SET_CRITERIONS_DATA_IS_LOADING = "setCriterionsDataIsLoading",
  SET_CRITERIONS_FILTER_VALUE = "setCriterionsFilterValue",
  SET_CRITERIONS_SELECTED_CRITERION = "setCriterionsSelectedCriterion",
  SET_CRITERIONS_SORT_VALUE = "setCriterionsSortValue",
}
