import { MutationTree } from "vuex";
import { MutationTypes } from "./types";
import { ICallsStateModel } from "./../../../interfaces";
import {
  BaseCallModel,
  CallsListSortModel,
  CallsListFilterModel,
  BaseEntityModel,
} from "@/services/types";

export type CallsMutations<S = ICallsStateModel> = {
  [MutationTypes.SET_CALLS_LIST](state: S, payload: BaseCallModel[]): void;
  [MutationTypes.SET_CALLS_LIST_PAGE](state: S, payload: number): void;
  [MutationTypes.SET_CALLS_LIST_PER_PAGE](state: S, payload: number): void;
  [MutationTypes.SET_CALLS_TOTAL](state: S, payload: number): void;
  [MutationTypes.SET_CALLS_TOTAL_DURATION](state: S, payload: number): void;
  [MutationTypes.SET_CALLS_DATA_IS_LOADING](state: S, payload: boolean): void;
  [MutationTypes.SET_CALLS_SORT_VALUE](
    state: S,
    payload: CallsListSortModel
  ): void;
  [MutationTypes.SET_CALLS_FILTER_VALUE](
    state: S,
    payload: CallsListFilterModel
  ): void;
  [MutationTypes.SET_CALLS_SELECTED_GRID_COLUMNS](
    state: S,
    payload: string[]
  ): void;
  [MutationTypes.SET_CALLS_MANAGERS_LIST](
    state: S,
    payload: BaseEntityModel[]
  ): void;
  [MutationTypes.SET_CALLS_PROJECTS_LIST](
    state: S,
    payload: BaseEntityModel[]
  ): void;
  [MutationTypes.SET_CALLS_LINKED_PHONES_LIST](
    state: S,
    payload: string[]
  ): void;
};

export const mutations: MutationTree<ICallsStateModel> & CallsMutations = {
  [MutationTypes.SET_CALLS_LIST](state, payload: BaseCallModel[]) {
    state.list = payload;
  },
  [MutationTypes.SET_CALLS_LIST_PAGE](state, payload: number) {
    state.page = payload;
  },
  [MutationTypes.SET_CALLS_LIST_PER_PAGE](state, payload: number) {
    state.perPage = payload;
  },
  [MutationTypes.SET_CALLS_TOTAL](state, payload: number) {
    state.total = payload;
  },
  [MutationTypes.SET_CALLS_TOTAL_DURATION](state, payload: number) {
    state.totalDuration = payload;
  },
  [MutationTypes.SET_CALLS_DATA_IS_LOADING](state, payload: boolean) {
    state.dataIsLoading = payload;
  },
  [MutationTypes.SET_CALLS_SORT_VALUE](state, payload: CallsListSortModel) {
    state.sort = payload;
  },
  [MutationTypes.SET_CALLS_FILTER_VALUE](state, payload: CallsListFilterModel) {
    state.filter = payload;
  },
  [MutationTypes.SET_CALLS_SELECTED_GRID_COLUMNS](state, payload: string[]) {
    state.selectedGridColumns = payload;
  },
  [MutationTypes.SET_CALLS_MANAGERS_LIST](state, payload: BaseEntityModel[]) {
    state.managers = payload as any;
  },
  [MutationTypes.SET_CALLS_PROJECTS_LIST](state, payload: BaseEntityModel[]) {
    state.projects = payload;
  },
  [MutationTypes.SET_CALLS_LINKED_PHONES_LIST](state, payload: string[]) {
    state.linkedCalls = payload;
  },
};
