import { ActionTree, ActionContext } from "vuex";
import { ICriterionsStateModel, IRootStateModel } from "../../../interfaces";
import { CriterionsMutations } from "./../mutations";
import { ActionTypes } from "./types";
import { MutationTypes } from "./../mutations/types";
import {
  DeleteCriterionsListRequestModel,
  ChangeCriterionStatusModel,
  EditCriterionRequestModel,
  CreateCriterionRequestModel,
  CriterionModel,
  CriterionsListFilterModel,
  CriterionsListByCategoryIdResponseModel,
  GetCriterionResponseModel,
  CriterionsListSortModel,
  CriterionsListResponseModel,
  CriterionsListByCheckListIdResponseModel,
  CreateCriterionFullRequestModel,
} from "./../../../../services/types";
import { BaseClient } from "./../../../../services/client/BaseClient";
import { getClientInstance } from "./../../../..//services/client/clientProvider";
import { StoreActionTypes } from "@/store/types";
import { getters } from "../../authentification/getters";

type AugmentedActionCriterionsContext = {
  commit<K extends keyof CriterionsMutations>(
    key: K,
    payload: Parameters<CriterionsMutations[K]>[1]
  ): ReturnType<CriterionsMutations[K]>;
} & Omit<ActionContext<ICriterionsStateModel, IRootStateModel>, "commit">;

export interface CriterionsActions {
  [ActionTypes.SET_CRITERIONS_LIST](
    context: AugmentedActionCriterionsContext
  ): Promise<void>;
  [ActionTypes.SET_CRITERIONS_LIST_PAGE](
    context: AugmentedActionCriterionsContext,
    page: number
  ): void;
  [ActionTypes.SET_CRITERIONS_LIST_PER_PAGE](
    context: AugmentedActionCriterionsContext,
    perPage: number
  ): void;
  [ActionTypes.SET_CRITERIONS_LIST_BY_CATEGORY_ID](
    context: AugmentedActionCriterionsContext
  ): Promise<void>;
  [ActionTypes.SET_CRITERIONS_LIST_BY_CHECKLIST_ID](
    context: AugmentedActionCriterionsContext
  ): Promise<void>;
  [ActionTypes.SET_CRITERIONS_LIST_FROM_ARRAY](
    context: AugmentedActionCriterionsContext,
    list: CriterionModel[]
  ): void;
  [ActionTypes.SET_CRITERIONS_LIST_FILTER](
    context: AugmentedActionCriterionsContext,
    sort: CriterionsListFilterModel
  ): void;
  [ActionTypes.SET_CRITERIONS_CRITERION_CHANGE_STATUS](
    context: AugmentedActionCriterionsContext,
    model: ChangeCriterionStatusModel
  ): Promise<void>;
  [ActionTypes.SET_CRITERIONS_CRITERION_DELETE](
    context: AugmentedActionCriterionsContext,
    model: DeleteCriterionsListRequestModel
  ): Promise<void>;
  [ActionTypes.SET_CRITERIONS_CRITERION_EDIT](
    context: AugmentedActionCriterionsContext,
    model: EditCriterionRequestModel
  ): Promise<void>;
  [ActionTypes.SET_CRITERIONS_CRITERION_CREATE](
    context: AugmentedActionCriterionsContext,
    model: CreateCriterionFullRequestModel
  ): Promise<void>;
  [ActionTypes.SET_CRITERIONS_SELECTED_CRITERION](
    context: AugmentedActionCriterionsContext,
    id: number
  ): Promise<void>;
  [ActionTypes.SET_CRITERIONS_LIST_SORT](
    context: AugmentedActionCriterionsContext,
    sort: CriterionsListSortModel
  ): void;
  [ActionTypes.SET_CRITERIONS_SELECTED_CRITERION_CLEAR](
    context: AugmentedActionCriterionsContext
  ): void;
}

export const actions: ActionTree<ICriterionsStateModel, IRootStateModel> &
  CriterionsActions = {
  async [ActionTypes.SET_CRITERIONS_LIST]({ commit, getters }) {
    const client: BaseClient = getClientInstance();

    commit(MutationTypes.SET_CRITERIONS_DATA_IS_LOADING, true);

    try {
      const response: any = await client.getCriterionsList({
        page: getters.getCriterionsListPage as number,
        perPage: getters.getCriterionsListPerPage as number,
        filter: getters.getCriterionsFilterValue,
        sort: getters.getCriterionsSortValue,
      });

      commit(MutationTypes.SET_CRITERIONS_LIST, response.items);
      commit(MutationTypes.SET_CRITERIONS_LIST_TOTAL, response.total);
    } catch (error) {
      console.error("getCriterionsList", error);
    } finally {
      commit(MutationTypes.SET_CRITERIONS_DATA_IS_LOADING, false);
    }
  },
  async [ActionTypes.SET_CRITERIONS_LIST_BY_CATEGORY_ID]({ commit, getters }) {
    const client: BaseClient = getClientInstance();

    commit(MutationTypes.SET_CRITERIONS_DATA_IS_LOADING, true);

    try {
      const response: any = await client.getCriterionsListByCategoryId(
        getters.getCategoriesSelectedCategory.id
      );

      commit(MutationTypes.SET_CRITERIONS_LIST, response.criterions);
    } catch (error) {
      console.error("getCriterionsListByCategoryId", error);
    } finally {
      commit(MutationTypes.SET_CRITERIONS_DATA_IS_LOADING, false);
    }
  },
  async [ActionTypes.SET_CRITERIONS_LIST_BY_CHECKLIST_ID]({ commit, getters }) {
    const client: BaseClient = getClientInstance();

    commit(MutationTypes.SET_CRITERIONS_DATA_IS_LOADING, true);

    try {
      const response: any = await client.getCriterionsListByCheckListId(
        getters.getCheckListsSelectedCheckList.id
      );

      commit(MutationTypes.SET_CRITERIONS_LIST, response.criterions);
    } catch (error) {
      console.error("getCriterionsListByCheckListId", error);
    } finally {
      commit(MutationTypes.SET_CRITERIONS_DATA_IS_LOADING, false);
    }
  },
  [ActionTypes.SET_CRITERIONS_LIST_PAGE]({ commit }, page: number) {
    commit(MutationTypes.SET_CRITERIONS_LIST_PAGE, page);
  },
  [ActionTypes.SET_CRITERIONS_LIST_PER_PAGE]({ commit }, perPage: number) {
    commit(MutationTypes.SET_CRITERIONS_LIST_PER_PAGE, perPage);
  },
  [ActionTypes.SET_CRITERIONS_LIST_FROM_ARRAY]({ commit }, list: any[]) {
    commit(MutationTypes.SET_CRITERIONS_LIST, list);
  },
  [ActionTypes.SET_CRITERIONS_LIST_FILTER](
    { commit },
    filter: CriterionsListFilterModel
  ) {
    commit(MutationTypes.SET_CRITERIONS_FILTER_VALUE, filter);
    commit(MutationTypes.SET_CRITERIONS_LIST_PAGE, 1);
  },
  async [ActionTypes.SET_CRITERIONS_CRITERION_CHANGE_STATUS](
    { commit },
    model: ChangeCriterionStatusModel
  ) {
    const client: BaseClient = getClientInstance();

    commit(MutationTypes.SET_CRITERIONS_DATA_IS_LOADING, true);

    try {
      await client.changeCriterionStatus(model.id, {
        status: model.status == "ACTIVE" ? "INACTIVE" : "ACTIVE",
      });
    } catch (error) {
      console.error("changeCriterionStatus", error);
    } finally {
      commit(MutationTypes.SET_CRITERIONS_DATA_IS_LOADING, false);
    }
  },
  async [ActionTypes.SET_CRITERIONS_CRITERION_DELETE](
    { commit },
    model: DeleteCriterionsListRequestModel
  ) {
    const client: BaseClient = getClientInstance();

    commit(MutationTypes.SET_CRITERIONS_DATA_IS_LOADING, true);

    try {
      await client.deleteCriterionsList(model);
    } catch (error) {
      console.error("deleteCriterionsList", error);
    } finally {
      commit(MutationTypes.SET_CRITERIONS_DATA_IS_LOADING, false);
    }
  },
  async [ActionTypes.SET_CRITERIONS_CRITERION_CREATE](
    { commit, dispatch },
    model: CreateCriterionFullRequestModel
  ) {
    const client: BaseClient = getClientInstance();

    commit(MutationTypes.SET_CRITERIONS_DATA_IS_LOADING, true);

    try {
      const response = await client.createCriterion(model.criterion);
      if (response && model.list.length > 0) {
        const ids = model.list
          .map((v) => v.criterions)
          .flat()
          .map((t) => t.id);

        ids.push(response.id);

        dispatch(StoreActionTypes.CHECKLISTS_EDIT_CHECKLIST_CRITERIONS_LIST, {
          ids,
        });
      }
    } catch (error) {
      console.error("createCriterion", error);
    } finally {
      commit(MutationTypes.SET_CRITERIONS_DATA_IS_LOADING, false);
    }
  },
  async [ActionTypes.SET_CRITERIONS_CRITERION_EDIT](
    { commit, getters },
    model: EditCriterionRequestModel
  ) {
    const client: BaseClient = getClientInstance();

    commit(MutationTypes.SET_CRITERIONS_DATA_IS_LOADING, true);

    const id = getters.getCriterionsSelectedCriterion.id;

    const checkListId = getters.getCheckListsSelectedCheckList.id;

    model.notes.map((v, index) => {
      v.position = {
        ...v.position,
        [checkListId]: index,
      };
    });

    try {
      await client.editCriterion(id, model);
    } catch (error) {
      console.error("editCriterion", error);
    } finally {
      commit(MutationTypes.SET_CRITERIONS_DATA_IS_LOADING, false);
    }
  },
  async [ActionTypes.SET_CRITERIONS_SELECTED_CRITERION](
    { commit },
    id: number
  ) {
    const client: BaseClient = getClientInstance();

    commit(MutationTypes.SET_CRITERIONS_DATA_IS_LOADING, true);

    try {
      const response: any = await client.getCriterion(id);

      commit(MutationTypes.SET_CRITERIONS_SELECTED_CRITERION, response);
    } catch (error) {
      console.error("getCriterion", error);
    } finally {
      commit(MutationTypes.SET_CRITERIONS_DATA_IS_LOADING, false);
    }
  },
  [ActionTypes.SET_CRITERIONS_LIST_SORT](
    { commit },
    sort: CriterionsListSortModel
  ) {
    commit(MutationTypes.SET_CRITERIONS_SORT_VALUE, sort);
  },
  [ActionTypes.SET_CRITERIONS_SELECTED_CRITERION_CLEAR]({ commit }) {
    commit(MutationTypes.SET_CRITERIONS_SELECTED_CRITERION, null);
  },
};
