import { GetterTree } from "vuex";
import { ICategoriesStateModel, IRootStateModel } from "./../../../interfaces";
import {
  CategoryModel,
  CategoriesListSortModel,
  CategoriesListFilterModel,
  BaseEntityModel,
} from "@/services/types";

export type CategoriesGetters = {
  getCategoriesList(state: ICategoriesStateModel): CategoryModel[];
  getCategoriesFullList(state: ICategoriesStateModel): BaseEntityModel[];
  getCategoriesListPage(state: ICategoriesStateModel): number;
  getCategoriesListPerPage(state: ICategoriesStateModel): number;
  getCategoriesTotal(state: ICategoriesStateModel): number;
  isCategoriesDataLoading(state: ICategoriesStateModel): boolean;
  getCategoriesSortValue(state: ICategoriesStateModel): CategoriesListSortModel;
  getCategoriesFilterValue(
    state: ICategoriesStateModel
  ): CategoriesListFilterModel;
  getCategoriesSelectedCategory(state: ICategoriesStateModel): CategoryModel;
};

export const getters: GetterTree<ICategoriesStateModel, IRootStateModel> &
  CategoriesGetters = {
  getCategoriesList: (state: ICategoriesStateModel) => {
    return state.list;
  },
  getCategoriesFullList: (state: ICategoriesStateModel) => {
    return state.fullList;
  },
  getCategoriesWithCriterionsFullList: (state: ICategoriesStateModel) => {
    return state.withCriterionsFullList;
  },
  getCategoriesListPage: (state: ICategoriesStateModel) => {
    return state.page;
  },
  getCategoriesListPerPage: (state: ICategoriesStateModel) => {
    return state.perPage;
  },
  getCategoriesTotal: (state: ICategoriesStateModel) => {
    return state.total;
  },
  isCategoriesDataLoading: (state: ICategoriesStateModel) => {
    return state.dataIsLoading;
  },
  getCategoriesFilterValue: (state: ICategoriesStateModel) => {
    return state.filter;
  },
  getCategoriesSortValue: (state: ICategoriesStateModel) => {
    return state.sort;
  },
  getCategoriesSelectedCategory: (state: ICategoriesStateModel) => {
    return state.selectedCategory;
  },
};
