declare const window: any;
import { ActionTree, ActionContext } from "vuex";
import { ICheckListsStateModel, IRootStateModel } from "../../../interfaces";
import { CheckListMutations } from "./../mutations";
import { ActionTypes } from "./types";
import { MutationTypes } from "./../mutations/types";
import {
  CheckListsListResponseModel,
  DeleteCheckListRequestModel,
  GetCheckListResponseModel,
  CheckListsListFilterModel,
  CheckListsListSortModel,
  CheckListModel,
  GetCheckListWithCriterionsResponseModel,
  CriterionModel,
  CategoriesWithCriterionsListByCheckListIdResponseModel,
  EditRecordIdListRequestModel,
  CheckListWithCategoriesSimpleModelListResponse,
  CategoryModel,
  GetCategoryWithCriterionsResponseModel,
  GetCategoryResponseModel,
  CategoriesFullListResponseModel,
  CategoryRenderModel,
} from "./../../../../services/types";
import { BaseClient } from "./../../../../services/client/BaseClient";
import { getClientInstance } from "./../../../..//services/client/clientProvider";
import { StoreActionTypes } from "./../../../types";
import { categoriesList, criterionsList } from "@/services/client/data";

type AugmentedActionCheckListsContext = {
  commit<K extends keyof CheckListMutations>(
    key: K,
    payload: Parameters<CheckListMutations[K]>[1]
  ): ReturnType<CheckListMutations[K]>;
} & Omit<ActionContext<ICheckListsStateModel, IRootStateModel>, "commit">;

export interface CheckListsActions {
  [ActionTypes.CHECKLISTS_LIST](
    context: AugmentedActionCheckListsContext
  ): Promise<void>;
  [ActionTypes.CHECKLISTS_FULL_LIST_GET](
    context: AugmentedActionCheckListsContext,
    id: number | null
  ): Promise<void>;
  [ActionTypes.CHECKLISTS_FULL_LIST_SAVE](
    context: AugmentedActionCheckListsContext,
    model: any
  );
  [ActionTypes.CHECKLISTS_LIST_PAGE](
    context: AugmentedActionCheckListsContext,
    page: number
  ): void;
  [ActionTypes.CHECKLISTS_LIST_PER_PAGE](
    context: AugmentedActionCheckListsContext,
    perPage: number
  ): void;
  [ActionTypes.CHECKLISTS_LIST_SORT](
    context: AugmentedActionCheckListsContext,
    sort: CheckListsListSortModel
  ): void;
  [ActionTypes.CHECKLISTS_LIST_FILTER](
    context: AugmentedActionCheckListsContext,
    sort: CheckListsListFilterModel
  ): void;
  [ActionTypes.CHECKLISTS_CHECKLIST_DELETE](
    context: AugmentedActionCheckListsContext,
    model: DeleteCheckListRequestModel
  ): Promise<void>;
  [ActionTypes.CHECKLISTS_CHECKLIST_CHANGE_NAME](
    context: AugmentedActionCheckListsContext,
    name: string
  ): Promise<void>;
  [ActionTypes.CHECKLISTS_CHECKLIST_CREATE](
    context: AugmentedActionCheckListsContext,
    name: string
  ): Promise<void>;
  [ActionTypes.CHECKLISTS_SELECTED_CHECKLIST](
    context: AugmentedActionCheckListsContext,
    id: number | null
  ): Promise<void>;
  [ActionTypes.CHECKLISTS_SELECTED_CHECKLIST_CLEAR](
    context: AugmentedActionCheckListsContext
  ): void;
  [ActionTypes.CHECKLISTS_FULL_LIST](
    context: AugmentedActionCheckListsContext,
    id: number | null
  ): Promise<void>;
  [ActionTypes.CHECKLISTS_CATEGORIES_LIST_BY_SELECTED_CHECKLIST](
    context: AugmentedActionCheckListsContext,
    id: number | null
  ): Promise<void>;
  [ActionTypes.CHECKLISTS_CATEGORIES_LIST_BY_SELECTED_CHECKLIST_CLEAR](
    context: AugmentedActionCheckListsContext
  ): void;
  [ActionTypes.CHECKLISTS_EDIT_CHECKLIST_CRITERIONS_LIST](
    context: AugmentedActionCheckListsContext,
    model: EditRecordIdListRequestModel
  ): Promise<void>;
  [ActionTypes.CHECKLISTS_LIST_SIMPLE](
    context: AugmentedActionCheckListsContext
  ): Promise<void>;
}

export const actions: ActionTree<ICheckListsStateModel, IRootStateModel> &
  CheckListsActions = {
  async [ActionTypes.CHECKLISTS_LIST]({ commit, getters }) {
    const client: BaseClient = getClientInstance();

    commit(MutationTypes.SET_CHECKLISTS_DATA_IS_LOADING, true);

    try {
      const response: CheckListsListResponseModel =
        await client.getCheckListsList({
          page: getters.getCheckListsListPage as number,
          perPage: getters.getCheckListsListPerPage as number,
          filter: getters.getCheckListsFilterValue,
          sort: getters.getCheckListsSortValue,
        });

      commit(MutationTypes.SET_CHECKLISTS_LIST, response.items);
      commit(MutationTypes.SET_CHECKLISTS_TOTAL, response.total);
    } catch (error) {
      window.error("Ошибка получения списка чеклистов");
      console.error("getCheckListsList", error);
    } finally {
      commit(MutationTypes.SET_CHECKLISTS_DATA_IS_LOADING, false);
    }
  },
  [ActionTypes.CHECKLISTS_LIST_PAGE]({ commit }, page: number) {
    commit(MutationTypes.SET_CHECKLISTS_LIST_PAGE, page);
  },
  [ActionTypes.CHECKLISTS_LIST_PER_PAGE]({ commit }, perPage: number) {
    commit(MutationTypes.SET_CHECKLISTS_LIST_PER_PAGE, perPage);
  },
  [ActionTypes.CHECKLISTS_LIST_SORT](
    { commit },
    sort: CheckListsListSortModel
  ) {
    commit(MutationTypes.SET_CHECKLISTS_SORT_VALUE, sort);
  },
  [ActionTypes.CHECKLISTS_LIST_FILTER](
    { commit },
    filter: CheckListsListFilterModel
  ) {
    commit(MutationTypes.SET_CHECKLISTS_FILTER_VALUE, filter);
    commit(MutationTypes.SET_CHECKLISTS_LIST_PAGE, 1);
  },
  async [ActionTypes.CHECKLISTS_CHECKLIST_DELETE](
    { commit, dispatch },
    model: DeleteCheckListRequestModel
  ) {
    const client: BaseClient = getClientInstance();

    commit(MutationTypes.SET_CHECKLISTS_DATA_IS_LOADING, true);

    try {
      const response = await client.deleteCheckListsList(model);

      if (!response) {
        window.error(
          "Сначала открепите чеклист от всех проектов, затем повторите попытку",
          "Ошибка удаления чеклиста"
        );
        return;
      }
    } catch (error) {
      window.error("Ошибка удаления чеклиста");
      console.error("deleteCheckListsList", error);
    } finally {
      dispatch(ActionTypes.CHECKLISTS_LIST);
    }
  },
  async [ActionTypes.CHECKLISTS_CHECKLIST_CREATE]({ commit }, name: string) {
    const client: BaseClient = getClientInstance();

    commit(MutationTypes.SET_CHECKLISTS_DATA_IS_LOADING, true);

    try {
      const checkList: CheckListModel = await client.createCheckList({
        name,
        ids: [],
      });
      commit(MutationTypes.SET_CHECKLISTS_SELECTED_CHECKLIST, checkList);
    } catch (error) {
      window.error("Ошибка создания чеклиста");
      console.error("createCheckList", error);
    } finally {
      commit(MutationTypes.SET_CHECKLISTS_DATA_IS_LOADING, false);
    }
  },
  async [ActionTypes.CHECKLISTS_CHECKLIST_CHANGE_NAME](
    { commit, dispatch, getters },
    name: string
  ) {
    const client: BaseClient = getClientInstance();

    commit(MutationTypes.SET_CHECKLISTS_DATA_IS_LOADING, true);

    const id = getters.getCheckListsSelectedCheckList.id;

    try {
      await client.changeCheckListName(id, { name });
    } catch (error) {
      window.error("Ошибка изменения названия чеклиста");
      console.error("changeCheckListName", error);
    } finally {
      dispatch(ActionTypes.CHECKLISTS_SELECTED_CHECKLIST, id);
      dispatch(ActionTypes.CHECKLISTS_LIST);
    }
  },
  async [ActionTypes.CHECKLISTS_SELECTED_CHECKLIST]({ commit }, id: number) {
    const client: BaseClient = getClientInstance();

    commit(MutationTypes.SET_CHECKLISTS_DATA_IS_LOADING, true);

    try {
      const response: GetCheckListResponseModel =
        await client.getCheckListWithCriterions(id);

      commit(MutationTypes.SET_CHECKLISTS_SELECTED_CHECKLIST, response);
    } catch (error) {
      console.error("getCheckList", error);
    } finally {
      commit(MutationTypes.SET_CHECKLISTS_DATA_IS_LOADING, false);
    }
  },
  [ActionTypes.CHECKLISTS_SELECTED_CHECKLIST_CLEAR]({ commit }) {
    commit(MutationTypes.SET_CHECKLISTS_SELECTED_CHECKLIST, null);
  },
  async [ActionTypes.CHECKLISTS_FULL_LIST]({ commit, dispatch }, id: number) {
    const client: BaseClient = getClientInstance();

    commit(MutationTypes.SET_CHECKLISTS_DATA_IS_LOADING, true);

    try {
      const response: GetCheckListWithCriterionsResponseModel =
        await client.getCheckListWithCriterions(id);
      const criterionList: CriterionModel[] = response.criterions;
      const checkList: CheckListModel = {
        id: response.id,
        name: response.name,
        createdAt: response.createdAt,
        updatedAt: response.updatedAt,
      };
      commit(MutationTypes.SET_CHECKLISTS_SELECTED_CHECKLIST, checkList);
      dispatch(StoreActionTypes.SET_CRITERIONS_LIST_FROM_ARRAY, criterionList);
    } catch (error) {
      console.error("getCategory", error);
    } finally {
      commit(MutationTypes.SET_CHECKLISTS_DATA_IS_LOADING, false);
    }
  },
  async [ActionTypes.CHECKLISTS_FULL_LIST_SAVE]({ commit, dispatch }, model) {
    const client: BaseClient = getClientInstance();
    const criterions = model.list
      .map((v) =>
        v.criterions.map((t, index) => {
          t.position = {
            ...t.position,
            [model.checkListId]: index,
          };
          t.category = {
            id: v.id,
            name: v.name,
          };

          return t;
        })
      )
      .flat();

    model.list.forEach((v, index) => {
      dispatch(StoreActionTypes.SET_CATEGORIES_CATEGORY_CHANGE, {
        id: v.id,
        name: v.name,
        status: v.status,
        position: {
          ...v.position,
          [model.checkListId]: index,
        },
      });
    });

    criterions.forEach(async (v) => {
      try {
        const result = await client.editCriterion(v.id, {
          categoryId: v.category.id,
          typeCode: v.typeCode,
          status: v.status,
          position: v.position,
          options: v.options,
          name: v.name,
          notes: v.notes ? v.notes : [],
        });
      } catch (error) {
        window.error("Ошибка изменения чеклиста");
        console.error("editCriterion", error);
      }
    });
  },
  async [ActionTypes.CHECKLISTS_FULL_LIST_GET](
    { commit, dispatch, getters },
    id: number
  ) {
    const client: BaseClient = getClientInstance();

    commit(MutationTypes.SET_CHECKLISTS_DATA_IS_LOADING, true);

    try {
      const criterionResponse: any = await client.getCheckListWithCriterions(
        id
      );
      const categoriesResponse: any = await client.getCategoriesFullList();

      const sortUnNuller = (value) => {
        if (!value || value[id] === undefined || value[id] == null) {
          return Infinity;
        }

        return value[id];
      };

      const list = JSON.parse(JSON.stringify(categoriesResponse.items))
        .filter(
          (v) =>
            criterionResponse.criterions.findIndex((t) => {
              return t.category.id === v.id;
            }) !== -1
        )
        .map((category) => {
          category.type = "group";
          category.projectId = id;

          category.criterions = category.criterions
            .filter(
              (v) =>
                criterionResponse.criterions.findIndex((t) => t.id === v.id) !==
                -1
            )
            .map((criterion) => {
              criterion.type = "criterion";

              criterion.notes.sort((a, b) => {
                return sortUnNuller(a.position) - sortUnNuller(b.position);
              });

              return criterion;
            })
            .sort((a, b) => {
              return sortUnNuller(a.position) - sortUnNuller(b.position);
            });

          return category;
        })
        .sort((a, b) => {
          return sortUnNuller(a.position) - sortUnNuller(b.position);
        });

      const checkList: CheckListModel = {
        id: criterionResponse.id,
        name: criterionResponse.name,
        createdAt: criterionResponse.createdAt,
        updatedAt: criterionResponse.updatedAt,
        projects: criterionResponse.projects,
      };

      commit(MutationTypes.SET_CHECKLISTS_SELECTED_CHECKLIST, checkList);
      dispatch(StoreActionTypes.SET_CRITERIONS_LIST_FROM_ARRAY, list);
    } catch (error) {
      window.error("Ошибка получения списка критериев");
      console.error("getCategory", error);
    } finally {
      commit(MutationTypes.SET_CHECKLISTS_DATA_IS_LOADING, false);
    }
  },
  [ActionTypes.CHECKLISTS_CATEGORIES_LIST_BY_SELECTED_CHECKLIST_CLEAR]({
    commit,
  }) {
    commit(
      MutationTypes.SET_CHECKLISTS_CATEGORIES_LIST_BY_SELECTED_CHECKLIST,
      []
    );
  },
  async [ActionTypes.CHECKLISTS_CATEGORIES_LIST_BY_SELECTED_CHECKLIST](
    { commit },
    id: number
  ) {
    const client: BaseClient = getClientInstance();

    commit(MutationTypes.SET_CHECKLISTS_DATA_IS_LOADING, true);

    try {
      const response: CategoriesWithCriterionsListByCheckListIdResponseModel =
        await client.getCategoriesWithCriterionsListByCheckListId(id);

      commit(
        MutationTypes.SET_CHECKLISTS_CATEGORIES_LIST_BY_SELECTED_CHECKLIST,
        response.categories
      );
    } catch (error) {
      console.error("getCategoriesWithCriterionsListByCheckListId", error);
    } finally {
      commit(MutationTypes.SET_CHECKLISTS_DATA_IS_LOADING, false);
    }
  },
  async [ActionTypes.CHECKLISTS_EDIT_CHECKLIST_CRITERIONS_LIST](
    { commit, dispatch, getters },
    model: EditRecordIdListRequestModel
  ) {
    const client: BaseClient = getClientInstance();

    commit(MutationTypes.SET_CHECKLISTS_DATA_IS_LOADING, true);

    const id = getters.getCheckListsSelectedCheckList.id;

    try {
      const response = await client.editCheckListCriterions(id, model);
    } catch (error) {
      console.error("editCheckListCriterions", error);
    } finally {
      dispatch(ActionTypes.CHECKLISTS_FULL_LIST_GET, id);
    }
  },
  async [ActionTypes.CHECKLISTS_LIST_SIMPLE]({ commit }) {
    const client: BaseClient = getClientInstance();

    commit(MutationTypes.SET_CHECKLISTS_DATA_IS_LOADING, true);

    try {
      const response: CheckListWithCategoriesSimpleModelListResponse =
        await client.getCheckListWithCategoriesSimpleList();

      commit(MutationTypes.SET_CHECKLISTS_LIST_SIMPLE, response.items);
    } catch (error) {
      console.error("getCheckListsList", error);
    } finally {
      commit(MutationTypes.SET_CHECKLISTS_DATA_IS_LOADING, false);
    }
  },
};
