import { Module } from "vuex";
import { CardsStateModel, state } from "@/store/modules/cards/state";
import { IRootStateModel } from "@/store/interfaces";
import { actions } from "@/store/modules/cards/actions";
import { mutations } from "@/store/modules/cards/mutations";
import { getters } from "@/store/modules/cards/getters";

const cards: Module<CardsStateModel, IRootStateModel> = {
  state,
  actions,
  mutations,
  getters,
};

export default cards;
