export enum MutationTypes {
  SET_CALLS_LIST = "setCallsList",
  SET_CALLS_LIST_PAGE = "setCallsListPage",
  SET_CALLS_TOTAL = "setCallsTotal",
  SET_CALLS_TOTAL_DURATION = "setCallsTotalDuration",
  SET_CALLS_LIST_PER_PAGE = "setCallsListPerPage",
  SET_CALLS_DATA_IS_LOADING = "setCallsDataIsLoading",
  SET_CALLS_SORT_VALUE = "setCallsSortValue",
  SET_CALLS_FILTER_VALUE = "setCallsFilterValue",
  SET_CALLS_SELECTED_GRID_COLUMNS = "setCallsSelectedGridColumns",
  SET_CALLS_MANAGERS_LIST = "setCallsManagersList",
  SET_CALLS_PROJECTS_LIST = "setCallsProjectsList",
  SET_CALLS_LINKED_PHONES_LIST = "setCallsLinkedPhonesList",
}
