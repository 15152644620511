import { ActionTree, ActionContext } from "vuex";
import { EwaStateModel, IRootStateModel } from "../../../interfaces";
import { EwaMutations } from "./../mutations";
import { ActionTypes } from "./types";
import { MutationTypes } from "./../mutations/types";
import { BaseClient } from "./../../../../services/client/BaseClient";
import { getClientInstance } from "./../../../..//services/client/clientProvider";
import {
  EwaInstance,
  EwaInstanceQr,
  EwaInstanceSettings,
  EwaOrder,
  EwaTariff,
  EwaUser,
} from "@/services/types/ewa";
import { BaseResponseModel } from "@/services/types";

type AugmentedActionEwaContext = {
  commit<K extends keyof EwaMutations>(
    key: K,
    payload: Parameters<EwaMutations[K]>[1]
  ): ReturnType<EwaMutations[K]>;
} & Omit<ActionContext<EwaStateModel, IRootStateModel>, "commit">;

export interface EwaActions {
  [ActionTypes.GET_ALL_INSTANCES](
    context: AugmentedActionEwaContext
  ): Promise<void>;
  [ActionTypes.GET_USER_INSTANCES](
    context: AugmentedActionEwaContext
  ): Promise<void>;
  [ActionTypes.GET_INSTANCE_SETTINGS](
    context: AugmentedActionEwaContext,
    idInstance: number
  ): Promise<void>;
  [ActionTypes.CREATE_INSTANCE](
    context: AugmentedActionEwaContext,
    idTariff: number,
    couponCode?: string
  ): Promise<void>;
  [ActionTypes.GET_QR](
    context: AugmentedActionEwaContext,
    idInstance: number
  ): Promise<void>;
  [ActionTypes.DELETE_INSTANCE](
    context: AugmentedActionEwaContext,
    companyId: number
  ): Promise<void>;
  [ActionTypes.LOGOUT_INSTANCE](
    context: AugmentedActionEwaContext,
    companyId: number
  ): Promise<void>;
  [ActionTypes.GET_TARIFFS](
    context: AugmentedActionEwaContext,
    couponCode?: string
  ): Promise<void>;
  [ActionTypes.ADD_TARIFF](
    context: AugmentedActionEwaContext,
    data: EwaTariff
  ): Promise<void>;
  [ActionTypes.REMOVE_TARIFF](
    context: AugmentedActionEwaContext,
    id: number
  ): Promise<void>;
  [ActionTypes.GET_ORDER](context: AugmentedActionEwaContext): Promise<void>;
  [ActionTypes.SET_ORDER](
    context: AugmentedActionEwaContext,
    data: EwaOrder
  ): Promise<void>;
  [ActionTypes.GET_BALANCE](context: AugmentedActionEwaContext): Promise<void>;
  [ActionTypes.SET_INSTANCE_SETTINGS](
    context: AugmentedActionEwaContext,
    settings: EwaInstanceSettings
  ): Promise<void>;
}

export const actions: ActionTree<EwaStateModel, IRootStateModel> & EwaActions =
  {
    async [ActionTypes.GET_ALL_INSTANCES]({ commit }) {
      const client: BaseClient = getClientInstance();

      commit(MutationTypes.SET_DATA_IS_LOADING, true);

      try {
        const response: EwaInstance[] = await client.getAllInstances();
        if (!response) {
          return;
        }

        commit(MutationTypes.SET_INSTANCES_LIST, response);
      } catch (error) {
        console.error("getAllInstances", error);
      } finally {
        setTimeout(
          () => commit(MutationTypes.SET_DATA_IS_LOADING, false),
          1000
        );
      }
    },

    async [ActionTypes.GET_USER_INSTANCES]({ commit, dispatch }) {
      const client: BaseClient = getClientInstance();

      commit(MutationTypes.SET_DATA_IS_LOADING, true);

      try {
        const response: EwaInstance[] = await client.getUserInstances();
        if (!response) {
          return;
        }

        commit(MutationTypes.SET_INSTANCES_LIST, response);
      } catch (error) {
        console.error("getUserInstances", error);
      } finally {
        commit(MutationTypes.SET_DATA_IS_LOADING, false);
      }
    },

    async [ActionTypes.GET_INSTANCE_SETTINGS](
      { state, commit },
      idInstance: number
    ) {
      const client: BaseClient = getClientInstance();

      commit(MutationTypes.SET_DIALOG_IS_LOADING, true);

      try {
        if (!state.list) {
          return;
        }

        const response: EwaInstanceSettings = await client.getInstanceSettings(
          idInstance
        );
        if (!response) {
          return;
        }

        const instance = state.list.find((v) => v.idInstance === idInstance);
        if (!instance) {
          return;
        }

        instance.settings = response;

        commit(MutationTypes.SET_CURRENT_INSTANCE, instance);
      } catch (error) {
        console.error("getInstanceSettings", error);
      } finally {
        commit(MutationTypes.SET_DIALOG_IS_LOADING, false);
      }
    },

    async [ActionTypes.SET_INSTANCE_SETTINGS](
      { state, commit, dispatch },
      settings: EwaInstanceSettings
    ) {
      const client: BaseClient = getClientInstance();

      commit(MutationTypes.SET_DATA_IS_LOADING, true);

      try {
        if (!state.currentInstance || !state.currentInstance.settings) {
          return;
        }

        const currentInstance = JSON.parse(
          JSON.stringify(state.currentInstance)
        );
        const response: EwaInstanceSettings | any =
          await client.setInstanceSettings(currentInstance.idInstance, {
            ...state.currentInstance.settings,
            ...settings,
          });
        if (!response) {
          return;
        }

        currentInstance.settings = response;

        commit(MutationTypes.SET_CURRENT_INSTANCE, currentInstance);
      } catch (error) {
        console.error("setInstanceSettings", error);
      } finally {
        commit(MutationTypes.SET_DATA_IS_LOADING, false);
      }
    },

    async [ActionTypes.CREATE_INSTANCE]({ commit }, payload: any) {
      const client: BaseClient = getClientInstance();

      commit(MutationTypes.SET_DIALOG_IS_LOADING, true);

      try {
        await client.addInstance(payload.idTariff, payload.couponCode);
      } catch (error) {
        (window as any).hideModal();
        console.error("createInstance", error);
        (window as any).error(
          "Проверьте настройки тарифа",
          "Невозможно добавить канал"
        );
      } finally {
        commit(MutationTypes.SET_DIALOG_IS_LOADING, false);
      }
    },

    async [ActionTypes.GET_QR]({ commit }, idInstance: number) {
      const client: BaseClient = getClientInstance();

      commit(MutationTypes.SET_DIALOG_IS_LOADING, true);
      try {
        const qr: EwaInstanceQr = await client.getInstanceQr(idInstance);
        if (!qr) {
          return;
        }

        commit(MutationTypes.SET_CURRENT_QR, qr);
      } catch (error) {
        (window as any).hideModal();
        (window as any).error(
          "Возможно, канал уже авторизован",
          "Ошибка получения QR-кода"
        );
        console.error("getQr", error);
      } finally {
        commit(MutationTypes.SET_DIALOG_IS_LOADING, false);
      }
    },

    async [ActionTypes.DELETE_INSTANCE](
      { commit, dispatch },
      idInstance: number
    ) {
      const client: BaseClient = getClientInstance();

      commit(MutationTypes.SET_DATA_IS_LOADING, true);

      try {
        const result: BaseResponseModel = await client.deleteInstance(
          idInstance
        );
        if (!result) {
          return;
        }

        await dispatch(ActionTypes.GET_ALL_INSTANCES);
      } catch (error) {
        console.error("deleteInstance", error);
      } finally {
        commit(MutationTypes.SET_DATA_IS_LOADING, false);
      }
    },

    async [ActionTypes.LOGOUT_INSTANCE](
      { commit, dispatch },
      idInstance: number
    ) {
      const client: BaseClient = getClientInstance();

      commit(MutationTypes.SET_DIALOG_IS_LOADING, true);

      try {
        const result: BaseResponseModel = await client.logoutInstance(
          idInstance
        );
        if (!result) {
          return;
        }

        await dispatch(ActionTypes.GET_USER_INSTANCES);
      } catch (error) {
        console.error("logoutInstance", error);
      } finally {
        commit(MutationTypes.SET_DIALOG_IS_LOADING, false);
      }
    },

    async [ActionTypes.GET_TARIFFS]({ commit }, couponCode?: string) {
      const client: BaseClient = getClientInstance();
      commit(MutationTypes.SET_DIALOG_IS_LOADING, true);

      try {
        const result: EwaTariff[] = await client.getTariffs(couponCode);
        if (!result) {
          return;
        }

        commit(MutationTypes.SET_TARIFFS, result);
      } catch (error) {
        console.error("getTariff", error);
      } finally {
        commit(MutationTypes.SET_DIALOG_IS_LOADING, false);
      }
    },

    async [ActionTypes.ADD_TARIFF]({ commit }, data: EwaTariff) {
      const client: BaseClient = getClientInstance();

      try {
        const result: BaseResponseModel = await client.addTariff(data);
      } catch (error) {
        console.error("addTariff", error);
      }
    },

    async [ActionTypes.REMOVE_TARIFF]({ commit }, id: number) {
      const client: BaseClient = getClientInstance();

      try {
        const result: BaseResponseModel = await client.removeTariff(id);
      } catch (error) {
        console.error("removeTariff", error);
      }
    },

    async [ActionTypes.GET_ORDER]({ commit }) {
      const client: BaseClient = getClientInstance();

      commit(MutationTypes.SET_DIALOG_IS_LOADING, true);

      try {
        const result: EwaUser = await client.getOrder();
        if (!result) {
          return;
        }

        commit(MutationTypes.SET_EWA_USER, result);
      } catch (error) {
        console.error("getOrder", error);
      } finally {
        commit(MutationTypes.SET_DIALOG_IS_LOADING, false);
      }
    },

    async [ActionTypes.SET_ORDER]({ commit }, data: EwaOrder) {
      const client: BaseClient = getClientInstance();

      commit(MutationTypes.SET_DIALOG_IS_LOADING, true);

      try {
        const result: { result: string; invoice_id: number } =
          await client.setOrder(data);
        if (!result || result.result !== "ok") {
          return;
        }
      } catch (error) {
        console.error("setOrder", error);
      } finally {
        commit(MutationTypes.SET_DIALOG_IS_LOADING, false);
        (window as any).hideModal();
        (window as any).openModal("Success");
      }
    },

    async [ActionTypes.GET_BALANCE]({ commit }) {
      const client: BaseClient = getClientInstance();

      commit(MutationTypes.SET_DATA_IS_LOADING, true);

      try {
        const result: { waBalance: number } = await client.getBalance();
        if (!result) {
          return;
        }

        commit(MutationTypes.SET_BALANCE, result.waBalance);
      } catch (error) {
        console.error("setOrder", error);
      } finally {
        //commit(MutationTypes.SET_DATA_IS_LOADING, false);
      }
    },
  };
