export enum ActionTypes {
  SET_CATEGORIES_LIST = "setCategoriesList",
  SET_CATEGORIES_FULL_LIST = "setCategoriesFullList",
  SET_CATEGORIES_WITH_CRITERIONS_FULL_LIST = "setCategoriesWithCriterionsFullList",
  SET_CATEGORIES_LIST_PAGE = "setCategoriesListPage",
  SET_CATEGORIES_LIST_PER_PAGE = "setCategoriesListPerPage",
  SET_CATEGORIES_LIST_SORT = "setCategoriesListSort",
  SET_CATEGORIES_LIST_FILTER = "setCategoriesListFilter",
  SET_CATEGORIES_CATEGORY_CHANGE_STATUS = "setCategoriesCategoryChangeStatus",
  SET_CATEGORIES_CATEGORY_DELETE = "setCategoriesCategoryDelete",
  SET_CATEGORIES_CATEGORY_CREATE = "setCategoriesCategoryCreate",
  SET_CATEGORIES_CATEGORY_CHANGE = "setCategoriesCategoryChange",
  SET_CATEGORIES_SELECTED_CATEGORY = "setCategoriesSelectedCategory",
  SET_CATEGORIES_SELECTED_CATEGORY_CLEAR = "setCategoriesSelectedCategoryClear",
  SET_CATEGORIES_SELECTED_CATEGORY_WITH_CRITERIONS_LIST = "setCategoriesSelectedCategoryWithCriterionsList",
}
