import { MutationTree } from "vuex";
import { MutationTypes } from "./types";
import { ICommentsStateModel } from "./../../../interfaces";
import { CommentModel } from "@/services/types/entities/comments";

export type CommentsMutations<S = ICommentsStateModel> = {
  [MutationTypes.SET_COMMENTS_LIST](state: S, payload: CommentModel[]): void;
  [MutationTypes.SET_COMMENTS_SELECTED_COMMENT](
    state: S,
    payload: CommentModel
  ): void;
  [MutationTypes.SET_COMMENTS_DATA_IS_LOADING](
    state: S,
    payload: boolean
  ): void;
  [MutationTypes.SET_COMMENTS_HAS_NOT_SAVED_COMMENT](
    state: S,
    payload: boolean
  ): void;
};

export const mutations: MutationTree<ICommentsStateModel> & CommentsMutations =
  {
    [MutationTypes.SET_COMMENTS_LIST](state, payload) {
      state.list = payload;
    },
    [MutationTypes.SET_COMMENTS_SELECTED_COMMENT](state, payload) {
      state.selectedComment = payload;
    },
    [MutationTypes.SET_COMMENTS_DATA_IS_LOADING](state, payload: boolean) {
      state.dataIsLoading = payload;
    },
    [MutationTypes.SET_COMMENTS_HAS_NOT_SAVED_COMMENT](
      state,
      payload: boolean
    ) {
      state.hasNotSavedComment = payload;
    },
  };
