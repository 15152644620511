export enum ActionTypes {
  SET_CRITERIONS_LIST = "setCriterionsList",
  SET_CRITERIONS_LIST_PAGE = "setCriterionsListPage",
  SET_CRITERIONS_LIST_PER_PAGE = "setCriterionsListPerPage",
  SET_CRITERIONS_LIST_BY_CATEGORY_ID = "setCriterionsListByCategoryId",
  SET_CRITERIONS_LIST_BY_CHECKLIST_ID = "setCriterionsListByCheckListId",
  SET_CRITERIONS_LIST_FROM_ARRAY = "setCriterionsListFromArray",
  SET_CRITERIONS_LIST_FILTER = "setCriterionsListFilter",
  SET_CRITERIONS_CRITERION_CHANGE_STATUS = "setCriterionsCriterionChangeStatus",
  SET_CRITERIONS_CRITERION_DELETE = "setCriterionsCriterionDelete",
  SET_CRITERIONS_CRITERION_CREATE = "setCriterionsCriterionCreate",
  SET_CRITERIONS_CRITERION_EDIT = "setCriterionsCriterionEdit",
  SET_CRITERIONS_SELECTED_CRITERION = "setCriterionsSelectedCriterion",
  SET_CRITERIONS_SELECTED_CRITERION_CLEAR = "setCriterionsSelectedCriterionClear",
  SET_CRITERIONS_LIST_SORT = "setCriterionsListSort",
}
