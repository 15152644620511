import { IPlayerStateModel } from "./../../interfaces";

export const defaultState: IPlayerStateModel = {
  currentTime: 0,
  duration: 0,
  play: false,
  playBackSpeed: 1.25,
  volume: 100,
  url: "",
  newCurrentTime: 0,
  isPlayed: false,
};

export const state: IPlayerStateModel = {
  ...defaultState,
};
