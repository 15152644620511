export enum MutationTypes {
  SET_SELECTED_CALL_DATA_IS_LOADING = "setSelectedCallDataIsLoading",
  SET_SELECTED_CALL = "setSelectedCall",
  SET_SELECTED_CALL_CATEGORIES = "setSelectedCallCategories",
  SET_SELECTED_CALL_ESTIMATED_CHECK_LIST = "setSelectedCallEstimatedCheckList",
  SET_SELECTED_CALL_CHECK_LIST_ID = "setSelectedCallCheckListId",
  SET_SELECTED_CALL_RATED = "setSelectedCallRated",
  SET_SELECTED_CALL_SELECTED_AUDITOR_ID = "setSelectedCAllSelectedAuditorId",
  SET_SELECTED_CALL_CURRENT_ESTIMATION = "setSelectedCallCurrentEstimation",
  SET_SELECTED_CALL_AVAILABLE_MANAGERS_LIST = "setSelectedCallAvailableManagersList",
  SET_SELECTED_CALL_CURRENT_ESTIMATION_CLEAR = "setSelectedCallCurrentEstimationClear",
  SET_SELECTED_CALL_RATED_CLEAR = "setSelectedCallRatedClear",
  SET_SELECTED_CALL_CLEAR = "setSelectedCallClear",
  SET_TOP_COLLAPSE_OPENED = "setTopCollapseOpened",
}
