import { GetterTree } from "vuex";
import { EwaStateModel, IRootStateModel } from "./../../../interfaces";

export type EwaGetters = {
  getInstanceList(state: EwaStateModel);
  getDialogQueue(state: EwaStateModel);
  ewaDataIsLoading(state: EwaStateModel);
  ewaDialogIsLoading(state: EwaStateModel);
  getCurrentInstance(state: EwaStateModel);
  getCurrentQr(state: EwaStateModel);
  getTariffs(state: EwaStateModel);
  getEwaUser(state: EwaStateModel);
  getBalance(state: EwaStateModel);
};

export const getters: GetterTree<EwaStateModel, IRootStateModel> & EwaGetters =
  {
    getInstanceList: (state: EwaStateModel) => {
      return state.list;
    },
    getDialogQueue: (state: EwaStateModel) => {
      return state.dialogQueue;
    },
    getCurrentQr: (state: EwaStateModel) => {
      return state.currentQr;
    },
    ewaDataIsLoading: (state: EwaStateModel) => {
      return state.dataIsLoading;
    },
    ewaDialogIsLoading: (state: EwaStateModel) => {
      return state.dialogIsLoading;
    },
    getCurrentInstance: (state: EwaStateModel) => {
      return state.currentInstance;
    },
    getTariffs: (state: EwaStateModel) => {
      return state.tariffs;
    },
    getEwaUser: (state: EwaStateModel) => {
      return state.user;
    },
    getBalance: (state: EwaStateModel) => {
      return state.balance;
    },
  };
