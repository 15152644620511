import { Module } from "vuex";
import { state } from "./state";
import { ICheckListsStateModel, IRootStateModel } from "./../../interfaces";
import { mutations } from "./mutations";
import { getters } from "./getters";
import { actions } from "./actions";

const checkLists: Module<ICheckListsStateModel, IRootStateModel> = {
  state,
  mutations,
  getters,
  actions,
};

export default checkLists;
