import { ReportsSnap } from "@/store/modules/reports2/types";
import { getStore } from "@/store/getStore";
import {
  getDateFilterFromInterval,
  REPORTS_DEFAULT_DATE_INTERVAL,
  REPORTS_NONE_DATE_INTERVAL,
} from "@/services/reports/dateIntervals";
import { StoreActionTypes, StoreMutationTypes } from "@/store/types";

export function createReportsSnap(name: string): ReportsSnap {
  const getters = getStore().getters;
  const dateInterval = getters.getReports2DateInterval;
  const dateFilters =
    dateInterval === REPORTS_NONE_DATE_INTERVAL
      ? getters.getReports2DateFilter
      : {
          from: null,
          to: null,
        };

  return JSON.parse(
    JSON.stringify({
      name,
      id: `snap${Date.now()}`,
      dateFilters,
      dateInterval,
      selectedReports: getters.getReports2SelectedReports,
      selectedProjects: getters.getReports2SelectedProjects,
      pageType: getters.getReports2Page,
      targets: getters.getReports2Targets,
    })
  );
}

export async function applyReportsSnap(snap: ReportsSnap) {
  getStore().commit(StoreMutationTypes.SET_REPORTS2_PAGE, snap.pageType);

  getStore().commit(
    StoreMutationTypes.SET_REPORTS2_DATE_INTERVAL,
    snap.dateInterval
  );

  getStore().commit(
    StoreMutationTypes.SET_REPORTS2_DATE_FILTER,
    snap.dateInterval === 0
      ? snap.dateFilters
      : getDateFilterFromInterval(snap.dateInterval)
  );

  getStore().commit(
    StoreMutationTypes.SET_REPORTS2_DATE_INTERVAL,
    snap.dateInterval
  );

  await getStore().dispatch(StoreActionTypes.FETCH_REPORTS2_DATA, null);

  getStore().commit(
    StoreMutationTypes.SET_REPORTS2_SELECTED_PROJECTS,
    snap.selectedProjects
  );
  getStore().commit(
    StoreMutationTypes.SET_REPORTS2_SELECTED_REPORTS,
    snap.selectedReports
  );
  getStore().commit(StoreMutationTypes.SET_REPORTS2_TARGETS, snap.targets);

  // do it after fetch, cause creating new render list resets selected snap
  getStore().commit(StoreMutationTypes.SET_REPORTS2_REPORTS_SNAP_ID, snap.id);
}

export function resetSelectedSnap() {
  getStore().commit(StoreMutationTypes.SET_REPORTS2_REPORTS_SNAP_ID, null);
}
