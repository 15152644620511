import "@fancyapps/ui/dist/fancybox/fancybox.css";
import { Fancybox } from "@fancyapps/ui";

Fancybox.defaults = {
  ...Fancybox.defaults,
  Slideshow: false,
};

function show(src: string) {
  Fancybox.show([{ src }]);
}

function showGallery(sources: string[], from = 0) {
  Fancybox.show(
    sources.map((src) => ({ src })),
    {
      startIndex: from,
    }
  );
}

export const useLightbox = () => {
  return {
    show,
    showGallery,
  };
};
