import { BaseApi } from "@/services/api/BaseApi";
import type {GetProjectsPlainListResponseDto, SttFilterRequestDto} from "@/services/api/projects/types";
import {
  GetProjectsManagersRequestDto,
  GetProjectsManagersResponseDto, GetSttFilterResponseDto, SttFilterTestResponseDto,
} from "@/services/api/projects/types";
import {SttFilterModel} from "@/features/project-stt-settings/types";

export class ProjectsApi extends BaseApi {
  async getProjectsPlainList() {
    return (await this.client.get(
      `/projects/list`
    )) as Promise<GetProjectsPlainListResponseDto>;
  }

  async getProjectsManagers(payload: GetProjectsManagersRequestDto = {}) {
    return (await this.client.post(
      payload,
      `/projects/managers`
    )) as Promise<GetProjectsManagersResponseDto>;
  }

  testSttFilter(id: number, payload: SttFilterRequestDto) {
    return this.client.post(payload, `/projects/${id}/sttFilterTest`) as Promise<SttFilterTestResponseDto>
  }
  getSttFilter(id: number) {
    return this.client.get(`/projects/${id}/sttFilter`) as Promise<GetSttFilterResponseDto>
  }
  setSttFilter(id: number, payload: SttFilterRequestDto) {
    return this.client.post(payload, `/projects/${id}/sttFilter`) as Promise<GetSttFilterResponseDto>
  }
}
