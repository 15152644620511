import { MutationTree } from "vuex";
import { MutationTypes } from "./types";
import { ICriterionsStateModel } from "./../../../interfaces";
import {
  CriterionModel,
  CriterionsListFilterModel,
  CriterionsListSortModel,
  CriterionFullModel,
  CategoryRenderModel,
} from "@/services/types";

export type CriterionsMutations<S = ICriterionsStateModel> = {
  [MutationTypes.SET_CRITERIONS_LIST](
    state: S,
    payload: CategoryRenderModel[]
  ): void;
  [MutationTypes.SET_CRITERIONS_DATA_IS_LOADING](
    state: S,
    payload: boolean
  ): void;
  [MutationTypes.SET_CRITERIONS_LIST_TOTAL](state: S, payload: number): void;
  [MutationTypes.SET_CRITERIONS_LIST_PAGE](state: S, payload: number): void;
  [MutationTypes.SET_CRITERIONS_LIST_PER_PAGE](state: S, payload: number): void;
  [MutationTypes.SET_CRITERIONS_FILTER_VALUE](
    state: S,
    payload: CriterionsListFilterModel
  ): void;
  [MutationTypes.SET_CRITERIONS_SELECTED_CRITERION](
    state: S,
    payload: CriterionFullModel
  ): void;
  [MutationTypes.SET_CRITERIONS_SORT_VALUE](
    state: S,
    payload: CriterionsListSortModel
  ): void;
};

export const mutations: MutationTree<ICriterionsStateModel> &
  CriterionsMutations = {
  [MutationTypes.SET_CRITERIONS_LIST](state, payload: CategoryRenderModel[]) {
    state.list = payload;
  },
  [MutationTypes.SET_CRITERIONS_DATA_IS_LOADING](state, payload: boolean) {
    state.dataIsLoading = payload;
  },
  [MutationTypes.SET_CRITERIONS_FILTER_VALUE](
    state,
    payload: CriterionsListFilterModel
  ) {
    state.filter = payload;
  },
  [MutationTypes.SET_CRITERIONS_SELECTED_CRITERION](
    state,
    payload: CriterionFullModel
  ) {
    state.selectedCriterion = payload;
  },
  [MutationTypes.SET_CRITERIONS_LIST_PAGE](state, payload: number) {
    state.page = payload;
  },
  [MutationTypes.SET_CRITERIONS_LIST_PER_PAGE](state, payload: number) {
    state.perPage = payload;
  },
  [MutationTypes.SET_CRITERIONS_LIST_TOTAL](state, payload: number) {
    state.total = payload;
  },
  [MutationTypes.SET_CRITERIONS_SORT_VALUE](
    state,
    payload: CriterionsListSortModel
  ) {
    state.sort = payload;
  },
};
