import { ActionContext, ActionTree } from "vuex";
import { IRootStateModel } from "@/store/interfaces";
import { Reports2Mutations } from "./../mutations";
import { Reports2ActionTypes } from "./types";
import { Reports2MutationTypes } from "./../mutations/types";
import { GetReportsResponse, ReportsPageType } from "@/services/types";
import { BaseClient } from "@/services/client/BaseClient";
import { getClientInstance } from "@/services/client/clientProvider";
import { StoreActionTypes, StoreMutationTypes } from "@/store/types";
import { Reports2Getters } from "@/store/modules/reports2/getters";
import { IReports2StateModel } from "@/store/modules/reports2/state";
import { createReportsRenderList } from "@/services/reports/reportsUtils";
import { resetSelectedSnap } from "@/services/reports/reportsSnaps";
import { getStore } from "@/store/getStore";

type AugmentedActionReports2Context = {
  commit<K extends keyof Reports2Mutations>(
    key: K,
    payload: Parameters<Reports2Mutations[K]>[1]
  ): ReturnType<Reports2Mutations[K]>;
  getters: {
    [K in keyof Reports2Getters]: ReturnType<Reports2Getters[K]>;
  };
} & Omit<
  ActionContext<IReports2StateModel, IRootStateModel>,
  "commit" | "getters"
>;

export interface Reports2Actions {
  [Reports2ActionTypes.FETCH_REPORTS2_DATA](
    context: AugmentedActionReports2Context
  ): Promise<void>;

  [Reports2ActionTypes.FETCH_REPORTS2_REPORT_DATA](
    context: AugmentedActionReports2Context,
    reportId: number
  ): Promise<void>;

  [Reports2ActionTypes.SET_REPORTS2_RENDER_LIST](
    context: AugmentedActionReports2Context
  ): void;
}

export const actions: ActionTree<IReports2StateModel, IRootStateModel> &
  Reports2Actions = {
  async [Reports2ActionTypes.FETCH_REPORTS2_REPORT_DATA](
    { commit, getters, dispatch },
    reportId
  ) {
    getStore().commit(
      StoreMutationTypes.SET_REPORTS2_FILTER_REPORT_ID,
      reportId
    );

    if (reportId === null) {
      await getStore().dispatch(Reports2ActionTypes.FETCH_REPORTS2_DATA, null);
      return;
    }

    const client: BaseClient = getClientInstance();
    const page: ReportsPageType = getters.getReports2Page;
    commit(Reports2MutationTypes.SET_REPORTS2_DATA_IS_LOADING, true);

    try {
      const request = getters.getReports2DateFilter;
      let response: GetReportsResponse;

      if (page == "managers") {
        response = await client.getManagerReports(reportId, request);
      } else {
        response = await client.getCriterionReports(reportId, request);
      }

      // Set projects
      commit(Reports2MutationTypes.SET_REPORTS2_PROJECTS, response.projects);
      commit(
        Reports2MutationTypes.SET_REPORTS2_SELECTED_PROJECTS,
        response.projects.map((p) => p.id)
      );

      // Set reports
      commit(Reports2MutationTypes.SET_REPORTS2_REPORTS, response.rating);
      commit(
        Reports2MutationTypes.SET_REPORTS2_SELECTED_REPORTS,
        response.rating.map((r) => r.id)
      );

      // Set labels
      commit(Reports2MutationTypes.SET_REPORTS2_LABELS, response.labels);

      // Set raw data
      commit(
        Reports2MutationTypes.SET_REPORTS2_RAW_REPORTS_DATA,
        response.items
      );

      dispatch(StoreActionTypes.SET_REPORTS2_RENDER_LIST);
    } catch (error) {
      console.error("getReports", error);
    } finally {
      commit(Reports2MutationTypes.SET_REPORTS2_DATA_IS_LOADING, false);
    }
  },

  async [Reports2ActionTypes.FETCH_REPORTS2_DATA]({
    commit,
    getters,
    dispatch,
  }) {
    const client: BaseClient = getClientInstance();

    commit(Reports2MutationTypes.SET_REPORTS2_DATA_IS_LOADING, true);
    const page: ReportsPageType = getters.getReports2Page;

    try {
      const request = getters.getReports2DateFilter;
      let response: GetReportsResponse;

      if (page == "managers") {
        response = await client.getManagersReports(request);
      } else {
        response = await client.getCriterionsReports(request);
      }

      // Set projects
      commit(Reports2MutationTypes.SET_REPORTS2_PROJECTS, response.projects);
      commit(
        Reports2MutationTypes.SET_REPORTS2_SELECTED_PROJECTS,
        response.projects.map((p) => p.id)
      );

      // Set reports
      commit(Reports2MutationTypes.SET_REPORTS2_REPORTS, response.rating);
      commit(
        Reports2MutationTypes.SET_REPORTS2_SELECTED_REPORTS,
        response.rating.map((r) => r.id)
      );

      // Set labels
      commit(Reports2MutationTypes.SET_REPORTS2_LABELS, response.labels);

      // Set raw data
      commit(
        Reports2MutationTypes.SET_REPORTS2_RAW_REPORTS_DATA,
        response.items
      );

      // Set filter reports options
      commit(
        Reports2MutationTypes.SET_REPORTS2_FILTER_REPORT_OPTIONS,
        response.rating
      );
      commit(Reports2MutationTypes.SET_REPORTS2_FILTER_REPORT_ID, null);

      dispatch(StoreActionTypes.SET_REPORTS2_RENDER_LIST);
    } catch (error) {
      console.error("getReports", error);
    } finally {
      commit(Reports2MutationTypes.SET_REPORTS2_DATA_IS_LOADING, false);
    }
  },

  [Reports2ActionTypes.SET_REPORTS2_RENDER_LIST]({ commit, getters }) {
    commit(
      Reports2MutationTypes.SET_REPORTS2_RENDER_REPORTS_DATA,
      createReportsRenderList(
        getters.getReports2RawData,
        getters.getReports2Reports,
        getters.getReports2SelectedProjects
      )
    );
    resetSelectedSnap();
  },
};
