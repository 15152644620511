import { GetterTree } from "vuex";
import {
  IAuthentificationStateModel,
  IRootStateModel,
} from "./../../../interfaces";
import { Profile, LocaleType } from "@/services/types";

export type AuthentificationGetters = {
  isAuthentificationDataLoading(state: IAuthentificationStateModel): boolean;
  isAuthentificationError(state: IAuthentificationStateModel): boolean;
  getAuthentificationProfile(
    state: IAuthentificationStateModel
  ): Profile | null;
  getAuthentificationHelperText(state: IAuthentificationStateModel): string;
  getUserLocale(state: IAuthentificationStateModel): LocaleType;
  isAuthentificationAuthenticated(state: IAuthentificationStateModel): boolean;
  getAuthentificationPasswordHash(state: IAuthentificationStateModel): string;
  getAuthentificationShowTutorialVideo(
    state: IAuthentificationStateModel
  ): boolean;
};

export const getters: GetterTree<IAuthentificationStateModel, IRootStateModel> &
  AuthentificationGetters = {
  getAuthentificationHelperText: (state: IAuthentificationStateModel) => {
    return state.helperText;
  },
  getAuthentificationProfile: (state: IAuthentificationStateModel) => {
    return state.profile;
  },
  isAuthentificationAuthenticated: (state: IAuthentificationStateModel) => {
    return state.authenticated;
  },
  isAuthentificationDataLoading: (state: IAuthentificationStateModel) => {
    return state.dataIsLoading;
  },
  isAuthentificationError: (state: IAuthentificationStateModel) => {
    return state.error;
  },
  getUserLocale: (state: IAuthentificationStateModel) => {
    return state.profile ? state.profile.locale : null;
  },
  getAuthentificationPasswordHash: (state: IAuthentificationStateModel) => {
    return state.passwordHash;
  },
  getTutorialCompletedStatus: (state: IAuthentificationStateModel) => {
    return state.profile ? state.profile.tutorialCompleted : false;
  },
  getAuthentificationShowTutorialVideo: (
    state: IAuthentificationStateModel
  ) => {
    return state.showTutorialVideo;
  },
};
