import * as utils from "@/services/utils";
import {
  BaseReportRenderModel,
  DateIntervalsType,
  ReportCallModel,
  ReportCallsListSortModel,
  ReportItem,
  ReportItemRenderData,
  ReportsListDateFilterModel,
  ReportsPageType,
} from "@/services/types/reports";
import { BaseEntityModel } from "@/services/types/base";
import {
  REPORTS_DEFAULT_DATE_INTERVAL,
  getDateFilterFromInterval,
} from "@/services/reports/dateIntervals";

export type Reports2Targets = Record<string, { value: number; color: string }>;

export interface IReports2StateModel {
  isDataLoading: boolean;
  isDetailsLoading: boolean;
  labels: string[];
  projects: BaseEntityModel[];
  selectedProjects: number[];
  reports: BaseEntityModel[];
  selectedReports: number[];
  reportsRawData: ReportItem[];
  reportsRenderData: ReportItemRenderData[];
  page: ReportsPageType;
  dateFilter: ReportsListDateFilterModel;
  dateInterval: number;
  reportDetailsRawData: ReportItem[];
  reportDetailsRenderData: ReportItemRenderData[];
  targets: Reports2Targets;
  selectedReportsSnapId: string;
  filterReportId: number | null;
  filterReportOptions: BaseEntityModel[];
}

export function getDefaultState(): IReports2StateModel {
  return {
    isDataLoading: false,
    isDetailsLoading: false,
    labels: [],
    projects: [],
    selectedProjects: [],
    reports: [],
    selectedReports: [],
    reportsRawData: [],
    reportsRenderData: [],
    page: "managers",
    dateInterval: REPORTS_DEFAULT_DATE_INTERVAL,
    dateFilter: getDateFilterFromInterval(REPORTS_DEFAULT_DATE_INTERVAL),
    reportDetailsRawData: [],
    reportDetailsRenderData: [],
    targets: {},
    selectedReportsSnapId: null,
    filterReportId: null,
    filterReportOptions: [],
  };
}

export const state: IReports2StateModel = getDefaultState();
