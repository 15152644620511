import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import store from "@/store";
import { StoreActionTypes } from "@/store/types";
import Auth from "@/views/auth/Auth.vue";
import ResetPassword from "@/views/auth/ResetPassword.vue";
import SetPassword from "@/views/auth/SetPassword.vue";
import Reports from "@/views/reports/Reports.vue";
import Users from "@/views/users/Users.vue";
import CallsDetails from "@/views/calls/details/CallsDetails.vue";
import CallsList from "@/views/calls/list/CallsList.vue";
import Categories from "../views/category/list/Categories.vue";
import CategoryDetails from "../views/category/details/CategoryDetails.vue";
import Criterion from "../views/criterion/Criterion.vue";
import CheckListsList from "../views/checkList/list/CheckListsList.vue";
import Projects from "../views/projects/list/Projects.vue";
import ProjectDetails from "../views/projects/details/ProjectDetails.vue";
import CheckListDetails from "../views/checkList/details/CheckListDetails.vue";
import ProjectCreateStepOne from "../views/projects/create/ProjectCreateStepOne.vue";
import ProjectCreateStepTwo from "../views/projects/create/ProjectCreateStepTwo.vue";
import ProjectCreateStepThree from "../views/projects/create/ProjectCreateStepThree.vue";
import SetProjectIntegration from "../views/projects/create/SetProjectIntegration.vue";
import AddProjectIntegration from "../views/projects/create/AddProjectIntegration.vue";
import ManagerReport from "../views/reports/manager/ManagerReport.vue";
import CriterionReport from "../views/reports/criterion/CriterionReport.vue";
import ProjectIntegration from "../views/projects/integration/ProjectIntegration.vue";
import Subscription from "../views/subscriptions/Subscription.vue";
import EWAHome from "../views/ewa/Home.vue";
import EWASettings from "../views/ewa/Settings.vue";
import Changelog from "../views/changelog/ChangeLog.vue";
import NotFound from "../components/general/NotFound.vue";
import { UserPermissions } from "@/services/roles/permissionsEnum";
import { isUserHasPermission } from "@/services/roles";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "*",
    name: "NotFound",
    component: NotFound,
  },
  {
    path: "/",
    name: "Reports",
    component: Reports,
    meta: {
      requiresAuth: true,
      requiresPermission: [UserPermissions.VIEW_COMPANY_REPORTS],
    },
  },
  {
    path: "/reports",
    redirect: "/",
  },
  {
    path: "/reports/managers/:managerId",
    name: "managerReportsDetails",
    component: ManagerReport,
    props: true,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/reports/criterions/:criterionId",
    name: "criterionReportsDetails",
    component: CriterionReport,
    props: true,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/reports2",
    name: "Reports2",
    component: () => import("@/views/reports2/Reports2.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/projects",
    name: "Projects",
    component: Projects,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/projects/:projectId",
    name: "projectDetails",
    component: ProjectDetails,
    props: true,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/projects/:projectId/integrations/:integrationId",
    name: "projectIntegration",
    component: ProjectIntegration,
    props: true,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/projects/:projectId/stt",
    name: "projectSttSettings",
    component: () => import(`@/views/projects/stt/ProjectSttSettingsView.vue`),
    props: route => ({
      projectId: Number(route.params.projectId)
    }),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/projects/new/step-one",
    name: "projectCreateStepOne",
    component: ProjectCreateStepOne,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/projects/new/step-two",
    name: "projectCreateStepTwo",
    component: ProjectCreateStepTwo,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/projects/new/step-three",
    name: "projectCreateStepThree",
    component: ProjectCreateStepThree,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/set-project-integration/:projectId/:integrationId",
    name: "setProjectIntegration",
    component: SetProjectIntegration,
    props: true,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/projects/:projectId/add-integration",
    name: "addProjectIntegration",
    component: AddProjectIntegration,
    props: true,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/calls",
    name: "Calls",
    component: CallsList,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/calls/:callId",
    name: "Call Details",
    component: CallsDetails,
    props: true,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/calls-upload",
    name: "Calls Upload",
    component: () => import("@/views/calls/upload/CallsUpload.vue"),
    props: true,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/criterions-groups",
    name: "criterionsGroups",
    component: Categories,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/criterions-groups/:groupId",
    name: "criterionsGroupsDetails",
    component: CategoryDetails,
    props: true,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/criterions",
    name: "Criterion",
    component: Criterion,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/check-lists",
    name: "checkLists",
    component: CheckListsList,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/check-lists/:checkListId",
    name: "CheckListDetails",
    component: CheckListDetails,
    props: true,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/subscription",
    name: "Subscription",
    component: Subscription,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/login",
    name: "loginPage",
    component: Auth,
    meta: {
      requiresUnAuth: true,
    },
  },
  {
    path: "/reset-password",
    name: "resetPasswordPage",
    component: ResetPassword,
    meta: {
      requiresUnAuth: true,
    },
  },
  {
    path: "/set-password",
    name: "setPasswordPage",
    component: SetPassword,
    meta: {
      requiresUnAuth: true,
    },
  },
  {
    path: "/ewa",
    name: "EWAHome",
    component: EWAHome,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/changelog",
    name: "Changelog",
    component: Changelog,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/cards",
    name: "Cards",
    component: () => import("@/views/cards/CardsList.vue"),
    meta: {
      requiresAuth: true,
    },
  },

  {
    path: "/users",
    name: "Users",
    component: () => import("@/views/company/CompanyUsersList.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/subcompanies",
    name: "SubCompanies",
    component: () => import("@/views/company/subcompany/SubCompaniesList.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/subcompany/:companyId",
    name: "SubCompanyUsers",
    props: (route) => ({
      ...route.params,
      ...{
        companyId: Number.parseInt(route.params.companyId, 10) || undefined,
      },
    }),
    component: () => import("@/views/company/CompanyUsersList.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/my-profile",
    name: "MyProfile",
    component: () => import("@/views/profile/MyProfile.vue"),
    meta: {
      requiresAuth: true,
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (!store.getters.isAuthentificationAuthenticated) {
    store.dispatch(StoreActionTypes.CHECK_IS_LOGGED_IN);
  }
  if (!store.getters.checkDictionaries) {
    store.dispatch(StoreActionTypes.SET_DICTIONARIES_CHECK_DICTIONARIES_LIST);
  }

  const requiresPermissions: UserPermissions[] =
    to.meta.requiresPermission || [];
  requiresPermissions.forEach((p) => {
    if (!isUserHasPermission(p)) {
      next({ name: "MyProfile" });
      return;
    }
  });

  if (to.meta.requiresAuth && !store.getters.isAuthentificationAuthenticated) {
    next("/login");
  } else if (
    to.meta.requiresUnAuth &&
    store.getters.isAuthentificationAuthenticated
  ) {
    next("/");
  } else {
    next();
  }
});

export default router;
