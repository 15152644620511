import { EntitiesApi } from "@/services/api/entities/EntitiesApi";
import { ProjectsApi } from "@/services/api/projects/ProjectsApi";
import { ManagersApi } from "@/services/api/managers/ManagersApi";
import { AuthApi } from "@/services/api/auth/AuthApi";
import { EstimationsApi } from "@/services/api/estimations/EstimationsApi";
import { ReportsApi } from "@/services/api/reports/ReportsApi";

export default {
  authApi: new AuthApi(),
  entitiesApi: new EntitiesApi(),
  estimationsApi: new EstimationsApi(),
  projectsApi: new ProjectsApi(),
  managersApi: new ManagersApi(),
  reportsApi: new ReportsApi(),
};
