import {isWretchError} from "@/services/api/utils";

function showErrorText(text: string) {
  return (window as any).error(text)
}

export function showErrorMessage(error: any) {
  if (isWretchError(error)) {
    return showErrorText(error.json.message)
  } else {
    return showErrorText(error.toString())
  }
}

export function showInfoMessage(message: string) {
  return (window as any).info(message);
}
