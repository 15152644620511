import { GetterTree } from "vuex";
import {
  CallsManagersListItem,
  ICallsStateModel,
  IRootStateModel,
} from "./../../../interfaces";
import {
  BaseCallModel,
  CallsListSortModel,
  CallsListFilterModel,
  BaseEntityModel,
} from "@/services/types";

export type CallsGetters = {
  getCallsList(state: ICallsStateModel): BaseCallModel[];
  getCallsListPage(state: ICallsStateModel): number;
  getCallsListPerPage(state: ICallsStateModel): number;
  getCallsTotal(state: ICallsStateModel): number;
  isCallsDataLoading(state: ICallsStateModel): boolean;
  getCallsSortValue(state: ICallsStateModel): CallsListSortModel;
  getCallsFilterValue(state: ICallsStateModel): CallsListFilterModel;
  getCallsSelectedGridColumns(state: ICallsStateModel): string[];
  getCallsManagersList(state: ICallsStateModel): CallsManagersListItem[];
  getCallsProjectsList(state: ICallsStateModel): BaseEntityModel[];
  getCallsLinkedPhonesList(state: ICallsStateModel): string[];
};

export const getters: GetterTree<ICallsStateModel, IRootStateModel> &
  CallsGetters = {
  getCallsList: (state: ICallsStateModel) => {
    return state.list;
  },
  getCallsListPage: (state: ICallsStateModel) => {
    return state.page;
  },
  getCallsListPerPage: (state: ICallsStateModel) => {
    return state.perPage;
  },
  getCallsTotal: (state: ICallsStateModel) => {
    return state.total;
  },
  getCallsTotalDuration: (state: ICallsStateModel) => {
    return state.totalDuration;
  },
  isCallsDataLoading: (state: ICallsStateModel) => {
    return state.dataIsLoading;
  },
  getCallsFilterValue: (state: ICallsStateModel) => {
    return state.filter;
  },
  getCallsSortValue: (state: ICallsStateModel) => {
    return state.sort;
  },
  getCallsSelectedGridColumns: (state: ICallsStateModel) => {
    return state.selectedGridColumns;
  },
  getCallsManagersList: (state: ICallsStateModel) => {
    return state.managers;
  },
  getCallsProjectsList: (state: ICallsStateModel) => {
    return state.projects;
  },
  getCallsLinkedPhonesList: (state: ICallsStateModel) => {
    return state.linkedCalls;
  },
  getCallsLinkedPhonesFilterValue: (state: ICallsStateModel) => {
    return state.filter.linkedCalls;
  },
};
