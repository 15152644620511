import { SubscriptionModel } from "@/services/types";
import { GetterTree } from "vuex";
import {
  ISubscriptionStateModel,
  IRootStateModel,
} from "./../../../interfaces";

export type SubscriptionGetters = {
  isSubscriptionDataLoading(state: ISubscriptionStateModel): boolean;
  getSubscriptionData(state: ISubscriptionStateModel): SubscriptionModel;
};

export const getters: GetterTree<ISubscriptionStateModel, IRootStateModel> &
  SubscriptionGetters = {
  isSubscriptionDataLoading: (state: ISubscriptionStateModel) => {
    return state.dataIsLoading;
  },
  getSubscriptionData: (state: ISubscriptionStateModel) => {
    return state.subscription;
  },
};
