export enum ActionTypes {
  CHECKLISTS_LIST = "checkListsList",
  CHECKLISTS_FULL_LIST_GET = "checkListsFullListGet",
  CHECKLISTS_FULL_LIST_SAVE = "checkListsFullListSave",
  CHECKLISTS_LIST_PAGE = "checkListsListPage",
  CHECKLISTS_LIST_PER_PAGE = "checkListsListPerPage",
  CHECKLISTS_LIST_SORT = "checkListsListSort",
  CHECKLISTS_LIST_FILTER = "checkListsListFilter",
  CHECKLISTS_CHECKLIST_DELETE = "checkListsCheckListDelete",
  CHECKLISTS_CHECKLIST_CREATE = "checkListsCheckListCreate",
  CHECKLISTS_CHECKLIST_CHANGE_NAME = "checkListsCheckListChangeName",
  CHECKLISTS_SELECTED_CHECKLIST = "checkListsSelectedCheckList",
  CHECKLISTS_SELECTED_CHECKLIST_CLEAR = "checkListsSelectedCheckListClear",
  CHECKLISTS_FULL_LIST = "checkListsSelectedCheckListWithCriterionsList",
  CHECKLISTS_CATEGORIES_LIST_BY_SELECTED_CHECKLIST = "checkListsCategoriesListBySelectedCheckList",
  CHECKLISTS_CATEGORIES_LIST_BY_SELECTED_CHECKLIST_CLEAR = "checkListsCategoriesListBySelectedCheckListClear",
  CHECKLISTS_EDIT_CHECKLIST_CRITERIONS_LIST = "checkListsEditCheckListCriterionsList",
  CHECKLISTS_LIST_SIMPLE = "checkListsListSimple",
}
