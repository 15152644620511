import { ActionTree, ActionContext } from "vuex";
import { GlobalStateModel, IRootStateModel } from "../../../interfaces";
import { GlobalMutations } from "../mutations";
import { ActionTypes } from "./types";
import { MutationTypes } from "../mutations/types";
import { getClientInstance } from "@/services/client/clientProvider";
import { BaseClient } from "@/services/client/BaseClient";
import { getters } from "../getters";
import { state } from "../state";
import { StoreActionTypes } from "@/store/types";

type AugmentedActionGeneralContext = {
  commit<K extends keyof GlobalMutations>(
    key: K,
    payload: Parameters<GlobalMutations[K]>[1]
  ): ReturnType<GlobalMutations[K]>;
} & Omit<ActionContext<GlobalStateModel, IRootStateModel>, "commit">;

export interface GlobalActions {
  [ActionTypes.SET_SHOW_CONTENT](
    context: AugmentedActionGeneralContext,
    payload: boolean
  ): Promise<void>;
  [ActionTypes.SET_CONFIRM_MODAL](
    context: AugmentedActionGeneralContext,
    modal
  ): Promise<void>;
  [ActionTypes.GET_SAVED_FILTERS](
    context: AugmentedActionGeneralContext
  ): Promise<void>;
  [ActionTypes.SET_SAVED_FILTERS](
    context: AugmentedActionGeneralContext,
    filters
  ): Promise<void>;
}

export const actions: ActionTree<GlobalStateModel, IRootStateModel> &
  GlobalActions = {
  async [ActionTypes.SET_SHOW_CONTENT]({ commit }, payload: boolean) {
    if (process.env.NODE_ENV === "production") {
      commit(MutationTypes.SET_SHOW_CONTENT, payload);
    }
  },
  async [ActionTypes.SET_CONFIRM_MODAL]({ commit }, modal) {
    commit(MutationTypes.SET_CONFIRM_MODAL, modal);
  },
  async [ActionTypes.GET_SAVED_FILTERS]({ commit, dispatch }) {
    const client: BaseClient = getClientInstance();

    try {
      const response = await client.getSettings();

      if (!response || !response.filters) {
        commit(MutationTypes.SET_SAVED_FILTERS, []);
        return;
      }

      commit(MutationTypes.SET_SAVED_FILTERS, response.filters);
    } catch (error) {
      console.error("getSavedFilters", error);
    }
  },
  async [ActionTypes.SET_SAVED_FILTERS]({ commit }, filters) {
    const model = {
      filters: {
        ...state.filters,
        ...filters,
      },
    };

    const client: BaseClient = getClientInstance();

    try {
      const response = await client.setSettings(model);

      if (!response || !response.filters) {
        commit(MutationTypes.SET_SAVED_FILTERS, []);
        return;
      }

      commit(MutationTypes.SET_SAVED_FILTERS, response.filters);
    } catch (error) {
      console.error("getEmployeesList", error);
    }
  },
};
