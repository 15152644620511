import wretch from "wretch";
import store from "@/store";
import { StoreActionTypes } from "@/store/types";
import router from "@/router";

const baseApiUrl: string = process.env.VUE_APP_BASE_URL;

function getConfiguredClient(baseApiUrl: string, token: string) {
  return wretch(baseApiUrl)
    .errorType("json")
    .auth(`Bearer ${token}`)
    .resolve((r) => {
      r.unauthorized((e) => {
        logOut();
      });

      return r.json();
    });
}

let client = getConfiguredClient(baseApiUrl, ``);

export function setApiAuthToken(token: string) {
  client = getConfiguredClient(baseApiUrl, token);
}

export function getHttpClient() {
  return client;
}

function logOut() {
  store.dispatch(StoreActionTypes.LOG_OUT);
  if (
    (router as any).history &&
    (router as any).history.current.path == "/login"
  ) {
    return;
  }
  router.push("/login");
}
