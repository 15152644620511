import { MutationTree } from "vuex";
import { MutationTypes } from "./types";
import { IAuthentificationStateModel } from "./../../../interfaces";
import { Profile, LocaleType } from "@/services/types";

export type AuthentificationMutations<S = IAuthentificationStateModel> = {
  [MutationTypes.SET_AUTHENTIFICATION_DATA_LOADING](
    state: S,
    payload: boolean
  ): void;
  [MutationTypes.HAS_AUTHENTIFICATION_ERROR](state: S, payload: boolean): void;
  [MutationTypes.SET_AUTHENTIFICATION_PROFILE](
    state: S,
    payload: Profile | null
  ): void;
  [MutationTypes.SET_AUTHENTIFICATION_HELPER_TEXT](
    state: S,
    payload: string
  ): void;
  [MutationTypes.SET_AUTHENTIFICATION_AUTHENTICATED](
    state: S,
    payload: boolean
  ): void;
  [MutationTypes.SET_AUTHENTIFICATION_USER_LOCALE](
    state: S,
    payload: LocaleType
  ): void;
  [MutationTypes.SET_AUTHENTIFICATION_PASSWORD_HASH](
    state: S,
    payload: string
  ): void;
  [MutationTypes.SET_AUTHENTIFICATION_TUTORIAL_COMPLETED_STATUS](
    state: S,
    payload: boolean
  ): void;
  [MutationTypes.SET_AUTHENTIFICATION_SHOW_TUTORIAL_VIDEO](
    state: S,
    payload: boolean
  ): void;
};

export const mutations: MutationTree<IAuthentificationStateModel> &
  AuthentificationMutations = {
  [MutationTypes.SET_AUTHENTIFICATION_DATA_LOADING](state, payload: boolean) {
    state.dataIsLoading = payload;
  },
  [MutationTypes.HAS_AUTHENTIFICATION_ERROR](state, payload: boolean) {
    state.error = payload;
  },
  [MutationTypes.SET_AUTHENTIFICATION_PROFILE](state, payload: Profile) {
    // modify model
    const p = payload as any;
    if (p) {
      if (
        Object.hasOwn(p, `permissions`) &&
        typeof p.permissions === "object"
      ) {
        p.permissions = Object.entries(p.permissions).map(([k, v]) => {
          return v;
        });
      }
      if (Object.hasOwn(p, `roles`) && typeof p.roles === "object") {
        p.roles = Object.entries(p.roles).map(([k, v]) => {
          return v;
        });
      }
    }

    state.profile = payload;
  },
  [MutationTypes.SET_AUTHENTIFICATION_HELPER_TEXT](state, payload: string) {
    state.helperText = payload;
  },
  [MutationTypes.SET_AUTHENTIFICATION_AUTHENTICATED](state, payload: boolean) {
    state.authenticated = payload;
  },
  [MutationTypes.SET_AUTHENTIFICATION_USER_LOCALE](state, payload: LocaleType) {
    state.profile = {
      ...state.profile,
      locale: payload,
    };
  },
  [MutationTypes.SET_AUTHENTIFICATION_PASSWORD_HASH](state, payload: string) {
    state.passwordHash = payload;
  },
  [MutationTypes.SET_AUTHENTIFICATION_TUTORIAL_COMPLETED_STATUS](
    state,
    payload: boolean
  ) {
    state.profile.tutorialCompleted = payload;
  },
  [MutationTypes.SET_AUTHENTIFICATION_SHOW_TUTORIAL_VIDEO](
    state,
    payload: boolean
  ) {
    state.showTutorialVideo = payload;
  },
};
