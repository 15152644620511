import { GetterTree } from "vuex";
import {
  IDictionariesStateModel,
  IRootStateModel,
} from "./../../../interfaces";
import { Dictionary, LocaleType } from "@/services/types";

export type DictionariesGetters = {
  isDictionariesDataLoading(state: IDictionariesStateModel): boolean;
  getDictionariesLoadedLanguages(state: IDictionariesStateModel): LocaleType[];
  getDictionariesList(state: IDictionariesStateModel): Dictionary[];
};

export const getters: GetterTree<IDictionariesStateModel, IRootStateModel> &
  DictionariesGetters = {
  isDictionariesDataLoading: (state: IDictionariesStateModel) => {
    return state.dataIsLoading;
  },
  getDictionariesLoadedLanguages: (state: IDictionariesStateModel) => {
    return state.loadedLanguages;
  },
  getDictionariesList: (state: IDictionariesStateModel) => {
    return state.list;
  },
  checkDictionaries: (state: IDictionariesStateModel) => {
    return state.list.length > 0;
  },
};
