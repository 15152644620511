export enum ActionTypes {
  SET_REPORTS_LIST = "setReportsList",
  SET_REPORTS_LIST_FILTER = "setReportsListFilter",
  SET_REPORTS_HANDLE_LIST_ITEM_CLICK = "setReportsHandleListItemClick",
  SET_REPORTS_SELECTED_PROJECTS_LIST = "setReportsSelectedProjectsList",
  SET_REPORTS_RENDER_LIST = "setReportsRenderList",
  SET_REPORTS_PAGE = "setReportsPage",
  SET_REPORTS_LIST_CLEAR = "setReportsListClear",
  SET_REPORTS_SELECTED_MANAGER = "setReportsSelectedManager",
  SET_REPORTS_SELECTED_CRITERION = "setReportsSelectedCriterion",
  SET_REPORTS_LIST_FILTER_SELECTED_MANAGER = "setReportsListFilterSelectedManager",
  SET_REPORTS_LIST_FILTER_SELECTED_CRITERION = "setReportsListFilterSelectedCriterion",
  SET_REPORTS_DATE_INTERVAL = "setReportsDateInterval",
  SET_REPORTS_CALLS_LIST = "setReportsCallsList",
  SET_REPORTS_CALLS_LIST_SORT = "setReportsCallsListSort",
}
