export enum ActionTypes {
  SET_PROJECT_INTEGRATION_PROJECT_CREATE = "setProjectIntegrationProjectCreate",
  SET_PROJECT_INTEGRATION_INTEGRATIONS_GET = "setProjectIntegrationIntegrationsGet",
  SET_PROJECT_INTEGRATION_INTEGRATION_CHANGE_STATUS = "setProjectIntegrationIntegrationChangeStatus",
  SET_PROJECT_INTEGRATION_INTEGRATIONS_DELETE = "setProjectIntegrationIntegrationDelete",
  SET_PROJECT_INTEGRATION_INTEGRATION_DETAILS_GET = "setProjectIntegrationIntegrationDetailsGet",
  SET_PROJECT_INTEGRATION_INTEGRATION_DETAILS_CLEAR = "setProjectIntegrationIntegrationDetailsClear",
  SET_PROJECT_INTEGRATION_INTEGRATION_ID = "setProjectIntegrationIntegrationId",
  SET_PROJECT_INTEGRATION_FUNNELS_LIST_SET = "setProjectIntegrationFunnelsListSet",
  SET_PROJECT_INTEGRATION_FUNNELS_LIST_GET = "setProjectIntegrationFunnelsListGet",
  SET_PROJECT_INTEGRATION_INTEGRATION_CLEAR = "setProjectIntegrationIntegrationClear",
  SET_PROJECT_INTEGRATION_INTEGRATIONS_LIST_CLEAR = "setProjectIntegrationIntegrationsListClear",
  SET_PROJECT_INTEGRATION_BITRIX_ADD = "setProjectIntegrationBitrixAdd",
}
