import { ISelectedCallStateModel } from "./../../interfaces";

export const defaultState: ISelectedCallStateModel = {
  dataIsLoading: false,
  selectedCall: null,
  categories: [],
  estimatedCheckList: null,
  checklistId: null,
  callRated: false,
  selectedAuditorId: null,
  currentEstimation: [],
  allCriterionsChecked: false,
  availableManagersList: [],
  topCollapseOpened: true,
};

export const state: ISelectedCallStateModel = {
  ...defaultState,
};
