import { MutationTree } from "vuex";
import { MutationTypes } from "./types";
import { IProjectsStateModel } from "./../../../interfaces";
import {
  BaseEntityModel,
  BaseProjectModel,
  ProjectLimitsModel,
  ProjectModel,
  ProjectsListFilterModel,
  ProjectsListSortModel,
  SubscriptionItemDataModel,
} from "@/services/types";

export type ProjectsMutations<S = IProjectsStateModel> = {
  [MutationTypes.SET_PROJECTS_LIST](
    state: S,
    payload: BaseProjectModel[]
  ): void;
  [MutationTypes.SET_PROJECTS_FULL_LIST](
    state: S,
    payload: BaseProjectModel[]
  ): void;
  [MutationTypes.SET_PROJECTS_LIST_PAGE](state: S, payload: number): void;
  [MutationTypes.SET_PROJECTS_LIST_PER_PAGE](state: S, payload: number): void;
  [MutationTypes.SET_PROJECTS_TOTAL](state: S, payload: number): void;
  [MutationTypes.SET_PROJECTS_DATA_IS_LOADING](
    state: S,
    payload: boolean
  ): void;
  [MutationTypes.SET_PROJECTS_SORT_VALUE](
    state: S,
    payload: ProjectsListSortModel
  ): void;
  [MutationTypes.SET_PROJECTS_FILTER_VALUE](
    state: S,
    payload: ProjectsListFilterModel
  ): void;
  [MutationTypes.SET_PROJECTS_SELECTED_PROJECT](
    state: S,
    payload: ProjectModel
  ): void;
  [MutationTypes.SET_PROJECTS_SELECTED_PROJECT_CHECKLIST](
    state: S,
    payload: BaseEntityModel[]
  ): void;
  [MutationTypes.SET_PROJECTS_LIMITS](
    state: S,
    payload: ProjectLimitsModel
  ): void;
};

export const mutations: MutationTree<IProjectsStateModel> & ProjectsMutations =
  {
    [MutationTypes.SET_PROJECTS_LIST](state, payload: BaseProjectModel[]) {
      state.list = payload;
    },
    [MutationTypes.SET_PROJECTS_FULL_LIST](state, payload: BaseProjectModel[]) {
      state.fullList = payload;
    },
    [MutationTypes.SET_PROJECTS_LIST_PAGE](state, payload: number) {
      state.page = payload;
    },
    [MutationTypes.SET_PROJECTS_LIST_PER_PAGE](state, payload: number) {
      state.perPage = payload;
    },
    [MutationTypes.SET_PROJECTS_TOTAL](state, payload: number) {
      state.total = payload;
    },
    [MutationTypes.SET_PROJECTS_DATA_IS_LOADING](state, payload: boolean) {
      state.dataIsLoading = payload;
    },
    [MutationTypes.SET_PROJECTS_SORT_VALUE](
      state,
      payload: ProjectsListSortModel
    ) {
      state.sort = payload;
    },
    [MutationTypes.SET_PROJECTS_FILTER_VALUE](
      state,
      payload: ProjectsListFilterModel
    ) {
      state.filter = payload;
    },
    [MutationTypes.SET_PROJECTS_SELECTED_PROJECT](
      state,
      payload: ProjectModel
    ) {
      state.selectedProject = payload;
    },
    [MutationTypes.SET_PROJECTS_SELECTED_PROJECT_CHECKLIST](
      state,
      payload: BaseEntityModel[]
    ) {
      state.selectedProjectCheckLists = payload;
    },
    [MutationTypes.SET_PROJECTS_LIMITS](state, payload: ProjectLimitsModel) {
      state.limits = payload;
    },
  };
