import { IProjectIntegrationStateModel } from "./../../interfaces";

export const state: IProjectIntegrationStateModel = {
  projectIntegration: null,
  funnelsList: null,
  selectedProjectIntegrationsList: [],
  selectedProjectIntegration: null,
  dataIsLoading: false,
  callsListIsLoading: false,
  projectIntegrationType: null,
  bitrixIntegration: null,
};
