import { ActionTree, ActionContext } from "vuex";
import { ISubscriptionStateModel, IRootStateModel } from "../../../interfaces";
import { SubscriptionMutations } from "./../mutations";
import { ActionTypes } from "./types";
import { MutationTypes } from "./../mutations/types";
import { SubscriptionModel } from "./../../../../services/types";
import { BaseClient } from "./../../../../services/client/BaseClient";
import { getClientInstance } from "./../../../..//services/client/clientProvider";

type AugmentedActionSubscriptionContext = {
  commit<K extends keyof SubscriptionMutations>(
    key: K,
    payload: Parameters<SubscriptionMutations[K]>[1]
  ): ReturnType<SubscriptionMutations[K]>;
} & Omit<ActionContext<ISubscriptionStateModel, IRootStateModel>, "commit">;

export interface SubscriptionActions {
  [ActionTypes.SET_SUBSCRIPTION_DATA](
    context: AugmentedActionSubscriptionContext
  ): Promise<void>;
}

export const actions: ActionTree<ISubscriptionStateModel, IRootStateModel> &
  SubscriptionActions = {
  async [ActionTypes.SET_SUBSCRIPTION_DATA]({ commit }) {
    const client: BaseClient = getClientInstance();

    commit(MutationTypes.SET_SUBSCRIPTION_DATA_IS_LOADING, true);

    try {
      const response: SubscriptionModel = await client.getSubscription();

      commit(MutationTypes.SET_SUBSCRIPTION_DATA, response);
    } catch (error) {
      console.error("getSubscription", error);
    } finally {
      commit(MutationTypes.SET_SUBSCRIPTION_DATA_IS_LOADING, false);
    }
  },
};
