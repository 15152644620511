export enum ActionTypes {
  SET_PROJECTS_LIST = "setProjectsList",
  SET_PROJECTS_LIST_CARDS = "setProjectsListCards",
  SET_PROJECTS_LIST_CALLS = "setProjectsListCalls",
  SET_PROJECTS_FULL_LIST = "setProjectsFullList",
  SET_PROJECTS_LIST_CLEAR = "setProjectsListClear",
  SET_PROJECTS_LIST_PAGE = "setProjectsListPage",
  SET_PROJECTS_LIST_PER_PAGE = "setProjectsListPerPage",
  SET_PROJECTS_LIST_SORT = "setProjectsListSort",
  SET_PROJECTS_LIST_FILTER = "setProjectsListFilter",
  SET_PROJECTS_PROJECT_DELETE = "setProjectsProjectDelete",
  SET_PROJECTS_SELECTED_PROJECT = "setProjectsSelectedProject",
  SET_PROJECTS_SELECTED_PROJECT_CLEAR = "setProjectsSelectedProjectClear",
  SET_PROJECTS_PROJECT_CHANGE_NAME = "setProjectsProjectChangeName",
  SET_PROJECTS_SELECTED_PROJECT_CHECKLIST_GET = "setProjectsSelectedProjectChecklistGet",
  SET_PROJECTS_SELECTED_PROJECT_UPDATE_CHECKLIST = "setProjectsSelectedProjectUpdateCheckList",
  SET_PROJECTS_LIMITS = "setProjectsLimits",
}
