import { MutationTree } from "vuex";
import { MutationTypes } from "./types";
import { ISubscriptionStateModel } from "./../../../interfaces";
import { SubscriptionModel } from "@/services/types";

export type SubscriptionMutations<S = ISubscriptionStateModel> = {
  [MutationTypes.SET_SUBSCRIPTION_DATA_IS_LOADING](
    state: S,
    payload: boolean
  ): void;
  [MutationTypes.SET_SUBSCRIPTION_DATA](
    state: S,
    payload: SubscriptionModel
  ): void;
};

export const mutations: MutationTree<ISubscriptionStateModel> &
  SubscriptionMutations = {
  [MutationTypes.SET_SUBSCRIPTION_DATA_IS_LOADING](state, payload: boolean) {
    state.dataIsLoading = payload;
  },
  [MutationTypes.SET_SUBSCRIPTION_DATA](state, payload: SubscriptionModel) {
    state.subscription = payload;
  },
};
