import { getFormattedDate } from "@/services/utils";
import { ReportsListDateFilterModel } from "@/services/types/reports";
import moment from "moment/moment";

export const REPORTS_DEFAULT_DATE_INTERVAL = 7;
export const REPORTS_NONE_DATE_INTERVAL = 0;

export const dateIntervals = [
  {
    name: "today",
    value: 1,
  },
  {
    name: "yesterday",
    value: -1,
  },
  {
    name: "week",
    value: 7,
  },
  {
    name: "month",
    value: 30,
  },
  {
    name: "quarter",
    value: 90,
  },
  {
    name: "year",
    value: 365,
  },
];

export function getDateFilterFromInterval(
  interval: number
): ReportsListDateFilterModel {
  const date = moment();

  switch (interval) {
    case -1:
      date.subtract(2, "day");
      break;
    case 7:
      date.subtract(7, "day");
      break;
    case 30:
      date.subtract(1, "month");
      break;
    case 90:
      date.subtract(4, "month");
      break;
    case 365:
      date.subtract(1, "year");
      break;
    case 1:
    default:
      date.subtract(1, "day");
      break;
  }

  return {
    from: getFormattedDate(date.toDate()),
    to: getFormattedDate(moment().toDate()),
  };
}
