import {
  BaseResponseModel,
  LoginWithPasswordRequestModel,
  LoginWithPasswordResponseModel,
  GetDictionaryRequestModel,
  GetDictionaryResponseModel,
  CategoriesListRequestModel,
  CategoriesListResponseModel,
  CreateCategoryRequestModel,
  ChangeCategoryRequestModel,
  ChangeCategoryStatusRequestModel,
  CreateCategoryResponseModel,
  ChangeCategoryResponseModel,
  ChangeCategoryStatusResponseModel,
  DeleteCategoriesListRequestModel,
  GetCategoryResponseModel,
  GetCategoryWithCriterionsResponseModel,
  CriterionsListByCategoryIdResponseModel,
  ChangeCriterionStatusRequestModel,
  ChangeCriterionStatusResponseModel,
  DeleteCriterionsListRequestModel,
  CreateCriterionRequestModel,
  CreateCriterionResponseModel,
  EditCriterionRequestModel,
  EditCriterionResponseModel,
  GetCriterionResponseModel,
  CriterionsListRequestModel,
  CriterionsListResponseModel,
  CategoriesFullListResponseModel,
  CheckListsListRequestModel,
  CheckListsListResponseModel,
  CreateCheckListRequestModel,
  CreateCheckListResponseModel,
  ChangeCheckListNameRequestModel,
  ChangeCheckListNameResponseModel,
  DeleteCheckListRequestModel,
  GetCheckListResponseModel,
  GetCheckListWithCriterionsResponseModel,
  CriterionsListByCheckListIdResponseModel,
  CategoriesWithCriterionsListByCheckListIdResponseModel,
  EditRecordIdListRequestModel,
  CallsListRequestModel,
  CallsListResponseModel,
  CallDetailsResponseModel,
  CallCriterionsListResponseModel,
  CallEstimateRequestModel,
  CallEstimatedCriterionsListResponseModel,
  ProjectsListRequestModel,
  ProjectsListResponseModel,
  ProjectsDetailsResponseModel,
  DeleteProjectsListRequestModel,
  CheckListWithCategoriesSimpleModelListResponse,
  CreateNewProjectRequestModel,
  CreateNewProjectResponseModel,
  GetProjectsFunnelsListModelRequestModel,
  GetProjectsFunnelsListModelResponseModel,
  SetProjectsFunnelsListModelRequestModel,
  SetProjectsFunnelsListModelResponseModel,
  GetReportsRequest,
  GetReportsResponse,
  BaseEntityModelsListResponse,
  GetCommentsListResponseModel,
  CreateCommentRequestModel,
  CommentModel,
  EditCommentRequestModel,
  DeleteCommentsListRequest,
  EditProjectRequestModel,
  EditProjectResponseModel,
  GetProjectCheckListResponseModel,
  GetProjectIntegrationsListResponseModel,
  ChangeProjectIntegrationStatusRequestModel,
  ChangeProjectIntegrationStatusResponseModel,
  DeleteProjectIntegrationsRequestModel,
  GetProjectIntegrationDetailsResponseModel,
  GetReportCallsListResponseModel,
  GetReportCallsListRequestModel,
  UpdateProjectCheckListsRequestModel,
  CreateEmployeeRequestModel,
  CreateEmployeeResponseModel,
  DeleteEmployeesListRequestModel,
  ChangeEmployeeStatusResponseModel,
  ChangeEmployeeStatusRequestModel,
  CompanyEmployeesListResponseModel,
  ChangeEmployeePasswordRequestModel,
  GetProjectsPhonesResponseModel,
  SubscriptionModel,
  SubscriptionItemDataModel,
  SetCallManagerRequestModel,
  CategoryModel,
  CategoryRenderModel,
  ProjectLimitsModel,
  GetCardListRequestModel,
  GetCardListResponseModel,
  GetProjectCardManagersResponseModel,
  GetAvailableUserRolesResponseModel,
  UpdateUserRequestModel,
  GetAvailableUserRolesRequestModel,
} from "../types";
import { BaseEntityModel } from "../types/base";
import { ResetPasswordModel, SetPasswordModel } from "../types/profile";

import { Storage } from "./../storage/Storage";
import store from "./../../store";
import router from "./../../router";
import { StoreActionTypes } from "@/store/types";
import {
  EwaCompany,
  EwaInstance,
  EwaInstanceQr,
  EwaInstanceSettings,
  EwaOrder,
  EwaTariff,
  EwaUser,
} from "../types/ewa";
import { showErrorMessage } from "@/services/ui/messages";
import {
  CreateSubCompanyRequestModel,
  RemoveSubCompaniesRequestModel,
  UpdateSubCompanyRequestModel,
} from "@/services/types/subCompanies";
import { EntitiesListRequestDto } from "@/services/api/entities/types";

declare const window: any;

export abstract class BaseClient {
  public abstract loginWithPassword(
    model: LoginWithPasswordRequestModel
  ): Promise<LoginWithPasswordResponseModel>;

  public abstract resetPassword(
    model: ResetPasswordModel
  ): Promise<BaseResponseModel>;

  public abstract setPassword(
    model: SetPasswordModel
  ): Promise<BaseResponseModel>;

  public abstract setOnboardingStatus(): Promise<BaseResponseModel>;

  public abstract getSubscription(): Promise<SubscriptionModel>;

  public abstract getDictionary(
    model: GetDictionaryRequestModel
  ): Promise<GetDictionaryResponseModel>;

  public abstract getCategoriesList(
    model: CategoriesListRequestModel
  ): Promise<CategoriesListResponseModel>;

  public abstract getCategoriesFullList(): Promise<CategoriesFullListResponseModel>;

  public abstract getCategoriesWithCriterionsFullList(): Promise<any>;

  public abstract changeCategoryStatus(
    id: number,
    model: ChangeCategoryStatusRequestModel
  ): Promise<ChangeCategoryStatusResponseModel>;

  public abstract deleteCategoriesList(
    model: DeleteCategoriesListRequestModel
  ): Promise<BaseResponseModel>;

  public abstract createCategory(
    model: CreateCategoryRequestModel
  ): Promise<CreateCategoryResponseModel>;

  public abstract changeCategory(
    id: number,
    model: ChangeCategoryRequestModel
  ): Promise<ChangeCategoryResponseModel>;

  public abstract getCategory(id: number): Promise<GetCategoryResponseModel>;

  public abstract getCategoryWithCriterions(
    id: number
  ): Promise<CategoryModel[]>;

  public abstract getCriterionsListByCategoryId(
    id: number
  ): Promise<CategoryModel[]>;

  public abstract changeCriterionStatus(
    id: number,
    model: ChangeCriterionStatusRequestModel
  ): Promise<ChangeCriterionStatusResponseModel>;

  public abstract deleteCriterionsList(
    model: DeleteCriterionsListRequestModel
  ): Promise<BaseResponseModel>;

  public abstract createCriterion(
    model: CreateCriterionRequestModel
  ): Promise<CreateCriterionResponseModel>;

  public abstract editCriterion(
    id: number,
    model: EditCriterionRequestModel
  ): Promise<EditCriterionResponseModel>;

  public abstract getCriterion(id: number): Promise<GetCriterionResponseModel>;

  public abstract getCriterionsList(
    model: CriterionsListRequestModel
  ): Promise<CriterionsListResponseModel>;

  public abstract getCheckListsList(
    model: CheckListsListRequestModel
  ): Promise<CheckListsListResponseModel>;

  public abstract getCheckList(id: number): Promise<GetCheckListResponseModel>;

  public abstract createCheckList(
    model: CreateCheckListRequestModel
  ): Promise<CreateCheckListResponseModel>;

  public abstract changeCheckListName(
    id: number,
    model: ChangeCheckListNameRequestModel
  ): Promise<ChangeCheckListNameResponseModel>;

  public abstract deleteCheckListsList(
    model: DeleteCheckListRequestModel
  ): Promise<BaseResponseModel>;

  public abstract getCheckListWithCriterions(
    id: number
  ): Promise<GetCheckListWithCriterionsResponseModel>;

  public abstract getCriterionsListByCheckListId(
    id: number
  ): Promise<CriterionsListByCheckListIdResponseModel>;

  public abstract getCategoriesWithCriterionsListByCheckListId(
    id: number
  ): Promise<CategoriesWithCriterionsListByCheckListIdResponseModel>;

  public abstract editCheckListCriterions(
    id: number,
    model: EditRecordIdListRequestModel
  ): Promise<BaseResponseModel>;

  public abstract getCallsList(
    model: EntitiesListRequestDto
  ): Promise<CallsListResponseModel>;

  public abstract getCallDetails(id: number): Promise<CallDetailsResponseModel>;

  public abstract getCallCriterionsList(
    id: number
  ): Promise<CallCriterionsListResponseModel>;

  public abstract setCallEstimation(
    id: number,
    model: CallEstimateRequestModel
  ): Promise<CallEstimatedCriterionsListResponseModel>;

  public abstract editCallEstimation(
    id: number,
    model: CallEstimateRequestModel
  ): Promise<CallEstimatedCriterionsListResponseModel>;

  public abstract setCallManager(
    id: number,
    model: SetCallManagerRequestModel
  ): Promise<BaseResponseModel>;

  public abstract getCallEstimation(
    id: number
  ): Promise<CallEstimatedCriterionsListResponseModel>;

  public abstract getProjectsList(
    model: ProjectsListRequestModel
  ): Promise<ProjectsListResponseModel>;

  public abstract getProjectsFullList(): Promise<ProjectsListResponseModel>;

  public abstract getProjectsLimits(): Promise<SubscriptionItemDataModel>;

  public abstract getProjectDetails(
    id: number
  ): Promise<ProjectsDetailsResponseModel>;

  public abstract changeProjectName(
    id: number,
    model: EditProjectRequestModel
  ): Promise<EditProjectResponseModel>;

  public abstract deleteProjectsList(
    model: DeleteProjectsListRequestModel
  ): Promise<BaseResponseModel>;

  public abstract getProjectChecklists(
    id: number
  ): Promise<GetProjectCheckListResponseModel>;

  public abstract getProjectIntegrations(
    id: number
  ): Promise<GetProjectIntegrationsListResponseModel>;

  public abstract getProjectIntegrationDetails(
    id: number
  ): Promise<GetProjectIntegrationDetailsResponseModel>;

  public abstract changeProjectIntegrationStatus(
    projectId: number,
    integrationId: number,
    model: ChangeProjectIntegrationStatusRequestModel
  ): Promise<ChangeProjectIntegrationStatusResponseModel>;

  public abstract deleteProjectIntegrations(
    projectId: number,
    model: DeleteProjectIntegrationsRequestModel
  ): Promise<BaseResponseModel>;

  public abstract getCheckListWithCategoriesSimpleList(): Promise<CheckListWithCategoriesSimpleModelListResponse>;

  public abstract createProject(
    model: CreateNewProjectRequestModel
  ): Promise<CreateNewProjectResponseModel>;

  public abstract getProjectsFunnelsList(
    model: GetProjectsFunnelsListModelRequestModel
  ): Promise<GetProjectsFunnelsListModelResponseModel>;

  public abstract setProjectsFunnelsList(
    projectId: number,
    integrationId: number,
    model: SetProjectsFunnelsListModelRequestModel
  ): Promise<SetProjectsFunnelsListModelResponseModel>;

  public abstract updateProjectCheckLists(
    projectId: number,
    model: UpdateProjectCheckListsRequestModel
  ): Promise<BaseResponseModel>;

  public abstract getManagersReports(
    model: GetReportsRequest
  ): Promise<GetReportsResponse>;

  public abstract getCriterionsReports(
    model: GetReportsRequest
  ): Promise<GetReportsResponse>;

  public abstract getManagersSimpleList(): Promise<BaseEntityModelsListResponse>;

  public abstract getProjectAvailableManagersList(
    projectId: number
  ): Promise<BaseEntityModelsListResponse>;

  public abstract getProjectsSimpleList(): Promise<BaseEntityModelsListResponse>;

  public abstract getProjectsPhonesList(): Promise<GetProjectsPhonesResponseModel>;

  public abstract getCommentsList(
    callId: number
  ): Promise<GetCommentsListResponseModel>;

  public abstract createComment(
    callId: number,
    model: CreateCommentRequestModel
  ): Promise<CommentModel>;

  public abstract editComment(
    callId: number,
    commentId: number,
    model: EditCommentRequestModel
  ): Promise<CommentModel>;

  public abstract deleteCommentsList(
    callId: number,
    model: DeleteCommentsListRequest
  ): Promise<BaseResponseModel>;

  public abstract getManagerReports(
    managerId: number,
    model: GetReportsRequest
  ): Promise<GetReportsResponse>;

  public abstract getCriterionReports(
    criterionId: number,
    model: GetReportsRequest
  ): Promise<GetReportsResponse>;

  public abstract getManagerBase(managerId: number): Promise<BaseEntityModel>;

  public abstract getCriterionBase(
    criterionId: number
  ): Promise<BaseEntityModel>;

  public abstract getReportCallsList(
    model: GetReportCallsListRequestModel
  ): Promise<GetReportCallsListResponseModel>;

  public abstract createEmployee(
    model: CreateEmployeeRequestModel
  ): Promise<CreateEmployeeResponseModel>;

  public abstract deleteEmployeesList(
    model: DeleteEmployeesListRequestModel
  ): Promise<BaseResponseModel>;

  public abstract changeEmployeeStatus(
    id: number,
    model: ChangeEmployeeStatusRequestModel
  ): Promise<ChangeEmployeeStatusResponseModel>;

  public abstract getEmployeesList(): Promise<CompanyEmployeesListResponseModel>;

  public abstract changeEmployeePassword(
    id: number,
    model: ChangeEmployeePasswordRequestModel
  ): Promise<BaseResponseModel>;

  public abstract bitrixIntegrationAdd(
    projectId: number,
    webhookUrl: string
  ): Promise<BaseResponseModel>;

  public abstract setSettings(filters);

  public abstract getSettings();

  public abstract getAllInstances(): Promise<EwaInstance[]>;

  public abstract getInstances(companyId?: number): Promise<EwaInstance[]>;

  public abstract addInstance(
    idTariff?: number,
    couponCode?: string
  ): Promise<EwaInstance>;

  public abstract deleteInstance(
    idInstance: number
  ): Promise<BaseResponseModel>;

  public abstract renewInstance(
    idInstance: number,
    countDays?: number
  ): Promise<BaseResponseModel>;

  public abstract getUserInstances(): Promise<EwaInstance[]>;

  public abstract getInstanceSettings(
    idInstance: number
  ): Promise<EwaInstanceSettings>;

  public abstract setInstanceSettings(
    idInstance: number,
    settings: EwaInstanceSettings
  ): Promise<BaseResponseModel>;

  public abstract getInstanceQr(idInstance: number): Promise<EwaInstanceQr>;

  public abstract logoutInstance(
    idInstance: number
  ): Promise<BaseResponseModel>;

  public abstract getTariffs(couponCode?: string): Promise<EwaTariff[]>;

  public abstract addTariff(data: EwaTariff): Promise<BaseResponseModel>;

  public abstract removeTariff(id): Promise<BaseResponseModel>;

  public abstract getOrder(): Promise<EwaUser>;

  public abstract setOrder(
    data: EwaOrder
  ): Promise<{ result: string; invoice_id: number }>;

  public abstract getBalance(): Promise<{ waBalance: number }>;

  public abstract getCardsList(
    payload: GetCardListRequestModel
  ): Promise<GetCardListResponseModel>;

  public abstract getProjectCardManagers(
    projectId: number
  ): Promise<GetProjectCardManagersResponseModel>;

  public abstract getAvailableUserRoles(
    payload?: GetAvailableUserRolesRequestModel
  ): Promise<GetAvailableUserRolesResponseModel>;

  public abstract getUserSelf(): Promise<any>;

  public abstract updateUser(payload: UpdateUserRequestModel): Promise<void>;

  public abstract updateSubCompany(
    payload: UpdateSubCompanyRequestModel
  ): Promise<void>;

  public abstract removeSubCompanies(
    payload: RemoveSubCompaniesRequestModel
  ): Promise<void>;

  public abstract createSubCompany(
    payload: CreateSubCompanyRequestModel
  ): Promise<void>;

  protected readonly parseResponse = (response: Response) => {
    if (response.ok) {
      return response.json();
    } else {
      switch (response.status) {
        case 403:
          showErrorMessage(`У вас недостаточно прав.`);
          store.dispatch(StoreActionTypes.SET_SHOW_CONTENT, false);
          break;
        case 401:
          window.error(
            "Проверьте правильность введенных данных",
            "Ошибка авторизации"
          );
          this.logOut();
          setTimeout(() => {
            console.clear();
          }, 100);
          break;
        default:
          showErrorMessage(`${response.statusText}`);
          throw { status: response.status, message: response.statusText };
      }
    }
  };

  private readonly logOut = () => {
    store.dispatch(StoreActionTypes.LOG_OUT);
    if (
      (router as any).history &&
      (router as any).history.current.path == "/login"
    ) {
      return;
    }
    router.push("/login");
  };
}
