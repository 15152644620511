export enum ActionTypes {
  LOGIN_WITH_PASSWORD = "loginWithPassword",
  LOG_OUT = "logout",
  CHECK_IS_LOGGED_IN = "checkIsLoggedIn",
  RESET_PASSWORD = "resetPassword",
  SET_PASSWORD = "setPassword",
  SET_PASSWORD_HASH = "setPasswordHash",
  SET_AUTHENTIFICATION_TUTORIAL_COMPLETED_STATUS = "setAuthentificationTutorialCompletedStatus",
  SET_AUTHENTIFICATION_SHOW_TUTORIAL_VIDEO = "setAuthentificationShowTutorialVideo",
}
