import { MutationTree } from "vuex";
import { MutationTypes } from "./types";
import { IEmployeesStateModel } from "./../../../interfaces";
import {
  CompanyEmployeeModel,
  CompanyEmployeesListFilterModel,
} from "@/services/types";

export type EmployeesMutations<S = IEmployeesStateModel> = {
  [MutationTypes.SET_EMPLOYEES_LIST](
    state: S,
    payload: CompanyEmployeeModel[]
  ): void;
  [MutationTypes.SET_EMPLOYEES_DATA_IS_LOADING](
    state: S,
    payload: boolean
  ): void;
  [MutationTypes.SET_EMPLOYEES_FILTER_VALUE](
    state: S,
    payload: CompanyEmployeesListFilterModel
  ): void;
  [MutationTypes.SET_EMPLOYEES_ERROR_MESSAGE](state: S, payload: string): void;
};

export const mutations: MutationTree<IEmployeesStateModel> &
  EmployeesMutations = {
  [MutationTypes.SET_EMPLOYEES_LIST](state, payload: CompanyEmployeeModel[]) {
    state.list = payload;
  },
  [MutationTypes.SET_EMPLOYEES_DATA_IS_LOADING](state, payload: boolean) {
    state.dataIsLoading = payload;
  },
  [MutationTypes.SET_EMPLOYEES_FILTER_VALUE](
    state,
    payload: CompanyEmployeesListFilterModel
  ) {
    state.filter = payload;
  },
  [MutationTypes.SET_EMPLOYEES_ERROR_MESSAGE](state, payload: string) {
    state.error = payload;
  },
};
