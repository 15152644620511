import { Module } from "vuex";
import { state } from "./state";
import { GlobalStateModel, IRootStateModel } from "../../interfaces";
import { mutations } from "./mutations";
import { getters } from "./getters";
import { actions } from "./actions";

const general: Module<GlobalStateModel, IRootStateModel> = {
  state,
  mutations,
  getters,
  actions,
};

export default general;
