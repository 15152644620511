import { MutationTree } from "vuex";
import { MutationTypes } from "./types";
import { ICategoriesStateModel } from "./../../../interfaces";
import {
  CategoryModel,
  CategoriesListSortModel,
  CategoriesListFilterModel,
  BaseEntityModel,
} from "@/services/types";

export type CategoriesMutations<S = ICategoriesStateModel> = {
  [MutationTypes.SET_CATEGORIES_LIST](state: S, payload: CategoryModel[]): void;
  [MutationTypes.SET_CATEGORIES_FULL_LIST](
    state: S,
    payload: BaseEntityModel[]
  ): void;
  [MutationTypes.SET_CATEGORIES_WITH_CRITERIONS_FULL](
    state: S,
    payload: any[]
  ): void;
  [MutationTypes.SET_CATEGORIES_LIST_PAGE](state: S, payload: number): void;
  [MutationTypes.SET_CATEGORIES_LIST_PER_PAGE](state: S, payload: number): void;
  [MutationTypes.SET_CATEGORIES_TOTAL](state: S, payload: number): void;
  [MutationTypes.SET_CATEGORIES_DATA_IS_LOADING](
    state: S,
    payload: boolean
  ): void;
  [MutationTypes.SET_CATEGORIES_SORT_VALUE](
    state: S,
    payload: CategoriesListSortModel
  ): void;
  [MutationTypes.SET_CATEGORIES_FILTER_VALUE](
    state: S,
    payload: CategoriesListFilterModel
  ): void;
  [MutationTypes.SET_CATEGORIES_SELECTED_CATEGORY](
    state: S,
    payload: CategoryModel
  ): void;
};

export const mutations: MutationTree<ICategoriesStateModel> &
  CategoriesMutations = {
  [MutationTypes.SET_CATEGORIES_LIST](state, payload: CategoryModel[]) {
    state.list = payload;
  },
  [MutationTypes.SET_CATEGORIES_FULL_LIST](state, payload: BaseEntityModel[]) {
    state.fullList = payload;
  },
  [MutationTypes.SET_CATEGORIES_WITH_CRITERIONS_FULL](state, payload: any[]) {
    state.withCriterionsFullList = payload;
  },
  [MutationTypes.SET_CATEGORIES_LIST_PAGE](state, payload: number) {
    state.page = payload;
  },
  [MutationTypes.SET_CATEGORIES_LIST_PER_PAGE](state, payload: number) {
    state.perPage = payload;
  },
  [MutationTypes.SET_CATEGORIES_TOTAL](state, payload: number) {
    state.total = payload;
  },
  [MutationTypes.SET_CATEGORIES_DATA_IS_LOADING](state, payload: boolean) {
    state.dataIsLoading = payload;
  },
  [MutationTypes.SET_CATEGORIES_SORT_VALUE](
    state,
    payload: CategoriesListSortModel
  ) {
    state.sort = payload;
  },
  [MutationTypes.SET_CATEGORIES_FILTER_VALUE](
    state,
    payload: CategoriesListFilterModel
  ) {
    state.filter = payload;
  },
  [MutationTypes.SET_CATEGORIES_SELECTED_CATEGORY](
    state,
    payload: CategoryModel
  ) {
    state.selectedCategory = payload;
  },
};
