import Vue from "vue";
import Vuex, { Module, ModuleTree } from "vuex";
import { IRootStateModel } from "./interfaces";
import authentification from "./modules/authentification";
import player from "./modules/player";
import comments from "./modules/comments";
import dictionaries from "./modules/dictionaries";
import categories from "./modules/categories";
import criterions from "./modules/criterions";
import checkLists from "./modules/checkLists";
import calls from "./modules/calls";
import reports from "./modules/reports";
import projects from "./modules/projects";
import employees from "./modules/employees";
import selectedCall from "./modules/selectedCall";
import projectIntegration from "./modules/projectIntegration";
import subscription from "./modules/subscriptions";
import general from "./modules/general";
import ewa from "./modules/ewa";
import cards from "@/store/modules/cards";
import reports2 from "@/store/modules/reports2";

Vue.use(Vuex);

const modules: ModuleTree<IRootStateModel> = {
  authentification,
  player,
  comments,
  dictionaries,
  categories,
  criterions,
  checkLists,
  calls,
  reports,
  reports2,
  projects,
  employees,
  selectedCall,
  projectIntegration,
  subscription,
  general,
  ewa,
  cards,
};

const root: Module<IRootStateModel, IRootStateModel> = {
  modules,
};

export default new Vuex.Store(root);
