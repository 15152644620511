import { GetterTree } from "vuex";
import { ICriterionsStateModel, IRootStateModel } from "./../../../interfaces";
import {
  CriterionModel,
  CriterionsListFilterModel,
  StatusType,
  CriterionTypeCode,
  CategoryModel,
  CategoryRenderModel,
} from "@/services/types";

export type CriterionsGetters = {
  getCriterionsList(state: ICriterionsStateModel): CategoryRenderModel[];
  getCriterionsTotal(state: ICriterionsStateModel): number;
  getCriterionsListPage(state: ICriterionsStateModel): number;
  getCriterionsListPerPage(state: ICriterionsStateModel): number;
  isCriterionsDataLoading(state: ICriterionsStateModel): boolean;
  getCriterionsFilterValue(
    state: ICriterionsStateModel
  ): CriterionsListFilterModel;
  getCriterionsSelectedCriterion(state: ICriterionsStateModel): CriterionModel;
  getCriterionsFilteredList(state: ICriterionsStateModel): CriterionModel[];
};

const filterByName = (
  search: string,
  list: CriterionModel[]
): CriterionModel[] => {
  if (search.length >= 2) {
    return list.filter((item) => {
      return (
        item.name.trim().toLowerCase().indexOf(search.trim().toLowerCase()) >= 0
      );
    });
  } else {
    return list;
  }
};

const filterByStatus = (
  status: StatusType,
  list: CriterionModel[]
): CriterionModel[] => {
  if (status !== null) {
    return list.filter((item) => item.status === status);
  } else {
    return list;
  }
};

const filterByType = (
  typeCode: CriterionTypeCode,
  list: CriterionModel[]
): CriterionModel[] => {
  if (typeCode !== null) {
    return list.filter((item) => item.typeCode === typeCode);
  } else {
    return list;
  }
};

export const getters: GetterTree<ICriterionsStateModel, IRootStateModel> &
  CriterionsGetters = {
  getCriterionsList: (state: ICriterionsStateModel) => {
    return state.list;
  },
  getCriterionsTotal: (state: ICriterionsStateModel) => {
    return state.total;
  },
  getCriterionsListPage: (state: ICriterionsStateModel) => {
    return state.page;
  },
  getCriterionsListPerPage: (state: ICriterionsStateModel) => {
    return state.perPage;
  },
  isCriterionsDataLoading: (state: ICriterionsStateModel) => {
    return state.dataIsLoading;
  },
  getCriterionsSelectedCriterion: (state: ICriterionsStateModel) => {
    return state.selectedCriterion;
  },
  getCriterionsFilterValue: (state: ICriterionsStateModel) => {
    return state.filter;
  },
  getCriterionsFilteredList: (state: any) => {
    const status = state.filter ? state.filter.status : null;
    const search = state.filter ? state.filter.search : "";
    const typeCode = state.filter ? state.filter.typeCode : null;
    return filterByStatus(
      status,
      filterByType(typeCode, filterByName(search, state.list))
    );
  },
  getCriterionsSortValue: (state: ICriterionsStateModel) => {
    return state.sort;
  },
};
