import { ActionTree, ActionContext } from "vuex";
import {
  IAuthentificationStateModel,
  IRootStateModel,
} from "../../../interfaces";
import { AuthentificationMutations } from "./../mutations";
import { ActionTypes } from "./types";
import { MutationTypes } from "./../mutations/types";
import {
  LoginWithPasswordRequestModel,
  LoginWithPasswordResponseModel,
  Profile,
  ResetPasswordModel,
  SetPasswordModel,
} from "./../../../../services/types";
import {
  getClient,
  createClientInstance,
} from "../../../../services/client/clientProvider";
import { BaseClient } from "../../../../services/client/BaseClient";
import { Storage } from "./../../../../services/storage/Storage";
import { StoreActionTypes, StoreMutationTypes } from "@/store/types";
import { getClientInstance } from "./../../../..//services/client/clientProvider";
import { setApiAuthToken } from "@/services/api/wretchClient";
declare const window: any;

type AugmentedActionAuthentificationContext = {
  commit<K extends keyof AuthentificationMutations>(
    key: K,
    payload: Parameters<AuthentificationMutations[K]>[1]
  ): ReturnType<AuthentificationMutations[K]>;
} & Omit<ActionContext<IAuthentificationStateModel, IRootStateModel>, "commit">;

export interface AuthentificationActions {
  [ActionTypes.LOGIN_WITH_PASSWORD](
    context: AugmentedActionAuthentificationContext,
    model: LoginWithPasswordRequestModel
  ): Promise<void>;
  [ActionTypes.LOG_OUT](context: AugmentedActionAuthentificationContext): void;
  [ActionTypes.CHECK_IS_LOGGED_IN](
    context: AugmentedActionAuthentificationContext
  ): void;
  [ActionTypes.RESET_PASSWORD](
    context: AugmentedActionAuthentificationContext,
    model: ResetPasswordModel
  ): Promise<void>;
  [ActionTypes.SET_PASSWORD](
    context: AugmentedActionAuthentificationContext,
    password: string
  ): Promise<void>;
  [ActionTypes.SET_PASSWORD_HASH](
    context: AugmentedActionAuthentificationContext,
    hash: string
  ): void;
  [ActionTypes.SET_AUTHENTIFICATION_TUTORIAL_COMPLETED_STATUS](
    context: AugmentedActionAuthentificationContext
  ): Promise<void>;
  [ActionTypes.SET_AUTHENTIFICATION_SHOW_TUTORIAL_VIDEO](
    context: AugmentedActionAuthentificationContext,
    show: boolean
  ): void;
}

const storage = new Storage();

const setProfileToStorage = (profile: Profile) => storage.profile.set(profile);

const getProfileFromStorage = (): Profile | null => storage.getProfile();

export const actions: ActionTree<IAuthentificationStateModel, IRootStateModel> &
  AuthentificationActions = {
  async [ActionTypes.LOGIN_WITH_PASSWORD](
    { commit, dispatch },
    model: LoginWithPasswordRequestModel
  ) {
    const client: BaseClient = getClient();
    commit(MutationTypes.SET_AUTHENTIFICATION_DATA_LOADING, true);

    try {
      const response: LoginWithPasswordResponseModel =
        await client.loginWithPassword(model);

      if (!response) {
        return;
      }

      commit(MutationTypes.SET_AUTHENTIFICATION_PROFILE, response);
      commit(MutationTypes.SET_AUTHENTIFICATION_AUTHENTICATED, true);
      commit(MutationTypes.SET_AUTHENTIFICATION_PASSWORD_HASH, "");
      createClientInstance(response.token);
      setApiAuthToken(response.token);
      setProfileToStorage(response);
      await dispatch(StoreActionTypes.SET_DICTIONARIES_GET_DICTIONARIES_LIST);

      commit(
        MutationTypes.SET_AUTHENTIFICATION_SHOW_TUTORIAL_VIDEO,
        !response.tutorialCompleted
      );
    } catch (error) {
      window.error("Попробуйте ещё раз", "Ошибка авторизации");
      commit(MutationTypes.HAS_AUTHENTIFICATION_ERROR, true);
    } finally {
      commit(MutationTypes.SET_AUTHENTIFICATION_DATA_LOADING, false);
    }
  },
  [ActionTypes.LOG_OUT]({ commit }) {
    commit(MutationTypes.SET_AUTHENTIFICATION_PROFILE, null);
    commit(MutationTypes.SET_AUTHENTIFICATION_AUTHENTICATED, false);
    storage.clear();
  },
  [ActionTypes.CHECK_IS_LOGGED_IN]({ commit }) {
    const profile = getProfileFromStorage();

    if (profile) {
      commit(MutationTypes.SET_AUTHENTIFICATION_PROFILE, profile);
      commit(MutationTypes.SET_AUTHENTIFICATION_AUTHENTICATED, true);
      createClientInstance(profile.token);
      setApiAuthToken(profile.token);
    } else {
      commit(MutationTypes.SET_AUTHENTIFICATION_PROFILE, null);
      commit(MutationTypes.SET_AUTHENTIFICATION_AUTHENTICATED, false);
      storage.clear();
    }
  },
  async [ActionTypes.RESET_PASSWORD]({ commit }, model: ResetPasswordModel) {
    const client: BaseClient = getClient();

    commit(MutationTypes.SET_AUTHENTIFICATION_DATA_LOADING, true);
    try {
      await client.resetPassword(model);
    } catch (error) {
      console.error("resetPassword", error);
    } finally {
      commit(MutationTypes.SET_AUTHENTIFICATION_DATA_LOADING, false);
    }
  },
  async [ActionTypes.SET_PASSWORD]({ commit, getters }, password: string) {
    const client: BaseClient = getClient();

    commit(MutationTypes.SET_AUTHENTIFICATION_DATA_LOADING, true);

    const model: SetPasswordModel = {
      hash: getters.getAuthentificationPasswordHash,
      password,
    };
    try {
      await client.setPassword(model);
    } catch (error) {
      console.error("setPassword", error);
    } finally {
      commit(MutationTypes.SET_AUTHENTIFICATION_DATA_LOADING, false);
      commit(MutationTypes.SET_AUTHENTIFICATION_PASSWORD_HASH, "");
    }
  },
  [ActionTypes.SET_PASSWORD_HASH]({ commit }, hash: string) {
    commit(MutationTypes.SET_AUTHENTIFICATION_PASSWORD_HASH, hash);
  },
  async [ActionTypes.SET_AUTHENTIFICATION_TUTORIAL_COMPLETED_STATUS]({
    commit,
    getters,
  }) {
    if ((getters.getAuthentificationProfile as Profile).tutorialCompleted) {
      commit(MutationTypes.SET_AUTHENTIFICATION_SHOW_TUTORIAL_VIDEO, false);
    } else {
      const client: BaseClient = getClientInstance();
      commit(MutationTypes.SET_AUTHENTIFICATION_DATA_LOADING, true);

      try {
        await client.setOnboardingStatus();
        commit(
          MutationTypes.SET_AUTHENTIFICATION_TUTORIAL_COMPLETED_STATUS,
          true
        );
        setProfileToStorage(getters.getAuthentificationProfile);
      } catch (error) {
        console.error("setOnboardingStatus", error);
      } finally {
        commit(MutationTypes.SET_AUTHENTIFICATION_DATA_LOADING, false);
        commit(MutationTypes.SET_AUTHENTIFICATION_SHOW_TUTORIAL_VIDEO, false);
      }
    }
  },
  [ActionTypes.SET_AUTHENTIFICATION_SHOW_TUTORIAL_VIDEO](
    { commit },
    show: boolean
  ) {
    commit(MutationTypes.SET_AUTHENTIFICATION_SHOW_TUTORIAL_VIDEO, show);
  },
};
