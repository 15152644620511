export enum ActionTypes {
  SET_INSTANCE_SETTINGS = "setInstanceSettings",
  GET_INSTANCE_SETTINGS = "getInstanceSettings",
  GET_USER_INSTANCES = "getUserInstances",
  GET_ALL_INSTANCES = "getAllInstances",
  CREATE_INSTANCE = "createInstance",
  DELETE_INSTANCE = "deleteInstance",
  LOGOUT_INSTANCE = "logoutInstance",
  REMOVE_TARIFF = "removeTariff",
  GET_BALANCE = "getBalance",
  GET_TARIFFS = "getTariffs",
  ADD_TARIFF = "addTariff",
  GET_ORDER = "getOrder",
  SET_ORDER = "setOrder",
  GET_QR = "getQr",
}
