export enum MutationTypes {
  SET_REPORTS_DATA_IS_LOADING = "setReportsDataIsLoading",
  SET_REPORTS_FILTER_VALUE = "setReportsFilterValue",
  SET_REPORTS_LABELS = "setReportsChartsLabels",
  SET_REPORTS_RAW_LIST = "setReportsRawList",
  SET_REPORTS_IS_DAY = "setReportsIsDay",
  SET_REPORTS_REPORTS_LIST = "setReportsReportsList",
  SET_REPORTS_PROJECTS_LIST = "setReportsProjectsList",
  SET_REPORTS_SELECTED_PROJECTS_LIST = "setReportsSelectedProjectsList",
  SET_REPORTS_RENDER_LIST = "setReportsRenderList",
  SET_REPORTS_PAGE = "setReportsPage",
  SET_REPORTS_SELECTED_MANAGER = "setReportsSelectedManager",
  SET_REPORTS_SELECTED_CRITERION = "setReportsSelectedCriterion",
  SET_REPORTS_DATE_INTERVAL = "setReportsDateInterval",
  SET_REPORTS_CALLS_LIST = "setReportsCallList",
  SET_REPORTS_CALLS_LIST_SORT_VALUE = "setReportsCallListSortValue",
}
