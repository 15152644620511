import { ActionTree, ActionContext } from "vuex";
import { IEmployeesStateModel, IRootStateModel } from "../../../interfaces";
import { EmployeesMutations } from "./../mutations";
import { ActionTypes } from "./types";
import { MutationTypes } from "./../mutations/types";
import {
  CompanyEmployeesListResponseModel,
  CompanyEmployeesListFilterModel,
  DeleteEmployeesListRequestModel,
  ChangeEmployeeStatusModel,
  CreateEmployeeRequestModel,
  ChangeEmployeePasswordModel,
} from "./../../../../services/types";
import { BaseClient } from "./../../../../services/client/BaseClient";
import { getClientInstance } from "./../../../..//services/client/clientProvider";

type AugmentedActionEmployeesContext = {
  commit<K extends keyof EmployeesMutations>(
    key: K,
    payload: Parameters<EmployeesMutations[K]>[1]
  ): ReturnType<EmployeesMutations[K]>;
} & Omit<ActionContext<IEmployeesStateModel, IRootStateModel>, "commit">;

export interface EmployeesActions {
  [ActionTypes.SET_EMPLOYEES_LIST](
    context: AugmentedActionEmployeesContext
  ): Promise<void>;
  [ActionTypes.SET_EMPLOYEES_LIST_FILTER](
    context: AugmentedActionEmployeesContext,
    sort: CompanyEmployeesListFilterModel
  ): void;
  [ActionTypes.SET_EMPLOYEES_EMPLOYEE_CHANGE_STATUS](
    context: AugmentedActionEmployeesContext,
    model: ChangeEmployeeStatusModel
  ): Promise<void>;
  [ActionTypes.SET_EMPLOYEES_EMPLOYEE_DELETE](
    context: AugmentedActionEmployeesContext,
    model: DeleteEmployeesListRequestModel
  ): Promise<void>;
  [ActionTypes.SET_EMPLOYEES_EMPLOYEE_CREATE](
    context: AugmentedActionEmployeesContext,
    model: CreateEmployeeRequestModel
  ): Promise<void>;
  [ActionTypes.SET_EMPLOYEES_EMPLOYEE_CHANGE_PASSWORD](
    context: AugmentedActionEmployeesContext,
    model: ChangeEmployeePasswordModel
  ): Promise<void>;
  [ActionTypes.SET_EMPLOYEES_ERROR_MESSAGE_CLEAR](
    context: AugmentedActionEmployeesContext
  ): void;
}

export const actions: ActionTree<IEmployeesStateModel, IRootStateModel> &
  EmployeesActions = {
  async [ActionTypes.SET_EMPLOYEES_LIST]({ commit }) {
    const client: BaseClient = getClientInstance();

    commit(MutationTypes.SET_EMPLOYEES_DATA_IS_LOADING, true);

    try {
      const response: CompanyEmployeesListResponseModel =
        await client.getEmployeesList();

      commit(MutationTypes.SET_EMPLOYEES_LIST, response.users);
    } catch (error) {
      console.error("getEmployeesList", error);
    } finally {
      commit(MutationTypes.SET_EMPLOYEES_DATA_IS_LOADING, false);
    }
  },
  [ActionTypes.SET_EMPLOYEES_LIST_FILTER](
    { commit },
    filter: CompanyEmployeesListFilterModel
  ) {
    commit(MutationTypes.SET_EMPLOYEES_FILTER_VALUE, filter);
  },
  async [ActionTypes.SET_EMPLOYEES_EMPLOYEE_CHANGE_STATUS](
    { commit, dispatch },
    model: ChangeEmployeeStatusModel
  ) {
    const client: BaseClient = getClientInstance();

    commit(MutationTypes.SET_EMPLOYEES_DATA_IS_LOADING, true);

    try {
      await client.changeEmployeeStatus(model.id, {
        status: model.status == "ACTIVE" ? "INACTIVE" : "ACTIVE",
      });
    } catch (error) {
      console.error("changeEmployeeStatus", error);
    } finally {
      dispatch(ActionTypes.SET_EMPLOYEES_LIST);
    }
  },
  async [ActionTypes.SET_EMPLOYEES_EMPLOYEE_DELETE](
    { commit, dispatch },
    model: DeleteEmployeesListRequestModel
  ) {
    const client: BaseClient = getClientInstance();

    commit(MutationTypes.SET_EMPLOYEES_DATA_IS_LOADING, true);

    try {
      await client.deleteEmployeesList(model);
    } catch (error) {
      console.error("deleteEmployeesList", error);
    } finally {
      dispatch(ActionTypes.SET_EMPLOYEES_LIST);
    }
  },
  async [ActionTypes.SET_EMPLOYEES_EMPLOYEE_CREATE](
    { commit },
    model: CreateEmployeeRequestModel
  ) {
    const client: BaseClient = getClientInstance();

    commit(MutationTypes.SET_EMPLOYEES_DATA_IS_LOADING, true);

    try {
      await client.createEmployee(model);
    } catch (error) {
      console.error("createEmployee", error);
      if (error.message) {
        commit(MutationTypes.SET_EMPLOYEES_ERROR_MESSAGE, error.message);
      }
    } finally {
      commit(MutationTypes.SET_EMPLOYEES_DATA_IS_LOADING, false);
    }
  },
  async [ActionTypes.SET_EMPLOYEES_EMPLOYEE_CHANGE_PASSWORD](
    { commit, dispatch },
    model: ChangeEmployeePasswordModel
  ) {
    const client: BaseClient = getClientInstance();

    commit(MutationTypes.SET_EMPLOYEES_DATA_IS_LOADING, true);

    try {
      await client.changeEmployeePassword(model.id, {
        newPassword: model.newPassword,
      });
    } catch (error) {
      console.error("changeEmployeePassword", error);
    } finally {
      dispatch(ActionTypes.SET_EMPLOYEES_LIST);
    }
  },
  [ActionTypes.SET_EMPLOYEES_ERROR_MESSAGE_CLEAR]({ commit }) {
    commit(MutationTypes.SET_EMPLOYEES_ERROR_MESSAGE, null);
  },
};
