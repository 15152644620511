import { MutationTree } from "vuex";
import { MutationTypes } from "./types";
import { IReportsStateModel } from "./../../../interfaces";
import {
  BaseEntityModel,
  BaseReportRenderModel,
  DateIntervalsType,
  ReportCallModel,
  ReportCallsListSortModel,
  ReportItem,
  ReportsListDateFilterModel,
  ReportsPageType,
} from "@/services/types";

export type ReportsMutations<S = IReportsStateModel> = {
  [MutationTypes.SET_REPORTS_DATA_IS_LOADING](state: S, payload: boolean): void;
  [MutationTypes.SET_REPORTS_FILTER_VALUE](
    state: S,
    payload: ReportsListDateFilterModel
  ): void;
  [MutationTypes.SET_REPORTS_LABELS](state: S, payload: string[]): void;
  [MutationTypes.SET_REPORTS_RAW_LIST](state: S, payload: ReportItem[]): void;
  [MutationTypes.SET_REPORTS_IS_DAY](state: S, payload: boolean): void;
  [MutationTypes.SET_REPORTS_REPORTS_LIST](
    state: S,
    payload: BaseEntityModel[]
  ): void;
  [MutationTypes.SET_REPORTS_PROJECTS_LIST](
    state: S,
    payload: BaseEntityModel[]
  ): void;
  [MutationTypes.SET_REPORTS_SELECTED_PROJECTS_LIST](
    state: S,
    payload: BaseEntityModel[]
  ): void;
  [MutationTypes.SET_REPORTS_RENDER_LIST](
    state: S,
    payload: BaseReportRenderModel[]
  ): void;
  [MutationTypes.SET_REPORTS_PAGE](state: S, payload: ReportsPageType): void;
  [MutationTypes.SET_REPORTS_SELECTED_MANAGER](
    state: S,
    payload: BaseEntityModel
  ): void;
  [MutationTypes.SET_REPORTS_SELECTED_CRITERION](
    state: S,
    payload: BaseEntityModel
  ): void;
  [MutationTypes.SET_REPORTS_DATE_INTERVAL](
    state: S,
    payload: DateIntervalsType
  ): void;
  [MutationTypes.SET_REPORTS_CALLS_LIST](
    state: S,
    payload: ReportCallModel[]
  ): void;
  [MutationTypes.SET_REPORTS_CALLS_LIST_SORT_VALUE](
    state: S,
    payload: ReportCallsListSortModel
  ): void;
};

export const mutations: MutationTree<IReportsStateModel> & ReportsMutations = {
  [MutationTypes.SET_REPORTS_DATA_IS_LOADING](state, payload: boolean) {
    state.dataIsLoading = payload;
  },
  [MutationTypes.SET_REPORTS_FILTER_VALUE](
    state,
    payload: ReportsListDateFilterModel
  ) {
    state.filter = payload;
  },
  [MutationTypes.SET_REPORTS_LABELS](state, payload: string[]) {
    state.labels = payload;
  },
  [MutationTypes.SET_REPORTS_RAW_LIST](state, payload: ReportItem[]) {
    state.rawList = payload;
  },
  [MutationTypes.SET_REPORTS_IS_DAY](state, payload: boolean) {
    state.isDay = payload;
  },
  [MutationTypes.SET_REPORTS_REPORTS_LIST](state, payload: BaseEntityModel[]) {
    state.reports = payload;
  },
  [MutationTypes.SET_REPORTS_PROJECTS_LIST](state, payload: BaseEntityModel[]) {
    state.projects = payload;
  },
  [MutationTypes.SET_REPORTS_SELECTED_PROJECTS_LIST](
    state,
    payload: BaseEntityModel[]
  ) {
    state.selectedProjects = payload;
  },
  [MutationTypes.SET_REPORTS_RENDER_LIST](
    state,
    payload: BaseReportRenderModel[]
  ) {
    state.reportsRenderList = payload;
  },
  [MutationTypes.SET_REPORTS_PAGE](state, payload: ReportsPageType) {
    state.page = payload;
  },
  [MutationTypes.SET_REPORTS_SELECTED_MANAGER](
    state,
    payload: BaseEntityModel
  ) {
    state.selectedManager = payload;
  },
  [MutationTypes.SET_REPORTS_SELECTED_CRITERION](
    state,
    payload: BaseEntityModel
  ) {
    state.selectedCriterion = payload;
  },
  [MutationTypes.SET_REPORTS_DATE_INTERVAL](state, payload: DateIntervalsType) {
    state.dateInterval = payload;
  },
  [MutationTypes.SET_REPORTS_CALLS_LIST](state, payload: ReportCallModel[]) {
    state.reportCallsList = payload;
  },
  [MutationTypes.SET_REPORTS_CALLS_LIST_SORT_VALUE](
    state,
    payload: ReportCallsListSortModel
  ) {
    state.reportCallsListSort = payload;
  },
};
