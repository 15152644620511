/* eslint-disable */
import {
  BaseEntityModelsListResponse,
  BaseResponseModel,
  CallCriterionsListResponseModel,
  CallDetailsResponseModel,
  CallEstimatedCriterionsListResponseModel,
  CallEstimateRequestModel,
  CallsListResponseModel,
  CategoriesFullListResponseModel,
  CategoriesListRequestModel,
  CategoriesListResponseModel,
  CategoriesWithCriterionsListByCheckListIdResponseModel,
  CategoryModel,
  ChangeCategoryRequestModel,
  ChangeCategoryResponseModel,
  ChangeCategoryStatusRequestModel,
  ChangeCategoryStatusResponseModel,
  ChangeCheckListNameRequestModel,
  ChangeCheckListNameResponseModel,
  ChangeCriterionStatusRequestModel,
  ChangeCriterionStatusResponseModel,
  ChangeEmployeePasswordRequestModel,
  ChangeEmployeeStatusRequestModel,
  ChangeEmployeeStatusResponseModel,
  ChangeProjectIntegrationStatusRequestModel,
  ChangeProjectIntegrationStatusResponseModel,
  CheckListsListRequestModel,
  CheckListsListResponseModel,
  CheckListWithCategoriesSimpleModelListResponse,
  CommentModel,
  CompanyEmployeesListResponseModel,
  CreateCategoryRequestModel,
  CreateCategoryResponseModel,
  CreateCheckListRequestModel,
  CreateCheckListResponseModel,
  CreateCommentRequestModel,
  CreateCriterionRequestModel,
  CreateCriterionResponseModel,
  CreateEmployeeRequestModel,
  CreateEmployeeResponseModel,
  CreateNewProjectRequestModel,
  CreateNewProjectResponseModel,
  CriterionsListByCheckListIdResponseModel,
  CriterionsListRequestModel,
  CriterionsListResponseModel,
  DeleteCategoriesListRequestModel,
  DeleteCheckListRequestModel,
  DeleteCommentsListRequest,
  DeleteCriterionsListRequestModel,
  DeleteEmployeesListRequestModel,
  DeleteProjectIntegrationsRequestModel,
  DeleteProjectsListRequestModel,
  Dictionary,
  EditCommentRequestModel,
  EditCriterionRequestModel,
  EditCriterionResponseModel,
  EditProjectRequestModel,
  EditProjectResponseModel,
  EditRecordIdListRequestModel,
  GetAvailableUserRolesRequestModel,
  GetAvailableUserRolesResponseModel,
  GetCardListRequestModel,
  GetCardListResponseModel,
  GetCategoryResponseModel,
  GetCheckListResponseModel,
  GetCheckListWithCriterionsResponseModel,
  GetCommentsListResponseModel,
  GetCriterionResponseModel,
  GetDictionaryRequestModel,
  GetDictionaryResponseModel,
  GetProjectCardManagersResponseModel,
  GetProjectCheckListResponseModel,
  GetProjectIntegrationDetailsResponseModel,
  GetProjectIntegrationsListResponseModel,
  GetProjectsFunnelsListModelRequestModel,
  GetProjectsFunnelsListModelResponseModel,
  GetProjectsPhonesResponseModel,
  GetReportCallsListRequestModel,
  GetReportCallsListResponseModel,
  GetReportsRequest,
  GetReportsResponse,
  LoginWithPasswordRequestModel,
  LoginWithPasswordResponseModel,
  ProjectsDetailsResponseModel,
  ProjectsListRequestModel,
  ProjectsListResponseModel,
  ResetPasswordModel,
  SetCallManagerRequestModel,
  SetPasswordModel,
  SetProjectsFunnelsListModelRequestModel,
  SetProjectsFunnelsListModelResponseModel,
  UpdateProjectCheckListsRequestModel,
  UpdateUserRequestModel,
} from "../types";

import { BaseClient } from "./BaseClient";

import enDict from "../../i18n/en.json";
import ruDict from "../../i18n/ru.json";
import { BaseEntityModel } from "../types/base";
import {
  SubscriptionItemDataModel,
  SubscriptionModel,
} from "../types/subscription";
import {
  EwaInstance,
  EwaInstanceQr,
  EwaInstanceSettings,
  EwaOrder,
  EwaTariff,
  EwaUser,
} from "../types/ewa";
import { EntitiesListRequestDto } from "@/services/api/entities/types";

export class WebClient extends BaseClient {
  private static instance: WebClient;

  private readonly url = process.env.VUE_APP_BASE_URL;

  private readonly token?: string;

  constructor(token?: string) {
    super();

    if (token) {
      this.token = token;
    }
  }

  public static getInstance(token?: string): WebClient {
    if (!WebClient.instance) {
      WebClient.instance = new WebClient(token);
    }

    return WebClient.instance;
  }

  public static createInstance(token?: string) {
    WebClient.instance = new WebClient(token);
  }

  public readonly loginWithPassword = (
    model: LoginWithPasswordRequestModel
  ): Promise<LoginWithPasswordResponseModel> =>
    fetch(`${this.url}/login`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(model),
    }).then((response) => this.parseResponse(response));

  public readonly resetPassword = async (
    model: ResetPasswordModel
  ): Promise<BaseResponseModel> =>
    fetch(`${this.url}/reset-password`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(model),
    }).then((response) => this.parseResponse(response));

  public readonly setPassword = async (
    model: SetPasswordModel
  ): Promise<BaseResponseModel> =>
    fetch(`${this.url}/update-password`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(model),
    }).then((response) => this.parseResponse(response));

  public readonly setOnboardingStatus = async (): Promise<BaseResponseModel> =>
    fetch(`${this.url}/onboarding/complete`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.token}`,
      },
      body: JSON.stringify({}),
    }).then((response) => this.parseResponse(response));

  public readonly getSubscription = async (): Promise<SubscriptionModel> =>
    fetch(`${this.url}/subscription`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${this.token}`,
      },
    }).then((response) => this.parseResponse(response));

  // public readonly getDictionary = (model: GetDictionaryRequestModel): Promise<GetDictionaryResponseModel> => fetch(`${this.url}/dictionary/${model.locale}`, {
  //         method: 'GET',
  //         headers: {
  //             'Content-Type': 'application/json'
  //         },
  //         body: JSON.stringify(model)
  //     })
  //         .then(response => this.parseResponse(response))

  public readonly getDictionary = (
    model: GetDictionaryRequestModel
  ): Promise<GetDictionaryResponseModel> => {
    let dictionary: Dictionary = {
      data: null,
      locale: model.locale,
    };

    if (model.locale == "ru") {
      dictionary.data = ruDict;
    } else {
      dictionary.data = enDict;
    }

    return new Promise((resolve) => {
      resolve(dictionary);
    });
  };

  public readonly getCategoriesList = (
    model: CategoriesListRequestModel
  ): Promise<CategoriesListResponseModel> =>
    fetch(`${this.url}/criterions/categories/list`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.token}`,
      },
      body: JSON.stringify(model),
    }).then((response) => this.parseResponse(response));

  public readonly getCategoriesFullList =
    async (): Promise<CategoriesFullListResponseModel> =>
      fetch(`${this.url}/criterions/categories/list`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${this.token}`,
        },
      }).then((response) => this.parseResponse(response));

  public readonly getCategoriesWithCriterionsFullList =
    async (): Promise<CategoriesFullListResponseModel> =>
      fetch(`${this.url}/criterions/categories/select`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${this.token}`,
        },
      }).then((response) => this.parseResponse(response));

  public readonly deleteCategoriesList = (
    model: DeleteCategoriesListRequestModel
  ): Promise<BaseResponseModel> =>
    fetch(`${this.url}/criterions/categories/remove`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.token}`,
      },
      body: JSON.stringify(model),
    }).then((response) => this.parseResponse(response));

  public readonly createCategory = (
    model: CreateCategoryRequestModel
  ): Promise<CreateCategoryResponseModel> =>
    fetch(`${this.url}/criterions/categories/create`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.token}`,
      },
      body: JSON.stringify(model),
    }).then((response) => this.parseResponse(response));

  public readonly getCategory = (
    id: number
  ): Promise<GetCategoryResponseModel> =>
    fetch(`${this.url}/criterions/categories/${id}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${this.token}`,
      },
    }).then((response) => this.parseResponse(response));

  public readonly changeCategoryStatus = (
    id: number,
    model: ChangeCategoryStatusRequestModel
  ): Promise<ChangeCategoryStatusResponseModel> =>
    fetch(`${this.url}/criterions/categories/${id}/set-status`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.token}`,
      },
      body: JSON.stringify(model),
    }).then((response) => this.parseResponse(response));

  public readonly changeCategory = (
    id: number,
    model: ChangeCategoryRequestModel
  ): Promise<ChangeCategoryResponseModel> =>
    fetch(`${this.url}/criterions/categories/${id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.token}`,
      },
      body: JSON.stringify(model),
    }).then((response) => this.parseResponse(response));

  public readonly getCategoryWithCriterions = (
    id: number
  ): Promise<CategoryModel[]> =>
    fetch(`${this.url}/criterions/categories/${id}/criterions`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${this.token}`,
      },
    }).then((response) => this.parseResponse(response));

  public readonly getCriterionsListByCategoryId = (
    id: number
  ): Promise<CategoryModel[]> =>
    fetch(`${this.url}/criterions/categories/${id}/criterions-only`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${this.token}`,
      },
    }).then((response) => this.parseResponse(response));

  public readonly changeCriterionStatus = async (
    id: number,
    model: ChangeCriterionStatusRequestModel
  ): Promise<ChangeCriterionStatusResponseModel> =>
    fetch(`${this.url}/criterions/${id}/set-status`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${this.token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(model),
    }).then((response) => this.parseResponse(response));

  public readonly deleteCriterionsList = async (
    model: DeleteCriterionsListRequestModel
  ): Promise<BaseResponseModel> =>
    fetch(`${this.url}/criterions/remove`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.token}`,
      },
      body: JSON.stringify(model),
    }).then((response) => this.parseResponse(response));

  public readonly createCriterion = async (
    model: CreateCriterionRequestModel
  ): Promise<CreateCriterionResponseModel> =>
    fetch(`${this.url}/criterions/create`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.token}`,
      },
      body: JSON.stringify(model),
    }).then((response) => this.parseResponse(response));

  public readonly editCriterion = async (
    id: number,
    model: EditCriterionRequestModel
  ): Promise<EditCriterionResponseModel> =>
    fetch(`${this.url}/criterions/${id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.token}`,
      },
      body: JSON.stringify(model),
    }).then((response) => this.parseResponse(response));

  public readonly getCriterion = (
    id: number
  ): Promise<GetCriterionResponseModel> =>
    fetch(`${this.url}/criterions/${id}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${this.token}`,
      },
    }).then((response) => this.parseResponse(response));

  public readonly getCriterionsList = async (
    model: CriterionsListRequestModel
  ): Promise<CriterionsListResponseModel> =>
    fetch(`${this.url}/criterions/list`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.token}`,
      },
      body: JSON.stringify(model),
    }).then((response) => this.parseResponse(response));

  public readonly getCheckListsList = async (
    model: CheckListsListRequestModel
  ): Promise<CheckListsListResponseModel> =>
    fetch(`${this.url}/checklists/list`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.token}`,
      },
      body: JSON.stringify(model),
    }).then((response) => this.parseResponse(response));

  public readonly getCheckList = (
    id: number
  ): Promise<GetCheckListResponseModel> =>
    fetch(`${this.url}/checklists/${id}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${this.token}`,
      },
    }).then((response) => this.parseResponse(response));

  public readonly createCheckList = async (
    model: CreateCheckListRequestModel
  ): Promise<CreateCheckListResponseModel> =>
    fetch(`${this.url}/checklists/create`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.token}`,
      },
      body: JSON.stringify(model),
    }).then((response) => this.parseResponse(response));

  public readonly changeCheckListName = async (
    id: number,
    model: ChangeCheckListNameRequestModel
  ): Promise<ChangeCheckListNameResponseModel> =>
    fetch(`${this.url}/checklists/${id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.token}`,
      },
      body: JSON.stringify(model),
    }).then((response) => this.parseResponse(response));

  public readonly deleteCheckListsList = async (
    model: DeleteCheckListRequestModel
  ): Promise<BaseResponseModel> =>
    fetch(`${this.url}/checklists/remove`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.token}`,
      },
      body: JSON.stringify(model),
    }).then((response) => this.parseResponse(response));

  public readonly getCheckListWithCriterions = (
    id: number
  ): Promise<GetCheckListWithCriterionsResponseModel> =>
    fetch(`${this.url}/checklists/${id}/criterions`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${this.token}`,
      },
    }).then((response) => this.parseResponse(response));

  public readonly getCriterionsListByCheckListId = (
    id: number
  ): Promise<CriterionsListByCheckListIdResponseModel> =>
    fetch(`${this.url}/checklists/${id}/criterions-only`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${this.token}`,
      },
    }).then((response) => this.parseResponse(response));

  public readonly getCategoriesWithCriterionsListByCheckListId = (
    id: number
  ): Promise<CategoriesWithCriterionsListByCheckListIdResponseModel> =>
    fetch(`${this.url}/checklists/${id}/criterions-expanded`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${this.token}`,
      },
    }).then((response) => this.parseResponse(response));

  public readonly editCheckListCriterions = async (
    id: number,
    model: EditRecordIdListRequestModel
  ): Promise<BaseResponseModel> =>
    fetch(`${this.url}/checklists/${id}/update-criterions`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.token}`,
      },
      body: JSON.stringify(model),
    }).then((response) => this.parseResponse(response));

  public readonly getCallsList = async (
    model: EntitiesListRequestDto
  ): Promise<CallsListResponseModel> => {
    return fetch(`${this.url}/entities/list`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.token}`,
      },
      body: JSON.stringify(model),
    }).then((response) => {
      return this.parseResponse(response);
    });
  };

  public readonly getCallDetails = (
    id: number
  ): Promise<CallDetailsResponseModel> =>
    fetch(`${this.url}/calls/${id}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${this.token}`,
      },
    }).then((response) => this.parseResponse(response));

  public readonly getCallCriterionsList = (
    id: number
  ): Promise<CallCriterionsListResponseModel> =>
    fetch(`${this.url}/checklists/${id}/criterions-grouped`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${this.token}`,
      },
    }).then((response) => this.parseResponse(response));

  public readonly setCallEstimation = async (
    id: number,
    model: CallEstimateRequestModel
  ): Promise<CallEstimatedCriterionsListResponseModel> =>
    fetch(`${this.url}/calls/${id}/estimate`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.token}`,
      },
      body: JSON.stringify(model),
    }).then((response) => this.parseResponse(response));

  public readonly editCallEstimation = async (
    id: number,
    model: CallEstimateRequestModel
  ): Promise<CallEstimatedCriterionsListResponseModel> =>
    fetch(`${this.url}/calls/${id}/estimate`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.token}`,
      },
      body: JSON.stringify(model),
    }).then((response) => this.parseResponse(response));

  public readonly setCallManager = async (
    id: number,
    model: SetCallManagerRequestModel
  ): Promise<BaseResponseModel> =>
    fetch(`${this.url}/calls/${id}/manager`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.token}`,
      },
      body: JSON.stringify(model),
    }).then((response) => this.parseResponse(response));

  public readonly getCallEstimation = async (
    id: number
  ): Promise<CallEstimatedCriterionsListResponseModel> =>
    fetch(`${this.url}/estimations/${id}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${this.token}`,
      },
    }).then((response) => this.parseResponse(response));

  public readonly getProjectsList = async (
    model: ProjectsListRequestModel
  ): Promise<ProjectsListResponseModel> =>
    fetch(`${this.url}/projects/list`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.token}`,
      },
      body: JSON.stringify(model),
    }).then((response) => this.parseResponse(response));

  public readonly getProjectsFullList =
    async (): Promise<ProjectsListResponseModel> =>
      fetch(`${this.url}/projects/list`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${this.token}`,
        },
      }).then((response) => this.parseResponse(response));

  public readonly getProjectsLimits =
    async (): Promise<SubscriptionItemDataModel> =>
      fetch(`${this.url}/projects/limits`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${this.token}`,
        },
      }).then((response) => this.parseResponse(response));

  public readonly getProjectDetails = async (
    id: number
  ): Promise<ProjectsDetailsResponseModel> =>
    fetch(`${this.url}/projects/${id}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${this.token}`,
      },
    }).then((response) => this.parseResponse(response));

  public readonly changeProjectName = async (
    id: number,
    model: EditProjectRequestModel
  ): Promise<EditProjectResponseModel> =>
    fetch(`${this.url}/projects/${id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.token}`,
      },
      body: JSON.stringify(model),
    }).then((response) => this.parseResponse(response));

  public readonly deleteProjectsList = async (
    model: DeleteProjectsListRequestModel
  ): Promise<BaseResponseModel> =>
    fetch(`${this.url}/projects/remove`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.token}`,
      },
      body: JSON.stringify(model),
    }).then((response) => this.parseResponse(response));

  public readonly getProjectChecklists = async (
    id: number
  ): Promise<GetProjectCheckListResponseModel> =>
    fetch(`${this.url}/projects/${id}/checklists`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${this.token}`,
      },
    }).then((response) => this.parseResponse(response));

  public readonly getProjectIntegrations = async (
    id: number
  ): Promise<GetProjectIntegrationsListResponseModel> =>
    fetch(`${this.url}/projects/${id}/integrations/list`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${this.token}`,
      },
    }).then((response) => this.parseResponse(response));

  public readonly getProjectIntegrationDetails = async (
    id: number
  ): Promise<GetProjectIntegrationDetailsResponseModel> =>
    fetch(`${this.url}/integrations/${id}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${this.token}`,
      },
    }).then((response) => this.parseResponse(response));

  public readonly changeProjectIntegrationStatus = async (
    projectId: number,
    integrationId: number,
    model: ChangeProjectIntegrationStatusRequestModel
  ): Promise<ChangeProjectIntegrationStatusResponseModel> =>
    fetch(
      `${this.url}/projects/${projectId}/integrations/${integrationId}/set-status`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.token}`,
        },
        body: JSON.stringify(model),
      }
    ).then((response) => this.parseResponse(response));

  public readonly deleteProjectIntegrations = async (
    projectId: number,
    model: DeleteProjectIntegrationsRequestModel
  ): Promise<BaseResponseModel> =>
    fetch(`${this.url}/projects/${projectId}/integrations/remove`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.token}`,
      },
      body: JSON.stringify(model),
    }).then((response) => this.parseResponse(response));

  public readonly getCheckListWithCategoriesSimpleList =
    async (): Promise<CheckListWithCategoriesSimpleModelListResponse> =>
      fetch(`${this.url}/checklists/expanded`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${this.token}`,
        },
      }).then((response) => this.parseResponse(response));

  public readonly createProject = async (
    model: CreateNewProjectRequestModel
  ): Promise<CreateNewProjectResponseModel> =>
    fetch(`${this.url}/projects/create`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.token}`,
      },
      body: JSON.stringify(model),
    }).then((response) => this.parseResponse(response));

  public readonly getProjectsFunnelsList = async (
    model: GetProjectsFunnelsListModelRequestModel
  ): Promise<GetProjectsFunnelsListModelResponseModel> =>
    fetch(
      `${this.url}/projects/${model.projectId}/integrations/${model.integrationId}/settings`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${this.token}`,
        },
      }
    ).then((response) => this.parseResponse(response));

  public readonly setProjectsFunnelsList = async (
    projectId: number,
    integrationId: number,
    model: SetProjectsFunnelsListModelRequestModel
  ): Promise<SetProjectsFunnelsListModelResponseModel> =>
    fetch(
      `${this.url}/projects/${projectId}/integrations/${integrationId}/settings`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.token}`,
        },
        body: JSON.stringify(model),
      }
    ).then((response) => this.parseResponse(response));

  public readonly updateProjectCheckLists = async (
    projectId: number,
    model: UpdateProjectCheckListsRequestModel
  ): Promise<BaseResponseModel> =>
    fetch(`${this.url}/projects/${projectId}/update-checklists`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.token}`,
      },
      body: JSON.stringify(model),
    }).then((response) => this.parseResponse(response));

  public readonly getManagersReports = async (
    model: GetReportsRequest
  ): Promise<GetReportsResponse> =>
    fetch(`${this.url}/reports/managers`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.token}`,
      },
      body: JSON.stringify(model),
    }).then((response) => this.parseResponse(response));

  public readonly getCriterionsReports = async (
    model: GetReportsRequest
  ): Promise<GetReportsResponse> =>
    fetch(`${this.url}/reports/criterions`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.token}`,
      },
      body: JSON.stringify(model),
    }).then((response) => this.parseResponse(response));

  public readonly getManagersSimpleList =
    (): Promise<BaseEntityModelsListResponse> =>
      fetch(`${this.url}/projects/managers`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${this.token}`,
        },
      }).then((response) => this.parseResponse(response));

  public readonly getProjectAvailableManagersList = (
    projectId: number
  ): Promise<BaseEntityModelsListResponse> =>
    fetch(`${this.url}/projects/${projectId}/managers`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${this.token}`,
      },
    }).then((response) => this.parseResponse(response));

  public getProjectsSimpleList() {
    return fetch(`${this.url}/projects/list`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${this.token}`,
      },
    }).then((response) => this.parseResponse(response));
  }

  public readonly getProjectsPhonesList =
    async (): Promise<GetProjectsPhonesResponseModel> =>
      fetch(`${this.url}/projects/phones`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${this.token}`,
        },
      }).then((response) => this.parseResponse(response));

  public readonly getCommentsList = async (
    callId: number
  ): Promise<GetCommentsListResponseModel> =>
    fetch(`${this.url}/calls/${callId}/comments`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${this.token}`,
      },
    }).then((response) => this.parseResponse(response));

  public readonly createComment = async (
    callId: number,
    model: CreateCommentRequestModel
  ): Promise<CommentModel> =>
    fetch(`${this.url}/calls/${callId}/comments/create`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.token}`,
      },
      body: JSON.stringify(model),
    }).then((response) => this.parseResponse(response));

  public readonly editComment = async (
    callId: number,
    commentId: number,
    model: EditCommentRequestModel
  ): Promise<CommentModel> =>
    fetch(`${this.url}/calls/${callId}/comments/${commentId}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.token}`,
      },
      body: JSON.stringify(model),
    }).then((response) => this.parseResponse(response));

  public readonly deleteCommentsList = async (
    callId: number,
    model: DeleteCommentsListRequest
  ): Promise<BaseResponseModel> =>
    fetch(`${this.url}/calls/${callId}/comments/remove`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.token}`,
      },
      body: JSON.stringify(model),
    }).then((response) => this.parseResponse(response));

  public readonly getManagerReports = async (
    managerId: number,
    model: GetReportsRequest
  ): Promise<GetReportsResponse> =>
    fetch(`${this.url}/reports/managers/${managerId}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.token}`,
      },
      body: JSON.stringify(model),
    }).then((response) => this.parseResponse(response));

  public readonly getCriterionReports = async (
    criterionId: number,
    model: GetReportsRequest
  ): Promise<GetReportsResponse> =>
    fetch(`${this.url}/reports/criterions/${criterionId}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.token}`,
      },
      body: JSON.stringify(model),
    }).then((response) => this.parseResponse(response));

  public readonly getManagerBase = async (
    managerId: number
  ): Promise<BaseEntityModel> =>
    fetch(`${this.url}/reports/managers/${managerId}/info`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${this.token}`,
      },
    }).then((response) => this.parseResponse(response));

  public readonly getCriterionBase = async (
    criterionId: number
  ): Promise<BaseEntityModel> =>
    fetch(`${this.url}/reports/criterions/${criterionId}/info`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${this.token}`,
      },
    }).then((response) => this.parseResponse(response));

  public readonly getReportCallsList = async (
    model: GetReportCallsListRequestModel
  ): Promise<GetReportCallsListResponseModel> =>
    fetch(`${this.url}/reports/entities/list`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.token}`,
      },
      body: JSON.stringify(model),
    }).then((response) => this.parseResponse(response));

  public readonly createEmployee = (
    model: CreateEmployeeRequestModel
  ): Promise<CreateEmployeeResponseModel> =>
    fetch(`${this.url}/users/create`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.token}`,
      },
      body: JSON.stringify(model),
    }).then((response) => this.parseResponse(response));
  //   .then((response) => {
  //   if (response.ok) {
  //     return response.json();
  //   } else {
  //     if (response.status == 401) {
  //       const storage = new Storage();
  //       storage.clear();
  //       window.location.href = "/";
  //     } else if (response.status == 403) {
  //       throw new Error("Allowed users limit is reached");
  //     }
  //   }
  // });

  public readonly deleteEmployeesList = (
    model: DeleteEmployeesListRequestModel
  ): Promise<BaseResponseModel> =>
    fetch(`${this.url}/users/remove`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.token}`,
      },
      body: JSON.stringify(model),
    }).then((response) => this.parseResponse(response));

  public readonly changeEmployeeStatus = (
    id: number,
    model: ChangeEmployeeStatusRequestModel
  ): Promise<ChangeEmployeeStatusResponseModel> =>
    fetch(`${this.url}/users/${id}/set-status`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.token}`,
      },
      body: JSON.stringify(model),
    }).then((response) => this.parseResponse(response));

  public readonly getEmployeesList =
    (): Promise<CompanyEmployeesListResponseModel> =>
      fetch(`${this.url}/users/list`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${this.token}`,
        },
      }).then((response) => this.parseResponse(response));

  public readonly changeEmployeePassword = (
    id: number,
    model: ChangeEmployeePasswordRequestModel
  ): Promise<BaseResponseModel> =>
    fetch(`${this.url}/users/${id}/change-password`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.token}`,
      },
      body: JSON.stringify(model),
    }).then((response) => this.parseResponse(response));

  public readonly bitrixIntegrationAdd = (
    projectId: number,
    webhookURL: string
  ): Promise<BaseResponseModel> => {
    const trueUrl = this.url.slice(0, -4);
    return fetch(`${trueUrl}/integration/bitrix24/add`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.token}`,
      },
      body: JSON.stringify({
        projectId,
        webhookURL,
      }),
    }).then((response) => this.parseResponse(response));
  };

  public readonly getSettings = (): Promise<BaseResponseModel> =>
    fetch(`${this.url}/users/self/settings`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${this.token}`,
      },
    }).then((response) => this.parseResponse(response));

  public readonly setSettings = (settings): Promise<BaseResponseModel> =>
    fetch(`${this.url}/users/self/settings`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.token}`,
      },
      body: JSON.stringify(settings),
    }).then((response) => this.parseResponse(response));

  /**
   * EWA Methods
   */

  public readonly getAllInstances = (): Promise<EwaInstance[]> =>
    fetch(`${this.url}/wa/list`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${this.token}`,
      },
    }).then((response) => this.parseResponse(response));

  public readonly getInstances = (copmanyId): Promise<EwaInstance[]> =>
    fetch(`${this.url}/wa/list`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.token}`,
      },
      body: JSON.stringify({
        copmanyId,
      }),
    }).then((response) => this.parseResponse(response));

  public readonly addInstance = (
    idTariff,
    couponCode?: string
  ): Promise<EwaInstance> =>
    fetch(`${this.url}/wa/instance/add`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.token}`,
      },
      body: JSON.stringify({
        tariffId: idTariff,
        couponCode: couponCode,
        autoRenew: false, // TODO: внедрить фичу автопродления
      }),
    }).then((response) => this.parseResponse(response));

  public readonly deleteInstance = (idInstance): Promise<BaseResponseModel> =>
    fetch(`${this.url}/wa/delete`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.token}`,
      },
      body: JSON.stringify({
        idInstance,
      }),
    }).then((response) => this.parseResponse(response));

  public readonly renewInstance = (
    idInstance,
    countDays
  ): Promise<BaseResponseModel> =>
    fetch(`${this.url}/wa/delete`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.token}`,
      },
      body: JSON.stringify({
        idInstance,
        countDays,
      }),
    }).then((response) => this.parseResponse(response));

  public readonly getUserInstances = (): Promise<EwaInstance[]> =>
    fetch(`${this.url}/wa/instance/list`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${this.token}`,
      },
    }).then((response) => this.parseResponse(response));

  public readonly getInstanceSettings = (
    idInstance
  ): Promise<EwaInstanceSettings> =>
    fetch(`${this.url}/wa/instance/${idInstance}/settings`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${this.token}`,
      },
    }).then((response) => this.parseResponse(response));

  public readonly setInstanceSettings = (
    idInstance,
    settings
  ): Promise<BaseResponseModel> =>
    fetch(`${this.url}/wa/instance/${idInstance}/settings`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.token}`,
      },
      body: JSON.stringify(settings),
    }).then((response) => this.parseResponse(response));

  public readonly getInstanceQr = (idInstance): Promise<EwaInstanceQr> =>
    fetch(`${this.url}/wa/instance/${idInstance}/qr`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${this.token}`,
      },
    })
      .then((response) => this.parseResponse(response))
      .catch((error) => {
        (window as any).hideModal();
        (window as any).error(
          "Возможно, канал уже авторизован",
          "Ошибка получения QR-кода"
        );
        console.error("getQr", error);
      });

  public readonly logoutInstance = (idInstance): Promise<BaseResponseModel> =>
    fetch(`${this.url}/wa/instance/${idInstance}/logout`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${this.token}`,
      },
    }).then((response) => this.parseResponse(response));

  public readonly getTariffs = (couponCode?: string): Promise<EwaTariff[]> =>
    fetch(
      `${this.url}/wa/tariffs${couponCode ? "?couponCode=" + couponCode : ""}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${this.token}`,
        },
      }
    ).then((response) => this.parseResponse(response));

  public readonly addTariff = (data: EwaTariff): Promise<BaseResponseModel> =>
    fetch(`${this.url}/wa/tariffs/add`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.token}`,
      },
      body: JSON.stringify(data),
    }).then((response) => this.parseResponse(response));

  public readonly removeTariff = (id: number): Promise<BaseResponseModel> =>
    fetch(`${this.url}/wa/tariffs/remove`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.token}`,
      },
      body: JSON.stringify({
        id,
      }),
    }).then((response) => this.parseResponse(response));

  public readonly getOrder = (): Promise<EwaUser> =>
    fetch(`${this.url}/wa/order`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${this.token}`,
      },
    }).then((response) => this.parseResponse(response));

  public readonly setOrder = (
    data: EwaOrder
  ): Promise<{ result: string; invoice_id: number }> =>
    fetch(`${this.url}/wa/order`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.token}`,
      },
      body: JSON.stringify(data),
    }).then((response) => this.parseResponse(response));

  public readonly getBalance = (): Promise<{ waBalance: number }> =>
    fetch(`${this.url}/wa/balance`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${this.token}`,
      },
    }).then((response) => this.parseResponse(response));

  public readonly getCardsList = (
    payload: GetCardListRequestModel
  ): Promise<GetCardListResponseModel> =>
    fetch(`${this.url}/cards/list`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${this.token}`,
      },
      body: JSON.stringify(payload),
    }).then((response) => this.parseResponse(response));

  public readonly getProjectCardManagers = (
    projectId: number
  ): Promise<GetProjectCardManagersResponseModel> => {
    return fetch(`${this.url}/projects/${projectId}/managers`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${this.token}`,
      },
    }).then((response) => this.parseResponse(response));
  };

  public getAvailableUserRoles(
    payload?: GetAvailableUserRolesRequestModel
  ): Promise<GetAvailableUserRolesResponseModel> {
    return fetch(`${this.url}/users/self/roles/available`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${this.token}`,
      },
      body: payload ? JSON.stringify(payload) : "",
    }).then((r) => this.parseResponse(r));
  }

  public getUserSelf(): Promise<any> {
    return fetch(`${this.url}/users/self`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${this.token}`,
      },
    }).then((r) => this.parseResponse(r));
  }

  public updateUser(payload: UpdateUserRequestModel): Promise<void> {
    return fetch(`${this.url}/users/${payload.id}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${this.token}`,
      },
      body: JSON.stringify(payload),
    }).then((r) => this.parseResponse(r));
  }

  public updateSubCompany(payload) {
    return fetch(`${this.url}/subcompanies/${payload.id}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${this.token}`,
      },
      body: JSON.stringify(payload),
    }).then((r) => this.parseResponse(r));
  }

  public removeSubCompanies(payload) {
    return fetch(`${this.url}/subcompanies/remove`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${this.token}`,
      },
      body: JSON.stringify(payload),
    }).then((r) => this.parseResponse(r));
  }

  public createSubCompany(payload) {
    return fetch(`${this.url}/subcompanies/create`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${this.token}`,
      },
      body: JSON.stringify(payload),
    }).then((r) => this.parseResponse(r));
  }
}
