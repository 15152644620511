import { ActionTypes as AuthentificationActionTypes } from "./modules/authentification/actions/types";
import { MutationTypes as AuthentificationMutationTypes } from "./modules/authentification/mutations/types";
import { MutationTypes as PlayerMutationTypes } from "./modules/player/mutations/types";
import { ActionTypes as PlayerActionTypes } from "./modules/player/actions/types";
import { ActionTypes as CommentsActionTypes } from "./modules/comments/actions/types";
import { MutationTypes as CommentsMutationTypes } from "./modules/comments/mutations/types";
import { ActionTypes as DictionariesActionTypes } from "./modules/dictionaries/actions/types";
import { MutationTypes as DictionariesMutationTypes } from "./modules/dictionaries/mutations/types";
import { MutationTypes as CategoriesMutationTypes } from "./modules/categories/mutations/types";
import { ActionTypes as CategoriesActionTypes } from "./modules/categories/actions/types";
import { MutationTypes as CriterionsMutationTypes } from "./modules/criterions/mutations/types";
import { ActionTypes as CriterionsActionTypes } from "./modules/criterions/actions/types";
import { MutationTypes as CheckListsMutationTypes } from "./modules/checkLists/mutations/types";
import { ActionTypes as CheckListsActionTypes } from "./modules/checkLists/actions/types";
import { MutationTypes as CallsMutationTypes } from "./modules/calls/mutations/types";
import { ActionTypes as CallsActionTypes } from "./modules/reports/actions/types";
import { MutationTypes as ReportsMutationTypes } from "./modules/reports/mutations/types";
import { ActionTypes as ReportsActionTypes } from "./modules/calls/actions/types";
import { MutationTypes as ProjectsMutationTypes } from "./modules/projects/mutations/types";
import { ActionTypes as ProjectsActionTypes } from "./modules/projects/actions/types";
import { MutationTypes as EmployeesMutationTypes } from "./modules/employees/mutations/types";
import { ActionTypes as EmployeesActionTypes } from "./modules/employees/actions/types";
import { MutationTypes as SelectedCallMutationTypes } from "./modules/selectedCall/mutations/types";
import { ActionTypes as SelectedCallActionTypes } from "./modules/selectedCall/actions/types";
import { MutationTypes as ProjectIntegrationMutationTypes } from "./modules/projectIntegration/mutations/types";
import { ActionTypes as ProjectIntegrationActionTypes } from "./modules/projectIntegration/actions/types";
import { MutationTypes as SubscriptionMutationTypes } from "./modules/subscriptions/mutations/types";
import { ActionTypes as SubscriptionActionTypes } from "./modules/subscriptions/actions/types";
import { MutationTypes as GlobalMutationTypes } from "./modules/general/mutations/types";
import { ActionTypes as GlobalActionTypes } from "./modules/general/actions/types";
import { ActionTypes as EwaActionTypes } from "./modules/ewa/actions/types";
import { MutationTypes as EwaMutationTypes } from "./modules/ewa/mutations/types";

import { Reports2ActionTypes } from "@/store/modules/reports2/actions/types";
import { Reports2MutationTypes } from "@/store/modules/reports2/mutations/types";

import { AuthentificationStore } from "./modules/authentification/types";
import { PlayerStore } from "./modules/player/types";
import { CommentsStore } from "./modules/comments/types";
import { DictionariesStore } from "./modules/dictionaries/types";
import { CategoriesStore } from "./modules/categories/types";
import { CriterionsStore } from "./modules/criterions/types";
import { CheckListsStore } from "./modules/checkLists/types";
import { CallsStore } from "./modules/calls/types";
import { ReportsStore } from "./modules/reports/types";
import { ProjectsStore } from "./modules/projects/types";
import { EmployeeesStore } from "./modules/employees/types";
import { SelectedCallStore } from "./modules/selectedCall/types";
import { ProjectIntegrationStore } from "./modules/projectIntegration/types";
import { SubscriptionStore } from "./modules/subscriptions/types";
import { EwaStore } from "./modules/ewa/types";
import { CardsStore } from "@/store/modules/cards/types";
import { Reports2Store } from "@/store/modules/reports2/types";
import { GeneralStore } from "@/store/modules/general/types";

export const StoreActionTypes = {
  ...AuthentificationActionTypes,
  ...PlayerActionTypes,
  ...CommentsActionTypes,
  ...DictionariesActionTypes,
  ...CategoriesActionTypes,
  ...CriterionsActionTypes,
  ...CheckListsActionTypes,
  ...CallsActionTypes,
  ...ReportsActionTypes,
  ...Reports2ActionTypes,
  ...ProjectsActionTypes,
  ...EmployeesActionTypes,
  ...SelectedCallActionTypes,
  ...ProjectIntegrationActionTypes,
  ...SubscriptionActionTypes,
  ...GlobalActionTypes,
  ...EwaActionTypes,
};

export const StoreMutationTypes = {
  ...AuthentificationMutationTypes,
  ...PlayerMutationTypes,
  ...CommentsMutationTypes,
  ...DictionariesMutationTypes,
  ...CategoriesMutationTypes,
  ...CriterionsMutationTypes,
  ...CheckListsMutationTypes,
  ...CallsMutationTypes,
  ...ReportsMutationTypes,
  ...Reports2MutationTypes,
  ...ProjectsMutationTypes,
  ...EmployeesMutationTypes,
  ...SelectedCallMutationTypes,
  ...ProjectIntegrationMutationTypes,
  ...SubscriptionMutationTypes,
  ...GlobalMutationTypes,
  ...EwaMutationTypes,
};

type StoreModules = {
  authentification: AuthentificationStore;
  player: PlayerStore;
  comments: CommentsStore;
  dictionaries: DictionariesStore;
  categories: CategoriesStore;
  criterions: CriterionsStore;
  checkLists: CheckListsStore;
  calls: CallsStore;
  reports: ReportsStore;
  reports2: Reports2Store;
  projects: ProjectsStore;
  employees: EmployeeesStore;
  selectedCall: SelectedCallStore;
  projectIntegration: ProjectIntegrationStore;
  subscription: SubscriptionStore;
  ewa: EwaStore;
  cards: CardsStore;
  general: GeneralStore;
};

export type Store = AuthentificationStore<
  Pick<StoreModules, "authentification">
> &
  PlayerStore<Pick<StoreModules, "player">> &
  CommentsStore<Pick<StoreModules, "comments">> &
  DictionariesStore<Pick<StoreModules, "dictionaries">> &
  CategoriesStore<Pick<StoreModules, "categories">> &
  CriterionsStore<Pick<StoreModules, "criterions">> &
  CheckListsStore<Pick<StoreModules, "checkLists">> &
  CallsStore<Pick<StoreModules, "calls">> &
  ReportsStore<Pick<StoreModules, "reports">> &
  Reports2Store<Pick<StoreModules, "reports2">> &
  ProjectsStore<Pick<StoreModules, "projects">> &
  EmployeeesStore<Pick<StoreModules, "employees">> &
  SelectedCallStore<Pick<StoreModules, "selectedCall">> &
  ProjectIntegrationStore<Pick<StoreModules, "projectIntegration">> &
  SubscriptionStore<Pick<StoreModules, "subscription">> &
  EwaStore<Pick<StoreModules, "ewa">> &
  CardsStore<Pick<StoreModules, "cards">> &
  GeneralStore<Pick<StoreModules, "general">>;
