import { ManagerAddedModel } from "@/services/types";

/* eslint-disable require-atomic-updates */
export const refactorNamesList = async (
  arr: ManagerAddedModel[]
): Promise<ManagerAddedModel[]> => {
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const nameDetector = require("russian-name-detector")();

  const result = await Promise.all(
    arr.flat().map(async (v) => {
      const refactoredName = await nameDetector(v.name);
      if (!refactoredName) {
        return v;
      }

      if (!refactoredName.surname && refactoredName.middlename !== undefined) {
        refactoredName.surname = refactoredName.middlename;
      }

      if (refactoredName.name && refactoredName.surname) {
        v.name = `${refactoredName.surname} ${refactoredName.name}`;
      }

      v.additional = {
        name: refactoredName.name,
        sex: refactoredName.sex,
        surname: refactoredName.surname,
      };

      return v;
    })
  );

  return result.sort((a: ManagerAddedModel, b: ManagerAddedModel) => {
    if (!a.additional.surname || !a.additional.surname) {
      return -1;
    }
    return a.additional.surname.localeCompare(b.additional.surname);
  });
};
