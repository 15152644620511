import { MutationTree } from "vuex";
import { MutationTypes } from "./types";
import { IDictionariesStateModel } from "./../../../interfaces";
import { Dictionary, LocaleType } from "@/services/types";

export type DictionariesMutations<S = IDictionariesStateModel> = {
  [MutationTypes.SET_DICTIONARIES_DATA_LOADING](
    state: S,
    payload: boolean
  ): void;
  [MutationTypes.SET_DICTIONARIES_DICTIONARY](
    state: S,
    payload: Dictionary
  ): void;
  [MutationTypes.SET_DICTIONARIES_LOADED_LOCALE](
    state: S,
    payload: LocaleType
  ): void;
};

export const mutations: MutationTree<IDictionariesStateModel> &
  DictionariesMutations = {
  [MutationTypes.SET_DICTIONARIES_DATA_LOADING](state, payload: boolean) {
    state.dataIsLoading = payload;
  },
  [MutationTypes.SET_DICTIONARIES_DICTIONARY](state, payload: Dictionary) {
    const list = [...state.list];
    const idx = list.findIndex((item) => item.locale == payload.locale);

    if (idx >= 0) {
      list.splice(idx, 1);
      list.push(payload);
    } else {
      list.push(payload);
    }

    state.list = [...list];
  },
  [MutationTypes.SET_DICTIONARIES_LOADED_LOCALE](state, payload: LocaleType) {
    if (state.loadedLanguages.indexOf(payload) < 0) {
      const list = state.loadedLanguages;
      list.push(payload);
      state.loadedLanguages = [...list];
    }
  },
};
