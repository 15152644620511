export enum ActionTypes {
  SET_PLAYER_PLAY = "setPlayerPlay",
  SET_PLAYER_VOLUME = "setPlayerVolume",
  SET_PLAYER_DURATION = "setPlayerDuration",
  SET_PLAYER_CURRENT_TIME = "setPlayerCurrentTime",
  SET_PLAYER_URL = "setPlayerUrl",
  SET_PLAYER_PLAY_BACK_SPEED = "setPlayerPlayBackSpeed",
  SET_PLAYER_NEW_CURRENT_TIME = "setPlayerNewCurrentTime",
  SET_PLAYER_CLEAR_PLAYER = "setPlayerClearPlayer",
}
