import { GetterTree } from "vuex";
import { IProjectsStateModel, IRootStateModel } from "./../../../interfaces";
import {
  BaseProjectModel,
  ProjectModel,
  ProjectsListSortModel,
  ProjectsListFilterModel,
  BaseEntityModel,
  SubscriptionItemDataModel,
  ProjectLimitsModel,
} from "@/services/types";

export type ProjectsGetters = {
  getProjectsList(state: IProjectsStateModel): BaseProjectModel[];
  getProjectsListPage(state: IProjectsStateModel): number;
  getProjectsListPerPage(state: IProjectsStateModel): number;
  getProjectsTotal(state: IProjectsStateModel): number;
  isProjectsDataLoading(state: IProjectsStateModel): boolean;
  getProjectsSortValue(state: IProjectsStateModel): ProjectsListSortModel;
  getProjectsFilterValue(state: IProjectsStateModel): ProjectsListFilterModel;
  getProjectsSelectedProject(state: IProjectsStateModel): ProjectModel;
  getProjectsSelectedProjectCheckList(
    state: IProjectsStateModel
  ): BaseEntityModel[];
  getProjectsLimits(state: IProjectsStateModel): ProjectLimitsModel;
};

export const getters: GetterTree<IProjectsStateModel, IRootStateModel> &
  ProjectsGetters = {
  getProjectsList: (state: IProjectsStateModel) => {
    return state.list;
  },
  getProjectsFullList: (state: IProjectsStateModel) => {
    return state.fullList;
  },
  getProjectsListPage: (state: IProjectsStateModel) => {
    return state.page;
  },
  getProjectsListPerPage: (state: IProjectsStateModel) => {
    return state.perPage;
  },
  getProjectsTotal: (state: IProjectsStateModel) => {
    return state.total;
  },
  isProjectsDataLoading: (state: IProjectsStateModel) => {
    return state.dataIsLoading;
  },
  getProjectsFilterValue: (state: IProjectsStateModel) => {
    return state.filter;
  },
  getProjectsSortValue: (state: IProjectsStateModel) => {
    return state.sort;
  },
  getProjectsSelectedProject: (state: IProjectsStateModel) => {
    return state.selectedProject;
  },
  getProjectsSelectedProjectCheckList: (state: IProjectsStateModel) => {
    return state.selectedProjectCheckLists;
  },
  getProjectsLimits: (state: IProjectsStateModel) => {
    return state.limits;
  },
};
