import { defineStore } from "pinia";
import { computed, reactive } from "vue";

export const usePlayerStore = defineStore("player", () => {
  const lastPlayed = reactive<{
    id: number;
    time: number;
  }>({
    id: null,
    time: null,
  });

  const actions = {
    setLastPlayed(id: number, time: number) {
      lastPlayed.time = time;
      lastPlayed.id = id;
    },
    resetLastPlayed() {
      lastPlayed.time = null;
      lastPlayed.id = null;
    },
  };

  return {
    lastPlayed: computed(() => lastPlayed),
    ...actions,
  };
});
