import { ActionTree, ActionContext } from "vuex";
import { IDictionariesStateModel, IRootStateModel } from "../../../interfaces";
import { DictionariesMutations } from "./../mutations";
import { ActionTypes } from "./types";
import { MutationTypes } from "./../mutations/types";
import {
  GetDictionaryResponseModel,
  DictionariesList,
} from "./../../../../services/types";
import { getClient } from "../../../../services/client/clientProvider";
import { BaseClient } from "../../../../services/client/BaseClient";
import { Storage } from "./../../../../services/storage/Storage";
import { i18n } from "@/i18n";
import { Locales } from "@/i18n/locales";
import VueI18n from "vue-i18n";

type AugmentedActionDictionariesContext = {
  commit<K extends keyof DictionariesMutations>(
    key: K,
    payload: Parameters<DictionariesMutations[K]>[1]
  ): ReturnType<DictionariesMutations[K]>;
} & Omit<ActionContext<IDictionariesStateModel, IRootStateModel>, "commit">;

export interface DictionariesActions {
  [ActionTypes.SET_DICTIONARIES_GET_DICTIONARIES_LIST](
    context: AugmentedActionDictionariesContext
  ): Promise<void>;
  [ActionTypes.SET_DICTIONARIES_CHECK_DICTIONARIES_LIST](
    context: AugmentedActionDictionariesContext
  ): Promise<void>;
}

const storage = new Storage();

const setDictionariesToStorage = (list: DictionariesList) =>
  storage.dictionaries.set(list);

const getDictionariesFromStorage = (): DictionariesList | null =>
  storage.getDictionaries();

const setLocaleMessage = (
  locale: VueI18n.Locale,
  message: VueI18n.LocaleMessageObject
) => i18n.setLocaleMessage(locale, message);

export const actions: ActionTree<IDictionariesStateModel, IRootStateModel> &
  DictionariesActions = {
  async [ActionTypes.SET_DICTIONARIES_GET_DICTIONARIES_LIST]({ commit }) {
    const client: BaseClient = getClient();
    const dictList: DictionariesList = {
      list: [],
    };

    const enDict: GetDictionaryResponseModel = await client.getDictionary({
      locale: "en",
    });
    if (enDict) {
      dictList.list.push(enDict);
      setLocaleMessage(Locales.EN, enDict.data);
    }

    const ruDict: GetDictionaryResponseModel = await client.getDictionary({
      locale: "ru",
    });
    if (ruDict) {
      dictList.list.push(ruDict);
      setLocaleMessage(Locales.RU, ruDict.data);
    }

    dictList.list.map((item) => {
      commit(MutationTypes.SET_DICTIONARIES_DICTIONARY, item);
      commit(MutationTypes.SET_DICTIONARIES_LOADED_LOCALE, item.locale);
    });

    setDictionariesToStorage(dictList);
  },
  async [ActionTypes.SET_DICTIONARIES_CHECK_DICTIONARIES_LIST]({
    commit,
    dispatch,
  }) {
    const listDict = getDictionariesFromStorage();
    if (listDict && listDict.list) {
      listDict.list.map((item) => {
        commit(MutationTypes.SET_DICTIONARIES_DICTIONARY, item);
        commit(MutationTypes.SET_DICTIONARIES_LOADED_LOCALE, item.locale);
        setLocaleMessage(item.locale, item.data);
      });
    } else {
      await dispatch(ActionTypes.SET_DICTIONARIES_GET_DICTIONARIES_LIST);
    }
  },
};
