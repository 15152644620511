import { Profile, DictionariesList } from "./../types";

class StorageItem<T> {
  constructor(private readonly field: string) {}

  public readonly get = () =>
    this.parseJsonValue<T>(localStorage.getItem(this.field));

  public readonly delete = () => localStorage.removeItem(this.field);

  public readonly set = (model: T) => {
    localStorage.setItem(this.field, JSON.stringify(model));

    return model;
  };

  private readonly parseJsonValue = <T>(value: string | null) => {
    if (value == null) {
      return null;
    } else {
      return JSON.parse(value) as T;
    }
  };
}

export class Storage {
  private static readonly PROFILE = "PROFILE";
  private static readonly DICTIONARIES_LIST = "DICTIONARIES_LIST";

  public readonly clear = () => localStorage.clear();

  public readonly profile = new StorageItem<Profile>(Storage.PROFILE);
  public readonly dictionaries = new StorageItem<DictionariesList>(
    Storage.DICTIONARIES_LIST
  );

  public getProfile = () => {
    const profile: Profile = this.profile.get();
    if (profile) {
      if (profile.tokenExpired * 1000 > new Date().getTime()) {
        return profile;
      } else {
        return null;
      }
    } else {
      return null;
    }
  };

  public getDictionaries = () => {
    const dictionaries: DictionariesList = this.dictionaries.get();
    if (dictionaries && dictionaries.list && dictionaries.list.length > 0) {
      return dictionaries;
    } else {
      return null;
    }
  };
}
