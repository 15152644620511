import { BaseApi } from "@/services/api/BaseApi";
import {
  GetCardsReportRequestDto,
  GetCardsReportResponseDto,
} from "@/services/api/reports/types";
import { merge } from "lodash";

export class ReportsApi extends BaseApi {
  async getCardsReport(payload: GetCardsReportRequestDto) {
    merge(payload, {
      filter: {
        types: ["CARD"],
      },
    });

    return (await this.client.post(
      payload,
      `/entities/report`
    )) as Promise<GetCardsReportResponseDto>;
  }
}
