import { GetterTree } from "vuex";
import {
  IProjectIntegrationStateModel,
  IRootStateModel,
} from "./../../../interfaces";
import {
  ProjectIntegrationModel,
  ProjectsFunnelsListModel,
  ProjectIntegration,
} from "@/services/types";

export type ProjectsIntegrationGetters = {
  isProjectIntegrationDataLoading(
    state: IProjectIntegrationStateModel
  ): boolean;
  getProjectIntegrationIntegration(
    state: IProjectIntegrationStateModel
  ): ProjectIntegrationModel;
  getProjectIntegrationFunnelsList(
    state: IProjectIntegrationStateModel
  ): ProjectsFunnelsListModel;
  getProjectIntegrationIntegrationsList(
    state: IProjectIntegrationStateModel
  ): ProjectIntegration[];
  getProjectIntegrationSelectedProjectIntegration(
    state: IProjectIntegrationStateModel
  ): ProjectIntegration;
  isProjectIntegrationCallsListLoading(
    state: IProjectIntegrationStateModel
  ): boolean;
  getProjectIntegrationType(state: IProjectIntegrationStateModel): string;
  getBitrixIntegration(state: IProjectIntegrationStateModel);
};

export const getters: GetterTree<
  IProjectIntegrationStateModel,
  IRootStateModel
> &
  ProjectsIntegrationGetters = {
  isProjectIntegrationDataLoading: (state: IProjectIntegrationStateModel) => {
    return state.dataIsLoading;
  },
  getProjectIntegrationIntegration: (state: IProjectIntegrationStateModel) => {
    return state.projectIntegration;
  },
  getProjectIntegrationFunnelsList: (state: IProjectIntegrationStateModel) => {
    return state.funnelsList;
  },
  getProjectIntegrationIntegrationsList: (
    state: IProjectIntegrationStateModel
  ) => {
    return state.selectedProjectIntegrationsList;
  },
  getProjectIntegrationSelectedProjectIntegration: (
    state: IProjectIntegrationStateModel
  ) => {
    return state.selectedProjectIntegration;
  },
  isProjectIntegrationCallsListLoading: (
    state: IProjectIntegrationStateModel
  ) => {
    return state.callsListIsLoading;
  },
  getProjectIntegrationType: (state: IProjectIntegrationStateModel) => {
    return state.projectIntegrationType;
  },
  getBitrixIntegration: (state: IProjectIntegrationStateModel) => {
    return state.bitrixIntegration;
  },
};
