import { UserRoles } from "@/services/roles/rolesEnum";
import { getStore } from "@/store/getStore";
import { UserPermissions } from "@/services/roles/permissionsEnum";

export function isUserHasPermission(key: UserPermissions | string): boolean {
  return getCurrentUser()?.permissions.includes(String(key));
}

export function isUserHasRole(key: UserRoles | string): boolean {
  return getCurrentUser()?.roles.includes(String(key));
}

export function isUserCompanyHasParent() {
  return getCurrentUser()?.company.hasParent;
}

function getCurrentUser() {
  return getStore().getters.getAuthentificationProfile;
}
