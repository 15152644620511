import { GetterTree } from "vuex";
import { IEmployeesStateModel, IRootStateModel } from "./../../../interfaces";
import {
  CompanyEmployeeModel,
  CompanyEmployeesListFilterModel,
  StatusType,
} from "@/services/types";

export type EmployeesGetters = {
  getEmployeesList(state: IEmployeesStateModel): CompanyEmployeeModel[];
  getEmployeesTotal(state: IEmployeesStateModel): number;
  isEmployeesDataLoading(state: IEmployeesStateModel): boolean;
  getEmployeesFilterValue(
    state: IEmployeesStateModel
  ): CompanyEmployeesListFilterModel;
  getEmployeesErrorMessage(state: IEmployeesStateModel): string;
};

const filterByName = (
  search: string,
  list: CompanyEmployeeModel[]
): CompanyEmployeeModel[] => {
  if (search.length >= 2) {
    return list.filter((item) => {
      const name = `${item.lastname} ${item.firstname} ${item.surname}`;
      return (
        name.trim().toLowerCase().indexOf(search.trim().toLowerCase()) >= 0
      );
    });
  } else {
    return list;
  }
};

const filterByStatus = (
  status: StatusType,
  list: CompanyEmployeeModel[]
): CompanyEmployeeModel[] => {
  if (status !== null) {
    return list.filter((item) => item.status === status);
  } else {
    return list;
  }
};

export const getters: GetterTree<IEmployeesStateModel, IRootStateModel> &
  EmployeesGetters = {
  getEmployeesList: (state: IEmployeesStateModel) => {
    return state.list;
  },
  getEmployeesTotal: (state: IEmployeesStateModel) => {
    return state.list.length;
  },
  isEmployeesDataLoading: (state: IEmployeesStateModel) => {
    return state.dataIsLoading;
  },
  getEmployeesFilterValue: (state: IEmployeesStateModel) => {
    return state.filter;
  },
  getEmployeesFilteredList: (state: IEmployeesStateModel) => {
    const status = state.filter ? state.filter.status : null;
    const search = state.filter ? state.filter.search : "";

    return filterByStatus(status, filterByName(search, state.list));
  },
  getEmployeesErrorMessage: (state: IEmployeesStateModel) => {
    return state.error;
  },
};
