import { GetterTree } from "vuex";
import { IPlayerStateModel, IRootStateModel } from "./../../../interfaces";
import { PlayBackSpeed } from "@/services/types";

export type PlayerGetters = {
  isPlayerPlay(state: IPlayerStateModel): boolean;
  getPlayerPlayBackSpeed(state: IPlayerStateModel): PlayBackSpeed;
  getPlayerVolume(state: IPlayerStateModel): number;
  getPlayerDuration(state: IPlayerStateModel): number;
  getPlayerCurrentTime(state: IPlayerStateModel): number;
  getPlayerUrl(state: IPlayerStateModel): string;
  getPlayerNewCurrentTime(state: IPlayerStateModel): number;
  getPlayerIsPlayed(state: IPlayerStateModel): boolean;
};

export const getters: GetterTree<IPlayerStateModel, IRootStateModel> &
  PlayerGetters = {
  isPlayerPlay: (state: IPlayerStateModel) => {
    return state.play;
  },
  getPlayerCurrentTime: (state: IPlayerStateModel) => {
    return state.currentTime;
  },
  getPlayerDuration: (state: IPlayerStateModel) => {
    return state.duration;
  },
  getPlayerPlayBackSpeed: (state: IPlayerStateModel) => {
    return state.playBackSpeed;
  },
  getPlayerUrl: (state: IPlayerStateModel) => {
    return state.url;
  },
  getPlayerVolume: (state: IPlayerStateModel) => {
    return state.volume;
  },
  getPlayerNewCurrentTime: (state: IPlayerStateModel) => {
    return state.newCurrentTime;
  },
  getPlayerIsPlayed: (state: IPlayerStateModel) => {
    return state.isPlayed;
  },
};
