import { GetterTree } from "vuex";
import { CardsStateModel } from "@/store/modules/cards/state";
import { IRootStateModel } from "@/store/interfaces";
import { ProjectWithChecklistsModel } from "@/services/types/project";
import { CardModel, CardsListFilterModel } from "@/services/types/cards";
import { NameIdModel } from "@/services/types/entities/base";

export type CardsGetters = {
  getCardsProjectsList(state: CardsStateModel): ProjectWithChecklistsModel[];
  getCardsList(state: CardsStateModel): CardModel[];
  getCardsIsLoading(state: CardsStateModel): boolean;
  getCardsManagersList(state: CardsStateModel): NameIdModel[];
  getCardsFilter(state: CardsStateModel): CardsListFilterModel;
};

export const getters: GetterTree<CardsStateModel, IRootStateModel> &
  CardsGetters = {
  getCardsFilter(state: CardsStateModel): CardsListFilterModel {
    return state.filter;
  },
  getCardsManagersList(state: CardsStateModel) {
    return state.managers;
  },
  getCardsIsLoading(state: CardsStateModel): boolean {
    return state.isLoading;
  },
  getCardsList(state: CardsStateModel) {
    return state.cards;
  },
  getCardsProjectsList(state: CardsStateModel): ProjectWithChecklistsModel[] {
    return state.projects;
  },
};
