import { GetterTree } from "vuex";
import { ICommentsStateModel, IRootStateModel } from "./../../../interfaces";
import { CommentModel } from "@/services/types/entities/comments";

export type CommentsGetters = {
  getCommentsList(state: ICommentsStateModel): CommentModel[];
  getCommentsGroupedPlayerComments(
    state: ICommentsStateModel
  ): CommentModel[][];
  getCommentsDataIsLoading(state: ICommentsStateModel): boolean;
  getCommentsSelectedComment(state: ICommentsStateModel): CommentModel;
  getCommentsSelectedCommentId(state: ICommentsStateModel): number;
  getCommentsHasNotSavedComment(state: ICommentsStateModel): boolean;
};

export const getters: GetterTree<ICommentsStateModel, IRootStateModel> &
  CommentsGetters = {
  getCommentsList: (state: ICommentsStateModel) => {
    return state.list;
  },
  getCommentsGroupedPlayerComments: (state: ICommentsStateModel) => {
    // const commentsWithTimestamp: CommentModel[] = state.list
    //   .filter((item) => item.timeOnTrack != null)
    //   .map((item) => ({ id: item.id, timeOnTrack: item.timeOnTrack }));
    // let playerComments: CommentModel[][] = [];
    // let playerCommentsItem: CommentModel[] = [];
    //
    // if (commentsWithTimestamp.length > 0) {
    //   for (let i = 0; i < commentsWithTimestamp.length; i++) {
    //     if (i == 0) {
    //       playerComments.push([commentsWithTimestamp[i]]);
    //     } else if (i == commentsWithTimestamp.length - 1) {
    //       if (
    //         commentsWithTimestamp[i].timeOnTrack -
    //           commentsWithTimestamp[i - 1].timeOnTrack <
    //         10 * 1000
    //       ) {
    //         playerCommentsItem.push(commentsWithTimestamp[i]);
    //         playerComments = [...playerComments, [...playerCommentsItem]];
    //       } else {
    //         playerComments.push([commentsWithTimestamp[i]]);
    //       }
    //     } else {
    //       if (
    //         commentsWithTimestamp[i + 1].timeOnTrack -
    //           commentsWithTimestamp[i].timeOnTrack >
    //           10 * 1000 &&
    //         commentsWithTimestamp[i].timeOnTrack -
    //           commentsWithTimestamp[i - 1].timeOnTrack >
    //           10 * 1000
    //       ) {
    //         if (playerCommentsItem.length > 0) {
    //           playerComments = [...playerComments, [...playerCommentsItem]];
    //           playerCommentsItem = [];
    //         }
    //         playerComments.push([commentsWithTimestamp[i]]);
    //       } else if (
    //         commentsWithTimestamp[i].timeOnTrack -
    //           commentsWithTimestamp[i - 1].timeOnTrack <
    //           10 * 1000 &&
    //         commentsWithTimestamp[i + 1].timeOnTrack -
    //           commentsWithTimestamp[i].timeOnTrack >
    //           10 * 1000
    //       ) {
    //         playerCommentsItem.push(commentsWithTimestamp[i]);
    //         playerComments = [...playerComments, [...playerCommentsItem]];
    //         playerCommentsItem = [];
    //       } else {
    //         playerCommentsItem.push(commentsWithTimestamp[i]);
    //       }
    //     }
    //   }
    // }

    // return playerComments;
    return [];
  },
  getCommentsDataIsLoading: (state: ICommentsStateModel) => {
    return state.dataIsLoading;
  },
  getCommentsSelectedComment: (state: ICommentsStateModel) => {
    return state.selectedComment;
  },
  getCommentsSelectedCommentId: (state: ICommentsStateModel) => {
    return state.selectedComment ? state.selectedComment.id : null;
  },
  getCommentsHasNotSavedComment: (state: ICommentsStateModel) => {
    return state.hasNotSavedComment;
  },
};
