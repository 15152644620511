export enum ActionTypes {
  SET_SELECTED_CALL = "setCallsSelectedCall",
  SET_SELECTED_CALL_CLEAR = "setCallsSelectedCallClear",
  SET_SELECTED_CALL_CATEGORIES = "setSelectedCallCategories",
  SET_SELECTED_CALL_SET_ESTIMATION = "setSelectedCallSetEstimation",
  SET_SELECTED_CALL_EDIT_ESTIMATION = "setSelectedCallEditEstimation",
  SET_SELECTED_CALL_GET_ESTIMATION = "setSelectedCallGetEstimation",
  SET_SELECTED_CALL_SET_CURRENT_ESTIMATION = "setSelectedCallSetCurrentEstimation",
  SET_SELECTED_CALL_SET_MANAGER = "setSelectedCallSetManager",
}
