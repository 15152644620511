export enum MutationTypes {
  SET_CATEGORIES_LIST = "setCategoriesList",
  SET_CATEGORIES_FULL_LIST = "setCategoriesFullList",
  SET_CATEGORIES_WITH_CRITERIONS_FULL = "setCategoriesWithCriterionsFull",
  SET_CATEGORIES_LIST_PAGE = "setCategoriesListPage",
  SET_CATEGORIES_TOTAL = "setCategoriesTotal",
  SET_CATEGORIES_LIST_PER_PAGE = "setCategoriesListPerPage",
  SET_CATEGORIES_DATA_IS_LOADING = "setCategoriesDataIsLoading",
  SET_CATEGORIES_SORT_VALUE = "setCategoriesSortValue",
  SET_CATEGORIES_FILTER_VALUE = "setCategoriesFilterValue",
  SET_CATEGORIES_SELECTED_CATEGORY = "setCategoriesSelectedCategory",
}
