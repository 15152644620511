import { Module } from "vuex";
import { state } from "./state";
import { ICommentsStateModel, IRootStateModel } from "./../../interfaces";
import { mutations } from "./mutations";
import { getters } from "./getters";
import { actions } from "./actions";

const comments: Module<ICommentsStateModel, IRootStateModel> = {
  state,
  mutations,
  getters,
  actions,
};

export default comments;
